import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class CompanyProgramDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            companyProgramDate: (!this.props.interimData.company_program_date ? null : this.inputDateMonthYear(this.props.interimData.company_program_date)),
            companyProgramDateResponseError: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        if (this.state.companyProgramDate) {

            if (dateRegular.test(this.state.companyProgramDate)) {

                const companyProgramDate = new Date(this.state.companyProgramDate).getTime() / 1000;

                if (companyProgramDate) {
                    let formData = new FormData();

                    formData.append('company_program_date', String(companyProgramDate));

                    this.props.apiRequest('/company/' + this.props.interimData.company_id + '/date', formData, () => {
                            this.props.modalClose();
                            this.props.notificationMessage(this.props.responseMessage, 'green');
                            this.props.pageUpdate();
                        }, () => {
                            this.setState({
                                companyProgramDateResponseError: this.props.responseError
                            })
                        }
                    );
                }

                else {
                    this.setState({
                        companyProgramDateResponseError: 'Пожалуйста, кажите корректно месяц и день даты вступления в программу.'
                    });
                }

            }

            else {
                this.setState({
                    companyProgramDateResponseError: 'Пожалуйста, заполните в правильном формате дату вступления в программу (2019-12-31).'
                });
            }
        }

        else {
            this.setState({
                companyProgramDateResponseError: 'Пожалуйста, укажите дату вступления в программу.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    inputDateMonthYear(date) {

        const dateMonthYear = new Date(date * 1000);

        let dd = dateMonthYear.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = dateMonthYear.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        let yyyy = dateMonthYear.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;

        return yyyy + '-' + mm + '-' + dd;
    }

    render() {
        const { interimData, isRequest } = this.props;
        const { companyProgramDateResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Дата вступления</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Дата вступления в программу</div>
                            <input className="Box__input"
                                   name="companyProgramDate"
                                   type="date"
                                   defaultValue={!interimData.company_program_date ? null : (this.inputDateMonthYear(interimData.company_program_date))}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{companyProgramDateResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProgramDate);
