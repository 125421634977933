import React from "react";
import {connect} from "react-redux";
import {modalOpen} from "../../../store/actions/modalAction";
import {apiRequest} from "../../../store/actions/apiAction";
import {pageLoad, pageMount, pageUpdate} from "../../../store/actions/pageAction";
import {notificationMessage} from "../../../store/actions/notificationAction";
// import "./Devices.scss";
// import "./Students.scss";
import Loading from "../../loading/Loading";
import getDateMonthYear from "../../../library/getDateMonthYear";
import getTimeHourMinSec from "../../../library/getTimeHourMinSec";

class Journal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            appsData: null,
            selectApp: null,
            selectVersion: null,
            addAppVersion: null,
            editApp: null,
            editVersion: null,
            appName: null,

            devicesData: null,
            addDevices: false,
            newDevice: null,
            selectDevice: null,
            archiveDevice: null,
            downloadDevice: null,
            uninstallApp: null,


            unrollArray: []
        };

        this.appInput = React.createRef();
    }

    componentDidMount() {
        this.props.notificationMessage('Кабинет авторизирован', 'black');
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // setInterval(this.props.pageUpdate, 1000);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 1000);
    }

    componentWillUnmount() {
        // console.log('Apps Unmounted');
        clearInterval(this.intervalUpdate);
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.devicesData) {
                    // console.log(3 + ' responseData');
                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        devicesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdate: this.props.isUpdate
                });
                this.pageUpdate(this.state.isLoad);
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/journal', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }


    // electronicNotification() {
    //     if (this.state.electronicNotification) {
    //         this.setState({
    //             electronicNotification: false
    //         });
    //         this.props.apiRequest('/profile/mail_notcheck', null, () => {
    //             this.props.notificationMessage('Системное оповещение отключено!', 'red');
    //         }, () => {
    //             this.setState({
    //                 electronicNotification: true
    //             });
    //         });
    //     }
    //
    //     else {
    //         this.setState({
    //             electronicNotification: true
    //         });
    //         this.props.apiRequest('/profile/mail_check', null, () => {
    //             this.props.notificationMessage('Системное оповещение включено', 'green');
    //         }, () => {
    //             this.setState({
    //                 electronicNotification: false
    //             });
    //         });
    //     }
    // }

    speckText() {

        // // Создаем распознаватель
        // // var recognizer = new webkitSpeechRecognition();
        // var recognizer = new SpeechRecognition();
        //
        // // Ставим опцию, чтобы распознавание началось ещё до того, как пользователь закончит говорить
        //         recognizer.interimResults = true;
        //
        // // Какой язык будем распознавать?
        //         recognizer.lang = 'ru-Ru';
        //
        // // Используем колбек для обработки результатов
        //         recognizer.onresult = function (event) {
        //             var result = event.results[event.resultIndex];
        //             if (result.isFinal) {
        //                 alert('Вы сказали: ' + result[0].transcript);
        //             } else {
        //                 console.log('Промежуточный результат: ', result[0].transcript);
        //             }
        //         };
        //
        // // Начинаем слушать микрофон и распознавать голос
        //         recognizer.start();

        // speechSynthesis.speak(
        //     new SpeechSynthesisUtterance('Сап, Двач. Я ламповый V8 JavaScript Engine')
        // );

        // let voices = speechSynthesis.getVoices();
        // let utterance = new SpeechSynthesisUtterance('Кожанные Россияне');
        // utterance.voice = voices[1];
        // speechSynthesis.speak(utterance);
    }

    handleAppChange() {
        let app = this.appInput.current.files.item(0);
        this.uploadApp(app);
    }

    uploadApp(app) {
        let formData = new FormData();

        formData.append('app_file', app);

        this.props.apiRequest('api/cabinet/apps/upload', formData, () => {
                this.setState({
                    addAppVersion: this.props.responseData
                });
                this.props.pageUpdate();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    selectAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            selectApp: app,
            selectVersion: version
        });
    }

    closeAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            selectApp: null,
            selectVersion: null,
            addAppVersion: null
        });
    }

    editAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            editApp: app,
            editVersion: version,
            appName: app.app_name
        });
    }

    closeEditAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            editApp: null,
            editVersion: null,
            appName: null
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    requestEditApp(app, name) {
        let formData = new FormData();

        formData.append('app_id', app.id);
        formData.append('app_name', name);

        this.props.apiRequest('api/cabinet/apps/edit/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeEditAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }



    requestArchiveVersion(version) {

        this.props.apiRequest('api/cabinet/apps/archive/version/' + version.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeArchiveAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    openAddDevices() {
        document.body.classList.add('overflow_hidden');
        this.setState({
            addDevices: true
        });
    }

    closeAddDevices() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            addDevices: false
        });
    }

    addDevices() {
        this.props.apiRequest('api/cabinet/devices/add', null, () => {
                this.setState({
                    newDevice: this.props.responseData
                });
                this.closeAddDevices();
                this.props.pageUpdate();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    closeNewDevice() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            newDevice: null
        });
    }

    selectDevice(device) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            selectDevice: device
        });
    }

    closeDevice() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            selectDevice: null
        });
    }

    archiveDevice(device) {
        this.setState({
            archiveDevice: device
        });
    }

    closeArchiveDevice() {
        this.setState({
            archiveDevice: null
        });
    }

    requestArchiveDevice(device) {
        this.props.apiRequest('api/cabinet/devices/archive/device/' + device.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeArchiveDevice();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    uninstallApp(device, app) {
        this.setState({
            selectDevice: device,
            uninstallApp: app
        });
    }

    closeUninstallApp() {
        this.setState({
            uninstallApp: null
        });
    }

    requestUninstallApp(app) {
        let device = this.state.selectDevice;
        let formData = new FormData();

        formData.append('device_id', device.id);
        formData.append('version_id', app.version_id);

        this.props.apiRequest('api/cabinet/devices/uninstall/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeUninstallApp();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    uninstallTask(task) {
        this.props.apiRequest('api/cabinet/devices/cancel/task/' + task.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    requestDownloadDevice(device) {
        this.props.apiRequest('api/cabinet/devices/download/device/' + device.id, null, () => {
                this.setState({
                    selectDevice: device,
                    downloadDevice: this.props.responseData
                });
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    closeDownloadDevice() {
        this.setState({
            downloadDevice: null
        });
    }

    requestDownloadApp(version) {
        let device = this.state.selectDevice;
        let formData = new FormData();

        formData.append('device_id', device.id);
        formData.append('version_id', version.id);

        this.props.apiRequest('api/cabinet/devices/download/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeDownloadDevice();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }











    unrollArray(id) {
        let unrollArray = this.state.unrollArray;
        let repeat = false;

        if (unrollArray.length > 0) {

            unrollArray.map((object, index) => {
                if (Number(object) === Number(id)) {
                    repeat = true;
                    unrollArray.splice(index, 1);
                }
            });
        }

        if (!repeat) {

            unrollArray.push(id);
        }

        this.setState({
            unrollArray: unrollArray
        });
    }




    clipboard(clipboard) {
        navigator.clipboard.writeText(clipboard)
            .then(() => {
                this.props.notificationMessage('UID: ' + clipboard + ', скопирован.', 'green');
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    }


    sleepDevice() {
        this.props.notificationMessage('Функция выключения экрана не доступна для данного устройства.', 'red');
    }



    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }


    openAllDevices() {
        let devicesData = this.state.devicesData;
        let unrollArray = [];


        devicesData.map(object => {
            unrollArray.push(object.id);
        });

        this.setState({
            unrollArray: unrollArray
        });
    }

    closeAllDevices() {
        this.setState({
            unrollArray: []
        });
    }



    render() {
        const { isRequest } = this.props;
        const { isMount, isOpen, appsData, selectApp, selectVersion, addAppVersion, editApp, editVersion, appName, archiveApp, archiveVersion,

            devicesData,
            addDevices,
            newDevice,
            selectDevice,
            archiveDevice,
            downloadDevice,
            uninstallApp,


            unrollArray

        } = this.state;

        if (isMount) {

            if (!isOpen || !devicesData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {


                let isUnroll = (id) => {
                    let repeat = false;

                    if (unrollArray.length > 0) {

                        unrollArray.map(object => {
                            if (Number(object) === Number(id)) {
                                repeat = true;
                            }
                        });
                    }

                    return repeat;
                }

                let lastVisit = (time) => {

                    return (
                        <div className={((parseInt(String(new Date().getTime()/1000)) - time) < 300) ? 'Student__online Student__online_green' : 'Student__online'}/>
                    );
                }

                let lastVisitDataTime = (time) => {

                    if (Number(time) > 0) {

                        if ((parseInt(String(new Date().getTime()/1000)) - time) < 300) {

                            return 'сейчас';
                        }

                        else {
                            let date = new Date(time * 1000);
                            let day = "0" + date.getDate();
                            let month = "0" + (date.getMonth() + 1);
                            let year = String(date.getFullYear());
                            let hours = "0" + date.getHours();
                            let minutes = "0" + date.getMinutes();
                            let seconds = "0" + date.getSeconds();
                            let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                            // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                            let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                            return formattedTime + ' ' + formattedData;
                        }
                    }

                    else {

                        return 'никогда';
                    }
                }


                let uidTitle = (uid) => {
                    let uidTitle = devi(String(uid), 8);

                    return uidTitle[0] + '-' + uidTitle[1] + '-' + uidTitle[2] + '-' + uidTitle[3];
                }


                let devi = (str, max=4, arr=[]) => {
                    arr.push(str.substring(max*(arr.length), max*(arr.length+1)));
                    if (arr.length <= (str.length / max | 0)) return devi(str, max, arr);
                    else if (arr[arr.length-1].length < max) arr.pop();
                    return arr;
                };



                let modalData = (objectId, objectData) => {
                    return {
                        objectId: objectId,
                        objectData: objectData
                    };
                }

                return (
                    <React.Fragment>
                        <section>
                            <div className="container">

                                <div className="module__wrapper">
                                    <div className="module__header">
                                        <div className="header__title">Журнал задач</div>
                                        <label>

                                            {/*<div className='button header__button button_blue'*/}
                                            {/*     onClick={this.openAddDevices.bind(this)}>*/}
                                            {/*    Добавить устройство*/}
                                            {/*</div>*/}

                                            {devicesData.length === unrollArray.length ? (

                                                <div className='button header__button button_blue'
                                                     style={{
                                                         backgroundColor: "#f1f1f1",
                                                         // marginRight: 24,
                                                         color: "#2a3e4c",
                                                         opacity: .5
                                                     }}
                                                     onClick={this.closeAllDevices.bind(this)}>
                                                    Свернуть все
                                                </div>

                                            ) : (

                                                <div className='button header__button button_blue'
                                                     style={{
                                                         backgroundColor: "#f1f1f1",
                                                         // marginRight: 24,
                                                         color: "#2a3e4c",
                                                         opacity: .5
                                                     }}
                                                     onClick={this.openAllDevices.bind(this)}>
                                                    Развернуть все
                                                </div>

                                            )}

                                        </label>
                                    </div>

                                    {/*<table className="table__device">*/}
                                    {/*<tbody>*/}
                                    {/*    <tr className="tr__device_header">*/}
                                    {/*        <th className="th__device">№</th>*/}
                                    {/*        <th className="th__device">UID</th>*/}
                                    {/*        <th className="th__device">Последний запрос</th>*/}
                                    {/*        <th className="th__device">Приложения</th>*/}
                                    {/*        <th className="th__device">Задачи</th>*/}
                                    {/*    </tr>*/}

                                    {/*{devicesData.map((device, index) => (*/}
                                    {/*    // device.versions.length > 0 ? (*/}
                                    {/*        <React.Fragment key={index}>*/}
                                    {/*            /!*<div className="module__field_header">*!/*/}
                                    {/*            /!*    <div className="field_header__title">{device.uid}</div>*!/*/}
                                    {/*            /!*    /!*<a className="header__link">{device.app_signature}</a>*!/*!/*/}
                                    {/*            /!*    <button id="id"*!/*/}
                                    {/*            /!*            className="button field_header__button button_gray"*!/*/}
                                    {/*            /!*            type="submit" name="open_edit_app"*!/*/}
                                    {/*            /!*            onClick={this.editAppVersion.bind(this, device, null)}>Редактировать</button>*!/*/}
                                    {/*            /!*</div>*!/*/}

                                    {/*            /!*{app.versions.map((version, index) => (*!/*/}
                                    {/*            /!*    <div className="module__field_inline" key={index}>*!/*/}
                                    {/*            /!*        <div className="field_inline__box">*!/*/}
                                    {/*            /!*            <div className="field_inline__label">Версия</div>*!/*/}
                                    {/*            /!*            <div className="field_inline__text">*!/*/}
                                    {/*            /!*                {version.version_name + ' ' + version.version_number + ' (' + version.version_code + ')'}*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*        </div>*!/*/}
                                    {/*            /!*        <div className="field_inline__hr">*!/*/}
                                    {/*            /!*            <div className="hr__line" />*!/*/}
                                    {/*            /!*        </div>*!/*/}
                                    {/*            /!*        <div className="field_inline__box">*!/*/}
                                    {/*            /!*            <div className="field_inline__label">Обновление</div>*!/*/}
                                    {/*            /!*            <div*!/*/}
                                    {/*            /!*                className={Number(version.access_update) > 0 ? "field_inline__text text_green" : "field_inline__text text_red"}>*!/*/}
                                    {/*            /!*                {Number(version.access_update) > 0 ? "Доступно" : "Не доступно"}*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*        </div>*!/*/}

                                    {/*            /!*        {Number(version.access_update) > 0 ? (*!/*/}
                                    {/*            /!*            <div className="field_inline__box">*!/*/}
                                    {/*            /!*                <div className="field_inline__label">От</div>*!/*/}
                                    {/*            /!*                <div className="field_inline__text">*!/*/}
                                    {/*            /!*                    {getDateMonthYear(version.release_date)}*!/*/}
                                    {/*            /!*                </div>*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*        ) : null}*!/*/}

                                    {/*            /!*        <div className="field_inline__box_right">*!/*/}
                                    {/*            /!*            <div className="field_inline__hr">*!/*/}
                                    {/*            /!*                <div className="hr__line" />*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*            <div className="button field_inline__button"*!/*/}
                                    {/*            /!*                 onClick={this.selectAppVersion.bind(this, app, version)}>Подробнее</div>*!/*/}
                                    {/*            /!*        </div>*!/*/}
                                    {/*            /!*    </div>*!/*/}
                                    {/*            /!*))}*!/*/}

                                    {/*            /!*<br/><br/><br/><br/>*!/*/}


                                    {/*            <tr className="tr__device">*/}
                                    {/*                <td className="td__device">{device.id}</td>*/}
                                    {/*                <td className="td__device">*/}
                                    {/*                    <button className="button device__link link_blue"*/}
                                    {/*                            type="submit"*/}
                                    {/*                            name="open_edit_device"*/}
                                    {/*                    onClick={this.selectDevice.bind(this, device)}>*/}
                                    {/*                        {device.uid}*/}
                                    {/*                    </button>*/}
                                    {/*                    /!*<button id="{$devices_array[$i]['uid']}"*!/*/}
                                    {/*                    /!*        className="button_icon icon-copy-48" type="submit"*!/*/}
                                    {/*                    /!*        name="copy_id"></button>*!/*/}
                                    {/*                </td>*/}
                                    {/*                <td className="td__device">{device.request_time ? (getDateMonthYear(device.request_time) + ' ' + getTimeHourMinSec(device.request_time)) : null}</td>*/}
                                    {/*                <td className="td__device">*/}
                                    {/*                    {device.apps_on_device ? device.apps_on_device.map(app => (*/}
                                    {/*                        app.app_name + ' ' + app.version_number + ' '*/}
                                    {/*                    )) : null}*/}
                                    {/*                </td>*/}
                                    {/*                <td className="td__device">{device.tasks ? device.tasks.length : null}</td>*/}
                                    {/*            </tr>*/}


                                    {/*        </React.Fragment>*/}
                                    {/*    // ) : null*/}
                                    {/*))}*/}
                                    {/*</tbody>*/}
                                    {/*</table>*/}

















                                    <ul className="Body__list">

                                        {devicesData.map((device, index) => (
                                            <React.Fragment key={index}>
                                                <li className={isUnroll(device.id) ? 'List__item active' : 'List__item'}
                                                    onClick={this.unrollArray.bind(this, device.id)}
                                                    style={{
                                                        padding: "12px 24px 7px 24px",
                                                        position: "relative",
                                                        height: 56 - 12 - 7
                                                    }}>
                                                    {/*<div className="Table__td">{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>*/}
                                                    {/*<div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>*/}

                                                    {/*{trainingGroup.direction_preparation ? (*/}
                                                    {/*    <React.Fragment>*/}
                                                    {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    {/*        <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.direction_preparation}</div>*/}
                                                    {/*    </React.Fragment>*/}
                                                    {/*) : null}*/}

                                                    {/*{trainingGroup.employee_fio ? (*/}
                                                    {/*    <React.Fragment>*/}
                                                    {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    {/*        <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.employee_fio}</div>*/}
                                                    {/*    </React.Fragment>*/}
                                                    {/*    ) : null}*/}

                                                    {/*<div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>*/}
                                                    {/*    {device.id}*/}
                                                    {/*</div>*/}
                                                    {/*<div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    <div className="Table__td" style={{
                                                        display: "inline-block", verticalAlign: "top", paddingTop: 6,
                                                        fontWeight: 400,
                                                        fontSize: 16
                                                    }}>
                                                        {device.name ? (device.name !== '' ? device.name : uidTitle(device.uid)) : uidTitle(device.uid)}
                                                        {/*{uidTitle(device.uid)}*/}
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Table__td" style={{
                                                        display: "inline-block", verticalAlign: "top", paddingTop: 6,
                                                        fontWeight: 400,
                                                        fontSize: 16
                                                    }}>
                                                        {device.app_version.version_name + ' ' + device.app_version.version_number}
                                                    </div>
                                                    {/*<div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    <div className="Table__td" style={{
                                                        display: "inline-block", verticalAlign: "top", paddingTop: 4,
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        marginLeft: 16
                                                    }}>
                                                        {device.assigned_task === 'uninstall' ? (
                                                            <div style={{
                                                                // position: "absolute",
                                                                // top: -10,
                                                                // left: 0,
                                                                backgroundColor: "#ff5722",
                                                                color: "white",
                                                                fontFamily: "Roboto",
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                display: "inline-block",
                                                                padding: "4px 6px 3px",
                                                                // marginTop: 6,
                                                                borderRadius: 4,
                                                                // marginLeft: 12
                                                            }}>
                                                                Удаление
                                                            </div>
                                                        ) : null}

                                                        {device.assigned_task === 'setup' ? (
                                                            <div style={{
                                                                // position: "absolute",
                                                                // top: -10,
                                                                // left: 0,
                                                                backgroundColor: "#2196f3",
                                                                color: "white",
                                                                fontFamily: "Roboto",
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                display: "inline-block",
                                                                padding: "4px 6px 3px",
                                                                // marginTop: 6,
                                                                borderRadius: 4,
                                                                // marginLeft: 12
                                                            }}>
                                                                Установка
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {/*<div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 7, marginLeft: 24, float: "right"}}>*/}
                                                    {/*    {lastVisit(device.request_time)}*/}
                                                    {/*</div>*/}
                                                    <div className="Table__td" style={{
                                                        display: "inline-block", verticalAlign: "top", paddingTop: 6, float: "right",
                                                        fontWeight: 400,
                                                        fontSize: 16
                                                    }}>
                                                        {Number(device.start_of_execution) > 0 ? (
                                                            Number(device.completion_time) > 0 ? (
                                                                lastVisitDataTime(device.completion_time)
                                                            ) : (
                                                                lastVisitDataTime(device.start_of_execution)
                                                            )
                                                        ) : (

                                                            Number(device.completion_time) > 0 ? (
                                                                lastVisitDataTime(device.completion_time)
                                                            ) : (
                                                                lastVisitDataTime(device.time)
                                                            )
                                                        )}


                                                    </div>

                                                    {device.name ? (device.name !== '' ? (
                                                        <div style={{
                                                            position: "absolute",
                                                            top: -10,
                                                            left: 0,
                                                            backgroundColor: "#000",
                                                            color: "white",
                                                            fontFamily: "Roboto",
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            display: "inline-block",
                                                            padding: "4px 6px 3px",
                                                            // marginTop: 6,
                                                            borderRadius: 4,
                                                            marginLeft: 12
                                                        }}>
                                                            {uidTitle(device.uid)}
                                                        </div>
                                                    ) : null) : null}

                                                    {Number(device.start_of_execution) > 0 ? (
                                                            Number(device.completion_time) > 0 ? (
                                                                device.completion_status === 'cancel' ? (
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        top: -10,
                                                                        right: 0,
                                                                        backgroundColor: "#9e9e9e",
                                                                        color: "white",
                                                                        fontFamily: "Roboto",
                                                                        fontSize: 12,
                                                                        fontWeight: 400,
                                                                        display: "inline-block",
                                                                        padding: "4px 6px 3px",
                                                                        // marginTop: 6,
                                                                        borderRadius: 4,
                                                                        marginRight: 12
                                                                    }}>
                                                                        Отменено
                                                                    </div>
                                                                ) : (
                                                                    device.completion_status === 'completed' ? (
                                                                        <div style={{
                                                                            position: "absolute",
                                                                            top: -10,
                                                                            right: 0,
                                                                            backgroundColor: "#4caf50",
                                                                            color: "white",
                                                                            fontFamily: "Roboto",
                                                                            fontSize: 12,
                                                                            fontWeight: 400,
                                                                            display: "inline-block",
                                                                            padding: "4px 6px 3px",
                                                                            // marginTop: 6,
                                                                            borderRadius: 4,
                                                                            marginRight: 12
                                                                        }}>
                                                                            Исполнено
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{
                                                                            position: "absolute",
                                                                            top: -10,
                                                                            right: 0,
                                                                            backgroundColor: "#ff5722",
                                                                            color: "white",
                                                                            fontFamily: "Roboto",
                                                                            fontSize: 12,
                                                                            fontWeight: 400,
                                                                            display: "inline-block",
                                                                            padding: "4px 6px 3px",
                                                                            // marginTop: 6,
                                                                            borderRadius: 4,
                                                                            marginRight: 12
                                                                        }}>
                                                                            Ошибка от
                                                                        </div>
                                                                    )
                                                                )
                                                            ) : (
                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: -10,
                                                                    right: 0,
                                                                    backgroundColor: "#2196f3",
                                                                    color: "white",
                                                                    fontFamily: "Roboto",
                                                                    fontSize: 12,
                                                                    fontWeight: 400,
                                                                    display: "inline-block",
                                                                    padding: "4px 6px 3px",
                                                                    // marginTop: 6,
                                                                    borderRadius: 4,
                                                                    marginRight: 12
                                                                }}>
                                                                    Исполняется от
                                                                </div>
                                                            )
                                                    ) : (

                                                        Number(device.completion_time) > 0 ? (
                                                        <div style={{
                                                        position: "absolute",
                                                        top: -10,
                                                        right: 0,
                                                        backgroundColor: "#9e9e9e",
                                                        color: "white",
                                                        fontFamily: "Roboto",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        display: "inline-block",
                                                        padding: "4px 6px 3px",
                                                        // marginTop: 6,
                                                        borderRadius: 4,
                                                        marginRight: 12
                                                    }}>
                                                        Отменено
                                                        </div>
                                                        ) : (
                                                            <div style={{
                                                                position: "absolute",
                                                                top: -10,
                                                                right: 0,
                                                                backgroundColor: "#000000",
                                                                color: "white",
                                                                fontFamily: "Roboto",
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                display: "inline-block",
                                                                padding: "4px 6px 3px",
                                                                // marginTop: 6,
                                                                borderRadius: 4,
                                                                marginRight: 12
                                                            }}>
                                                                Ожидает от
                                                            </div>
                                                        )
                                                        )}

                                                </li>
                                                {isUnroll(device.id) ? (
                                                    <li>
                                                        {device.download_apk_log ? (
                                                            <table className="Body__table" style={{marginBottom: 24}}>
                                                                <tbody>
                                                                {device.download_apk_log.map((app, index) => (
                                                                    <tr className="Table__tr" key={index}
                                                                        style={{
                                                                            cursor: "default"
                                                                        }}
                                                                    >
                                                                        <td className="Table__td"
                                                                            style={{
                                                                            paddingLeft: 24
                                                                        }}>

                                                                            {app.message}

                                                                        </td>
                                                                        <td className="Table__td"
                                                                            style={{
                                                                                width: 42,
                                                                                paddingRight: 24,
                                                                                textAlign: "right"
                                                                            }}>
                                                                            {lastVisitDataTime(app.time)}

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        ) : null}

                                                    </li>
                                                ) : null}
                                            </React.Fragment>
                                        ))}

                                        <div
                                            style={{
                                                padding: "0 10px",
                                                height: 42 + 24 + 16,
                                                width: "calc(100% - 20px)"
                                            }}>
                                        </div>

                                    </ul>












                                </div>
                            </div>
                        </section>
                    </React.Fragment>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        isRequest: state.api.isRequest,
        responseData: state.api.responseData,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Journal);
