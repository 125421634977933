import React from "react";
import {connect} from "react-redux";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {formLoad} from "../../../../../store/actions/formAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import {modalClose} from "../../../../../store/actions/modalAction";
import {alertOpen} from "../../../../../store/actions/alertAction";
import Loading from "../../../../loading/Loading";
import getDateMonthYear from "../../../../../library/getDateMonthYear";
import inputDateMonthYear from "../../../../../library/inputDateMonthYear";

class FormCompanySupportsEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.interimData.company_id,
            supportType: 1,
            countNumber: null,
            countDate: inputDateMonthYear(new Date().getTime() / 1000),
            formResponseError: null,
        };
    }

    componentDidMount() {
        this.formUpdate(this.state.isLoadForm);
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMountForm) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoadForm !== this.state.isLoadForm) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.formData) {
                    // console.log(3 + ' responseData');

                    this.formUpdate();

                    this.setState({
                        isOpenForm: true,
                        isLoadForm: this.props.isLoadForm,
                        isUpdateForm: this.props.isUpdateForm,
                        formData: responseData
                    });
                }
            }

            else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdateForm: this.props.isUpdateForm
                });
                this.formUpdate(this.state.isLoadForm);
            }
        }
    }

    formUpdate(isLoadForm) {
        this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
            this.props.formLoad(isLoadForm);
        }, null);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        if (this.state.countDate) {

            if (dateRegular.test(this.state.countDate)) {

                const countDate = new Date(this.state.countDate).getTime() / 1000;

                if (countDate) {

                    if (this.state.countNumber > 0) {
                        let formData = new FormData();

                        formData.append('support_date', String(countDate));
                        formData.append('support_quant', this.state.countNumber);
                        formData.append('support_id', this.state.supportType);

                        this.setState({
                            isHandleSubmit: true
                        });

                        this.props.apiRequest('/company/' + this.state.objectId + '/support', formData, () => {
                                this.setStateAndInput('countNumber', null);
                                this.setState({
                                    isHandleSubmit: false,
                                    formResponseError: null
                                });
                                this.formUpdate(this.state.isLoadForm);
                                this.props.pageUpdate();
                            }, () => {
                                this.setState({
                                    isHandleSubmit: false,
                                    formResponseError: this.props.responseError
                                })
                            }
                        );

                    }

                    else {
                        this.setState({
                            formResponseError: 'Пожалуйста, укажите сумму поддержки.'
                        });
                    }
                }

                else {
                    this.setState({
                        formResponseError: 'Пожалуйста, кажите корректно месяц и день даты начала и завершения мероприятия.'
                    });
                }
            }

            else {
                this.setState({
                    formResponseError: 'Пожалуйста, заполните в правильном формате дату начала и завершения мероприятия (2019-12-31).'
                });
            }
        }

        else {
            this.setState({
                formResponseError: 'Пожалуйста, укажите дату начала и завершения мероприятия.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    render() {
        const { isOpenForm, isHandleSubmit, formResponseError, countNumber, countDate, formData, supportType } = this.state;

        let alertData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        // Переменная для отображения поступлений если есть.
        let supportArraySelected = false;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Поддержка предприятия</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Поддержка</div>
                                <select className="Box__select" name="supportType" value={supportType} onChange={this.handleInputChange.bind(this)}>
                                    <option value={1}>Адресная поддрежка</option>
                                    <option value={2}>Поддержка занятости</option>
                                    <option value={3}>Системные меры</option>
                                </select>
                        </div>

                        <div className="Content__box">
                            <div className="Box__counts">
                                {!isOpenForm ? (<Loading/>) : (
                                    formData ? (
                                        formData.length > 0 ? (

                                            <React.Fragment>

                                                {formData.length > 0 ? (
                                                    formData.map((support) => (
                                                        Number(support.support_name_id) === Number(supportType) ? supportArraySelected = true : false
                                                    ))
                                                ) : null}

                                                {supportArraySelected ? (
                                                    <table className="Counts__table">
                                                        <thead>
                                                        <tr className="Table__tr">
                                                            <th className="Table__th">
                                                                <div className="Th__title">Дата поступлений</div>
                                                                <div className="Th__count">Сумма</div>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {formData.map(support => (
                                                            (Number(support.support_name_id) === Number(supportType) ? (
                                                                <tr className="Table__tr" key={support.support_id}>
                                                                    <td className="Table__td">
                                                                        <div className="Link">
                                                                            <div className="Program__name">{getDateMonthYear(support.support_date)}</div>
                                                                            <div className="Program__count">{Number(support.support_quant)}</div>
                                                                            <div className="Program__delete"
                                                                                 onClick={this.alertOpen.bind(this, 'alertCompanySupportDelete', alertData(this.state.objectId, null, support.support_id))}>Удалить</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ) : null)
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                ) : (<div className='Box__text Box__text_center Box__text_disabled'>Поддержка не содержит записи о поступлениях</div>)}

                                            </React.Fragment>

                                        ) : (<div className='Box__text Box__text_center Box__text_disabled'>Поддержка не содержит записи о поступлениях</div>)
                                    ) : (<div className='Box__text Box__text_center Box__text_disabled'>Поддержка не содержит записи о поступлениях</div>)
                                )}
                            </div>
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Сумма</div>
                            <input className="Box__input"
                                   id="countNumber"
                                   name="countNumber"
                                   type="number"
                                   min={0}
                                   max={1000000000}
                                   defaultValue={countNumber}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Дата поступлений</div>
                            <input className="Box__input"
                                   name="countDate"
                                   type="date"
                                   defaultValue={countDate}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{formResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isHandleSubmit ? 'disabled' : null}>{isHandleSubmit ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCompanySupportsEdit);
