import React from "react";
import '../../Form.scss';
import {modalClose} from "../../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../../loading/Loading";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";

class ProjectProblemUnresolved extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectProblemDeleteResponseError: null
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        this.props.apiRequest('/problem/' + this.props.interimData.problem_id + '/open', null, () => {
                this.props.modalClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
            }, () => {
                this.setState({
                    projectProblemDeleteResponseError: this.props.responseError
                })
            }
        );
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { isRequest } = this.props;
        const { projectProblemDeleteResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Решение проблемы</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__text">Вы уверены что хотите отменить решение проблемы?</div>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{projectProblemDeleteResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Отменить решение'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProblemUnresolved);
