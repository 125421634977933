import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class ProfileData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileFio: null,
            profilePhone: null,
            positionAtPlaceOfWork: null,
            profileDataResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            profileFio: this.props.interimData.user_fio,
            profilePhone: this.props.interimData.user_tlp,
            positionAtPlaceOfWork: this.props.interimData.user_dolj
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.profileFio) {
            this.setState({profileDataResponseError: 'Поле ФИО не должно быть пустым.'});
        }

        else {
            let formData = new FormData();
            formData.append('profile_fio', this.state.profileFio);
            formData.append('profile_tlp', this.state.profilePhone);
            formData.append('profile_dolj', this.state.positionAtPlaceOfWork);

            this.props.apiRequest('/profile/edit', formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        profileDataResponseError: this.props.responseError
                    })
                }
            );
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { interimData, isRequest } = this.props;
        const { profileDataResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Учетные данные</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">ФИО *</div>
                            <input className="Box__input"
                                   name="profileFio"
                                   type="text"
                                   defaultValue={interimData.user_fio}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Должность</div>
                            <input className="Box__input"
                                   name="positionAtPlaceOfWork"
                                   type="text"
                                   defaultValue={interimData.user_dolj}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Контактный телефон</div>
                            <input className="Box__input"
                                   name="profilePhone"
                                   type="tel"
                                   defaultValue={interimData.user_tlp}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{profileDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileData);
