import React from "react";
import {connect} from "react-redux";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import {modalClose} from "../../../../../store/actions/modalAction";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";

class FormMailingCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHandleSubmit: false,
            formResponseError: null,
            templateTitle: '',
            templateText: ''
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let formData = new FormData();

        formData.append('subject', this.state.templateTitle);
        formData.append('body', this.state.templateText);

        this.setState({
            isHandleSubmit: true
        });

        this.props.apiRequest('/mailing/template', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: null
                });
                this.modalClose();
                this.props.pageUpdate();
                this.props.notificationMessage(this.props.responseMessage, 'green');
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: this.props.responseError
                })
            }
        );
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { isHandleSubmit, formResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Новый шаблон</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Заголовок письма</div>
                            <input className="Box__input"
                                   name="templateTitle"
                                   type="text"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Текст письма</div>
                            <input className="Box__input"
                                   name="templateText"
                                   type="text"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{formResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isHandleSubmit ? 'disabled' : null}>{isHandleSubmit ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalClose: () => dispatch(modalClose()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormMailingCreate);
