import React from "react";
import {modalClose, modalOpen} from "../../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../../loading/Loading";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";

class CompanyIndicatorEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objectId: this.props.interimData.company_id,


            indicatorYear: new Date().getFullYear(),
            oldYear: new Date().getFullYear(),
            indicatorQuarter: 1,
            oldQuarter: 1,

            indicatorYearArray: [...Array.from(Array(new Date().getFullYear() - 1969).keys(),x => new Date().getFullYear() - x)],




            indicatorProceeds: 0,
            indicatorExpenses: 0,
            indicatorSalary: 0,
            indicatorInsurancePremiums: 0,
            indicatorsTax: 0,
            indicatorsLaborCosts: 0,
            indicatorsProceeds2: 0,
            indicatorsNumber: 0,








            indicatorsData: null,
            isIndicatorsData: false,







            companySupportEditResponseError: null
        };
    }

    componentDidMount() {
        this.formIndicatorsData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            // console.log(this.state.indicatorYear + ' ' + this.state.oldYear);
            // console.log(this.state.indicatorQuarter + ' ' + this.state.oldQuarter);

            if (this.state.indicatorQuarter !== this.state.oldQuarter) {
                this.formQuarterData();
                this.setState({
                    oldQuarter: this.state.indicatorQuarter
                });
            }

            if (this.state.indicatorYear !== this.state.oldYear) {
                this.formIndicatorsData();
                this.setState({
                    oldYear: this.state.indicatorYear
                });
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.indicatorProceeds > 0 &&
            this.state.indicatorExpenses > 0 &&
            this.state.indicatorSalary > 0 &&
            this.state.indicatorInsurancePremiums > 0 &&
            this.state.indicatorsTax > 0 &&
            this.state.indicatorsLaborCosts > 0 &&
            this.state.indicatorsProceeds2 > 0 &&
            this.state.indicatorsNumber > 0) {

            let formData = new FormData();

            formData.append('indicators_quarter', this.state.indicatorQuarter);
            formData.append('indicators_year', this.state.indicatorYear);

            formData.append('indicators_proceeds', this.state.indicatorProceeds);
            formData.append('indicators_direct_expenses', this.state.indicatorExpenses);
            formData.append('indicators_salary', this.state.indicatorSalary);
            formData.append('indicators_insurance_premiums', this.state.indicatorInsurancePremiums);
            formData.append('indicators_tax', this.state.indicatorsTax);
            formData.append('indicators_labor_costs', this.state.indicatorsLaborCosts);
            formData.append('indicators_proceeds2', this.state.indicatorsProceeds2);
            formData.append('indicators_number', this.state.indicatorsNumber);

            this.props.apiRequest('/company/' + this.state.objectId + '/indicator_create', formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        companySupportEditResponseError: this.props.responseError
                    })
                }
            );
        }

        else {
            this.setState({
                companySupportEditResponseError: 'Пожалуйста, укажите все показатели за текущий период.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    formIndicatorsData() {

        let formData = new FormData();

        formData.append('year', this.state.indicatorYear);

        this.setState({
            isIndicatorsData: true
        });

        this.props.apiRequest('/company/' + this.state.objectId + '/indicator_year', formData, () => {
            this.setState({
                indicatorsData: this.props.responseData,
                isIndicatorsData: false
            }, () => {
                this.formQuarterData();
            });
            // console.log(this.state.indicatorsData);
        }, null);
    }

    formQuarterData() {
        let indicatorsData = this.state.indicatorsData;

        let indicatorProceeds = 0;
        let indicatorExpenses = 0;
        let indicatorSalary = 0;
        let indicatorInsurancePremiums = 0;
        let indicatorsTax = 0;
        let indicatorsLaborCosts = 0;
        let indicatorsProceeds2 = 0;
        let indicatorsNumber = 0;

        if (indicatorsData.length > 0) {

            for (let i = 0; i < indicatorsData.length; i++) {

                if (Number(this.state.indicatorQuarter) === Number(indicatorsData[i].indicators_quarter)) {
                    indicatorProceeds = Number(indicatorsData[i].indicators_proceeds);
                    indicatorExpenses = Number(indicatorsData[i].indicators_direct_expenses);
                    indicatorSalary = Number(indicatorsData[i].indicators_salary);
                    indicatorInsurancePremiums = Number(indicatorsData[i].indicators_insurance_premiums);
                    indicatorsTax = Number(indicatorsData[i].indicators_tax);
                    indicatorsLaborCosts = Number(indicatorsData[i].indicators_labor_costs);
                    indicatorsProceeds2 = Number(indicatorsData[i].indicators_proceeds2);
                    indicatorsNumber = Number(indicatorsData[i].indicators_number);

                    this.setState({
                        indicatorProceeds: indicatorProceeds,
                        indicatorExpenses: indicatorExpenses,
                        indicatorSalary: indicatorSalary,
                        indicatorInsurancePremiums: indicatorInsurancePremiums,
                        indicatorsTax: indicatorsTax,
                        indicatorsLaborCosts: indicatorsLaborCosts,
                        indicatorsProceeds2: indicatorsProceeds2,
                        indicatorsNumber: indicatorsNumber,
                    });

                    this.setStateAndInput('indicatorProceeds', indicatorProceeds);
                    this.setStateAndInput('indicatorExpenses', indicatorExpenses);
                    this.setStateAndInput('indicatorSalary', indicatorSalary);
                    this.setStateAndInput('indicatorInsurancePremiums', indicatorInsurancePremiums);
                    this.setStateAndInput('indicatorsTax', indicatorsTax);
                    this.setStateAndInput('indicatorsLaborCosts', indicatorsLaborCosts);
                    this.setStateAndInput('indicatorsProceeds2', indicatorsProceeds2);
                    this.setStateAndInput('indicatorsNumber', indicatorsNumber);
                }
            }
        }

    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    inputDateMonthYear(date) {

        const dateMonthYear = new Date(date * 1000);

        let dd = dateMonthYear.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = dateMonthYear.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        let yyyy = dateMonthYear.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;

        return yyyy + '-' + mm + '-' + dd;
    }

    onClickQuestion(elementId, text) {

        if (document.getElementById('hint' + elementId).innerHTML === text) {
            document.getElementById('hint' + elementId).innerHTML = null;
        }

        else {
            document.getElementById('hint' + elementId).innerHTML = text;
        }
    }

    render() {
        const { isRequest } = this.props;
        const { companySupportEditResponseError,

            indicatorYear,
            indicatorQuarter,
            indicatorYearArray,

            indicatorProceeds,
            indicatorExpenses,
            indicatorSalary,
            indicatorInsurancePremiums,
            indicatorsTax,
            indicatorsLaborCosts,
            indicatorsProceeds2,
            indicatorsNumber,

        } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Показатели предприятия</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Показатели в год</div>
                            <select className="Box__select" name="indicatorYear" value={indicatorYear} onChange={this.handleInputChange.bind(this)}>
                                {indicatorYearArray.map(year => (<option value={year} key={year}>{year} год</option>))}
                            </select>
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Показатели в квартал</div>
                            <select className="Box__select" name="indicatorQuarter" value={indicatorQuarter} onChange={this.handleInputChange.bind(this)}>
                                <option value={1}>1 квартал</option>
                                <option value={2}>2 квартал</option>
                                <option value={3}>3 квартал</option>
                                <option value={4}>4 квартал</option>
                            </select>
                        </div>

                        <div className="Content__br" />

                        <div className="Content__box">
                            <div className='Box__text Box__text_center'>Добавленная стоимость на единицу затрат труда</div>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 1, 'Сумма строк 11 («Выручка от реализации товаров (работ, услуг) собственного производства») и 12 («Выручка от реализации покупных товаров») приложения № 1 к Листу 2 формы налоговой декларации по налогу на прибыль организации')}>?</div>
                            <div className="Box__label">Выручка (млн. рублей)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorProceeds"
                                   name="indicatorProceeds"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorProceeds}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint1" className="Box__info"/>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 2, 'Сумма строк 10 («Прямые расходы, относящиеся к реализованным товарам (работам, услугам)») и 20 («Прямые расходы налогоплательщиков, осуществляющих оптовую, мелкооптовую и розничную торговлю в текущем отчетном (налоговом) периоде, относящиеся к реализованным товарам») приложения № 2 к Листу 2 формы налоговой декларации по налогу на прибыль организации')}>?</div>
                            <div className="Box__label">Прямые расходы (млн. рублей)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorExpenses"
                                   name="indicatorExpenses"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorExpenses}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint2" className="Box__info"/>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 3, 'Рассчитывается на основе данных Справки 2-НДФЛ')}>?</div>
                            <div className="Box__label">Сумма расходов на оплату труда работников (млн. рублей)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorSalary"
                                   name="indicatorSalary"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorSalary}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint3" className="Box__info"/>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 4, 'Страховые взносы, уплачиваемые предприятиями в государственные внебюджетные фонды Российской Федерации')}>?</div>
                            <div className="Box__label">Сумма страховых взносов (млн. рублей)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorInsurancePremiums"
                                   name="indicatorInsurancePremiums"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorInsurancePremiums}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint4" className="Box__info"/>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 5, 'Налог на имущество - строка 220 раздела 2 налоговой декларации по налогу на имущество организаций')}>?</div>
                            <div className="Box__label">Сумма налогов, включаемых в себестоимость (млн. рублей)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorsTax"
                                   name="indicatorsTax"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorsTax}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint5" className="Box__info"/>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 6, 'Среднемесячное количество застрахованных лиц по данным четырех квартальных форм по страховым взносам')}>?</div>
                            <div className="Box__label">Затраты труда (чел.)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorsLaborCosts"
                                   name="indicatorsLaborCosts"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorsLaborCosts}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint6" className="Box__info"/>
                        </div>

                        <div className="Content__br" />

                        <div className="Content__box">
                            <div className='Box__text Box__text_center'>Выручка на численность</div>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 7, 'Строка 2110 ОФР')}>?</div>
                            <div className="Box__label">Выручка (млн. рублей)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorsProceeds2"
                                   name="indicatorsProceeds2"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorsProceeds2}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint7" className="Box__info"/>
                        </div>

                        <div className="Content__box">
                            <div className="Label__question icon-question"
                                 onClick={this.onClickQuestion.bind(this, 8, 'Среднесписочная численность сотрудников за год по форме П-4')}>?</div>
                            <div className="Box__label">Численность сотрудников (чел.)</div>
                            <input className="Box__input Box__input_margin-none"
                                   id="indicatorsNumber"
                                   name="indicatorsNumber"
                                   type="number"
                                   step={0.1}
                                   min={0}
                                   defaultValue={indicatorsNumber}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                            <div id="hint8" className="Box__info"/>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{companySupportEditResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>

                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyIndicatorEdit);
