import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class ProfilePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordNew: null,
            passwordRepeat: null,
            profilePasswordResponseError: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.passwordNew && !this.state.passwordRepeat) {
            this.setState({
                profilePasswordResponseError: 'Пожалуйста, внимательно заполните все поля.'
            });
        }

        else {
            if (this.state.passwordNew === this.state.passwordRepeat) {
                let formData = new FormData();
                formData.append('profile_password', this.state.passwordNew);

                this.props.apiRequest('/profile/password', formData, () => {
                        this.props.modalClose();
                        this.props.pageUpdate();
                    }, () => {
                        this.setState({
                            profilePasswordResponseError: this.props.responseError
                        })
                    }
                );
            }

            else {
                this.setState({
                    profilePasswordResponseError: 'Повторный пароль введен неверно.'
                });
            }
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { isRequest } = this.props;
        const { profilePasswordResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Изменение пароля</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">Новый пароль</div>
                            <input className="Box__input"
                                   name="passwordNew"
                                   type="password"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Повторите пароль, чтобы не ошибиться</div>
                            <input className="Box__input"
                                   name="passwordRepeat"
                                   type="password"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{profilePasswordResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Изменить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isRequest: state.api.isRequest,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
