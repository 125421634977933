export default function baseURL() {
    if (document.location.host === 'localhost') {
        return 'http://localhost/';
    }

    else if (document.location.host === 'localhost:7000') {
        return 'http://localhost/';
    }

    else if (document.location.host === 'uadmin.otk-inform.ru') {
        return '/';
    }

    else {
        console.log('Error, the address connection is not defined.');
        return null;
    }
}