import axios from "axios";
// import {push} from "react-router-redux"
import {AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS} from "./actionTypes";
import baseURL from "../../library/baseURL";

// Желательно реализовать перенаправление в authLogout при помощи react-router-redux.
// Желательно реализовать при autoLogin запрос к API на /protected.

export function authLogin(formData, callbackSuccess, callbackError) {
    return async dispatch => {
        dispatch(authRequest());

        try {
            const response = await axios.post('api/auth', formData, {baseURL: baseURL(), headers: {'Content-Type': 'multipart/form-data'}});

            const data = response.data;
            const expireDate = new Date(data.auth.expireAt * 1000);

            localStorage.setItem('tokenSession', response.data.auth.token);
            localStorage.setItem('expireDate', expireDate);

            dispatch(authSuccess(data.auth.token));

            // console.log((expireDate.getTime() - new Date().getTime()) / 1000);

            dispatch(autoLogout((expireDate.getTime() - new Date().getTime()) / 1000));
            // dispatch(autoLogout(((expireDate.getTime() - new Date().getTime()) / 1000) - 431900));

            callbackSuccess();
        }

        catch (error) {
            dispatch(authError('Неверный пароль или адрес эл. почты'));

            callbackError();
        }
    }
}

export function authRequest() {
    // console.log('Auth request start.');

    return {
        type: AUTH_REQUEST
    }
}

export function authError(authError) {
    // console.log('Auth request error.');

    return {
        type: AUTH_ERROR,
        authError
    }
}

export function autoLogout(time) {
    return dispatch => {
        setTimeout(() => {dispatch(authLogout(null))}, time * 1000);
    }
}

export function authLogout(callback) {
    localStorage.removeItem('tokenSession');
    localStorage.removeItem('expireDate');

    if (callback) callback();

    return {
        type: AUTH_LOGOUT
    }
}

export function autoLogin() {
    return dispatch => {
        const token = localStorage.getItem('tokenSession');

        if (!token) {
            dispatch(authLogout(null));
        }

        else {
            const expireDate = new Date(localStorage.getItem('expireDate'));

            if (expireDate <= new Date()) {
                dispatch(authLogout(null));
            }

            else {
                dispatch(authSuccess(token));

                // console.log((expireDate.getTime() - new Date().getTime()) / 1000);

                dispatch(autoLogout((expireDate.getTime() - new Date().getTime()) / 1000));
                // dispatch(autoLogout(((expireDate.getTime() - new Date().getTime()) / 1000) - 431900));
            }
        }
    }
}

export function authSuccess(token) {
    return {
        type: AUTH_SUCCESS,
        token
    }
}
