import React from "react";
import {connect} from "react-redux";
import {modalOpen} from "../../../store/actions/modalAction";
import {apiRequest} from "../../../store/actions/apiAction";
import {pageLoad, pageMount, pageUpdate} from "../../../store/actions/pageAction";
import {notificationMessage} from "../../../store/actions/notificationAction";
import "./Devices.scss";
import "./Students.scss";
import Loading from "../../loading/Loading";
import getDateMonthYear from "../../../library/getDateMonthYear";
import getTimeHourMinSec from "../../../library/getTimeHourMinSec";

class Devices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            appsData: null,
            selectApp: null,
            selectVersion: null,
            addAppVersion: null,
            editApp: null,
            editVersion: null,
            appName: null,

            devicesData: null,
            addDevices: false,
            newDevice: null,
            selectDevice: null,
            archiveDevice: null,
            downloadDevice: null,
            uninstallApp: null,


            unrollArray: []
        };

        this.appInput = React.createRef();
    }

    componentDidMount() {
        this.props.notificationMessage('Кабинет авторизирован', 'black');
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // setInterval(this.props.pageUpdate, 1000);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 60000);
    }

    componentWillUnmount() {
        // console.log('Apps Unmounted');
        clearInterval(this.intervalUpdate);
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.devicesData) {
                    // console.log(3 + ' responseData');
                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        devicesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdate: this.props.isUpdate
                });
                this.pageUpdate(this.state.isLoad);
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/devices', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }


    // electronicNotification() {
    //     if (this.state.electronicNotification) {
    //         this.setState({
    //             electronicNotification: false
    //         });
    //         this.props.apiRequest('/profile/mail_notcheck', null, () => {
    //             this.props.notificationMessage('Системное оповещение отключено!', 'red');
    //         }, () => {
    //             this.setState({
    //                 electronicNotification: true
    //             });
    //         });
    //     }
    //
    //     else {
    //         this.setState({
    //             electronicNotification: true
    //         });
    //         this.props.apiRequest('/profile/mail_check', null, () => {
    //             this.props.notificationMessage('Системное оповещение включено', 'green');
    //         }, () => {
    //             this.setState({
    //                 electronicNotification: false
    //             });
    //         });
    //     }
    // }

    speckText() {

        // // Создаем распознаватель
        // // var recognizer = new webkitSpeechRecognition();
        // var recognizer = new SpeechRecognition();
        //
        // // Ставим опцию, чтобы распознавание началось ещё до того, как пользователь закончит говорить
        //         recognizer.interimResults = true;
        //
        // // Какой язык будем распознавать?
        //         recognizer.lang = 'ru-Ru';
        //
        // // Используем колбек для обработки результатов
        //         recognizer.onresult = function (event) {
        //             var result = event.results[event.resultIndex];
        //             if (result.isFinal) {
        //                 alert('Вы сказали: ' + result[0].transcript);
        //             } else {
        //                 console.log('Промежуточный результат: ', result[0].transcript);
        //             }
        //         };
        //
        // // Начинаем слушать микрофон и распознавать голос
        //         recognizer.start();

        // speechSynthesis.speak(
        //     new SpeechSynthesisUtterance('Сап, Двач. Я ламповый V8 JavaScript Engine')
        // );

        // let voices = speechSynthesis.getVoices();
        // let utterance = new SpeechSynthesisUtterance('Кожанные Россияне');
        // utterance.voice = voices[1];
        // speechSynthesis.speak(utterance);
    }

    handleAppChange() {
        let app = this.appInput.current.files.item(0);
        this.uploadApp(app);
    }

    uploadApp(app) {
        let formData = new FormData();

        formData.append('app_file', app);

        this.props.apiRequest('api/cabinet/apps/upload', formData, () => {
                this.setState({
                    addAppVersion: this.props.responseData
                });
                this.props.pageUpdate();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    selectAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            selectApp: app,
            selectVersion: version
        });
    }

    closeAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            selectApp: null,
            selectVersion: null,
            addAppVersion: null
        });
    }

    editAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            editApp: app,
            editVersion: version,
            appName: app.app_name
        });
    }

    closeEditAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            editApp: null,
            editVersion: null,
            appName: null
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    requestEditApp(app, name) {
        let formData = new FormData();

        formData.append('app_id', app.id);
        formData.append('app_name', name);

        this.props.apiRequest('api/cabinet/apps/edit/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeEditAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }



    requestArchiveVersion(version) {

        this.props.apiRequest('api/cabinet/apps/archive/version/' + version.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeArchiveAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    openAddDevices() {
        document.body.classList.add('overflow_hidden');
        this.setState({
            addDevices: true
        });
    }

    closeAddDevices() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            addDevices: false
        });
    }

    addDevices() {
        this.props.apiRequest('api/cabinet/devices/add', null, () => {
                this.setState({
                    newDevice: this.props.responseData
                });
                this.closeAddDevices();
                this.props.pageUpdate();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    closeNewDevice() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            newDevice: null
        });
    }

    selectDevice(device) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            selectDevice: device
        });
    }

    closeDevice() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            selectDevice: null
        });
    }

    archiveDevice(device) {
        this.setState({
            archiveDevice: device
        });
    }

    closeArchiveDevice() {
        this.setState({
            archiveDevice: null
        });
    }

    requestArchiveDevice(device) {
        this.props.apiRequest('api/cabinet/devices/archive/device/' + device.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeArchiveDevice();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    uninstallApp(device, app) {
        this.setState({
            selectDevice: device,
            uninstallApp: app
        });
    }

    closeUninstallApp() {
        this.setState({
            uninstallApp: null
        });
    }

    requestUninstallApp(app) {
        let device = this.state.selectDevice;
        let formData = new FormData();

        formData.append('device_id', device.id);
        formData.append('version_id', app.version_id);

        this.props.apiRequest('api/cabinet/devices/uninstall/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeUninstallApp();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    uninstallTask(task) {
        this.props.apiRequest('api/cabinet/devices/cancel/task/' + task.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    requestDownloadDevice(device) {
        this.props.apiRequest('api/cabinet/devices/download/device/' + device.id, null, () => {
                this.setState({
                    selectDevice: device,
                    downloadDevice: this.props.responseData
                });
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    closeDownloadDevice() {
        this.setState({
            downloadDevice: null
        });
    }

    requestDownloadApp(version) {
        let device = this.state.selectDevice;
        let formData = new FormData();

        formData.append('device_id', device.id);
        formData.append('version_id', version.id);

        this.props.apiRequest('api/cabinet/devices/download/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeDownloadDevice();
                this.closeDevice();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }











    unrollArray(id) {
        let unrollArray = this.state.unrollArray;
        let repeat = false;

        if (unrollArray.length > 0) {

            unrollArray.map((object, index) => {
                if (Number(object) === Number(id)) {
                    repeat = true;
                    unrollArray.splice(index, 1);
                }
            });
        }

        if (!repeat) {

            unrollArray.push(id);
        }

        this.setState({
            unrollArray: unrollArray
        });
    }




    clipboard(clipboard) {
        navigator.clipboard.writeText(clipboard)
            .then(() => {
                this.props.notificationMessage('UID: ' + clipboard + ', скопирован.', 'green');
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    }


    sleepDevice() {
        this.props.notificationMessage('Функция выключения экрана не доступна для данного устройства.', 'red');
    }



    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }


    openAllDevices() {
        let devicesData = this.state.devicesData;
        let unrollArray = [];


        devicesData.map(object => {
            unrollArray.push(object.id);
        });

        this.setState({
            unrollArray: unrollArray
        });
    }

    closeAllDevices() {
        this.setState({
            unrollArray: []
        });
    }


    printOpen(device) {
        let url = '/api/print/device/' + device.id;

        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    }

    render() {
        const { isRequest } = this.props;
        const { isMount, isOpen, appsData, selectApp, selectVersion, addAppVersion, editApp, editVersion, appName, archiveApp, archiveVersion,

            devicesData,
            addDevices,
            newDevice,
            selectDevice,
            archiveDevice,
            downloadDevice,
            uninstallApp,


            unrollArray

        } = this.state;

        if (isMount) {

            if (!isOpen || !devicesData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {


                let isUnroll = (id) => {
                    let repeat = false;

                    if (unrollArray.length > 0) {

                        unrollArray.map(object => {
                            if (Number(object) === Number(id)) {
                                repeat = true;
                            }
                        });
                    }

                    return repeat;
                }

                let lastVisit = (time) => {

                    return (
                        <div className={((parseInt(String(new Date().getTime()/1000)) - time) < 300) ? 'Student__online Student__online_green' : 'Student__online'}/>
                    );
                }

                let lastVisitDataTime = (time) => {

                    if (Number(time) > 0) {

                        if ((parseInt(String(new Date().getTime()/1000)) - time) < 300) {

                            return 'сейчас';
                        }

                        else {
                            let date = new Date(time * 1000);
                            let day = "0" + date.getDate();
                            let month = "0" + (date.getMonth() + 1);
                            let year = String(date.getFullYear());
                            let hours = "0" + date.getHours();
                            let minutes = "0" + date.getMinutes();
                            let seconds = "0" + date.getSeconds();
                            let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                            // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                            let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                            return formattedTime + ' ' + formattedData;
                        }
                    }

                    else {

                        return 'никогда';
                    }
                }


                let uidTitle = (uid) => {
                    let uidTitle = devi(String(uid), 8);

                    return uidTitle[0] + '-' + uidTitle[1] + '-' + uidTitle[2] + '-' + uidTitle[3];
                }


                let devi = (str, max=4, arr=[]) => {
                    arr.push(str.substring(max*(arr.length), max*(arr.length+1)));
                    if (arr.length <= (str.length / max | 0)) return devi(str, max, arr);
                    else if (arr[arr.length-1].length < max) arr.pop();
                    return arr;
                };



                let modalData = (objectId, objectData) => {
                    return {
                        objectId: objectId,
                        objectData: objectData
                    };
                }

                return (
                    <React.Fragment>
                        <section>
                            <div className="container">

                                <div className="module__wrapper">
                                    <div className="module__header">
                                        <div className="header__title">Устройства</div>
                                        <label>

                                            <div className='button header__button button_blue'
                                                 onClick={this.openAddDevices.bind(this)}>
                                                Добавить устройство
                                            </div>

                                            {devicesData.length === unrollArray.length ? (

                                                <div className='button header__button button_blue'
                                                     style={{
                                                         backgroundColor: "#f1f1f1",
                                                         marginRight: 24,
                                                         color: "#2a3e4c",
                                                         opacity: .5
                                                     }}
                                                     onClick={this.closeAllDevices.bind(this)}>
                                                    Свернуть все
                                                </div>

                                            ) : (

                                            <div className='button header__button button_blue'
                                                 style={{
                                                     backgroundColor: "#f1f1f1",
                                                     marginRight: 24,
                                                     color: "#2a3e4c",
                                                     opacity: .5
                                                 }}
                                                 onClick={this.openAllDevices.bind(this)}>
                                                Развернуть все
                                            </div>

                                            )}

                                        </label>
                                    </div>

                                    {/*<table className="table__device">*/}
                                    {/*<tbody>*/}
                                    {/*    <tr className="tr__device_header">*/}
                                    {/*        <th className="th__device">№</th>*/}
                                    {/*        <th className="th__device">UID</th>*/}
                                    {/*        <th className="th__device">Последний запрос</th>*/}
                                    {/*        <th className="th__device">Приложения</th>*/}
                                    {/*        <th className="th__device">Задачи</th>*/}
                                    {/*    </tr>*/}

                                    {/*{devicesData.map((device, index) => (*/}
                                    {/*    // device.versions.length > 0 ? (*/}
                                    {/*        <React.Fragment key={index}>*/}
                                    {/*            /!*<div className="module__field_header">*!/*/}
                                    {/*            /!*    <div className="field_header__title">{device.uid}</div>*!/*/}
                                    {/*            /!*    /!*<a className="header__link">{device.app_signature}</a>*!/*!/*/}
                                    {/*            /!*    <button id="id"*!/*/}
                                    {/*            /!*            className="button field_header__button button_gray"*!/*/}
                                    {/*            /!*            type="submit" name="open_edit_app"*!/*/}
                                    {/*            /!*            onClick={this.editAppVersion.bind(this, device, null)}>Редактировать</button>*!/*/}
                                    {/*            /!*</div>*!/*/}

                                    {/*            /!*{app.versions.map((version, index) => (*!/*/}
                                    {/*            /!*    <div className="module__field_inline" key={index}>*!/*/}
                                    {/*            /!*        <div className="field_inline__box">*!/*/}
                                    {/*            /!*            <div className="field_inline__label">Версия</div>*!/*/}
                                    {/*            /!*            <div className="field_inline__text">*!/*/}
                                    {/*            /!*                {version.version_name + ' ' + version.version_number + ' (' + version.version_code + ')'}*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*        </div>*!/*/}
                                    {/*            /!*        <div className="field_inline__hr">*!/*/}
                                    {/*            /!*            <div className="hr__line" />*!/*/}
                                    {/*            /!*        </div>*!/*/}
                                    {/*            /!*        <div className="field_inline__box">*!/*/}
                                    {/*            /!*            <div className="field_inline__label">Обновление</div>*!/*/}
                                    {/*            /!*            <div*!/*/}
                                    {/*            /!*                className={Number(version.access_update) > 0 ? "field_inline__text text_green" : "field_inline__text text_red"}>*!/*/}
                                    {/*            /!*                {Number(version.access_update) > 0 ? "Доступно" : "Не доступно"}*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*        </div>*!/*/}

                                    {/*            /!*        {Number(version.access_update) > 0 ? (*!/*/}
                                    {/*            /!*            <div className="field_inline__box">*!/*/}
                                    {/*            /!*                <div className="field_inline__label">От</div>*!/*/}
                                    {/*            /!*                <div className="field_inline__text">*!/*/}
                                    {/*            /!*                    {getDateMonthYear(version.release_date)}*!/*/}
                                    {/*            /!*                </div>*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*        ) : null}*!/*/}

                                    {/*            /!*        <div className="field_inline__box_right">*!/*/}
                                    {/*            /!*            <div className="field_inline__hr">*!/*/}
                                    {/*            /!*                <div className="hr__line" />*!/*/}
                                    {/*            /!*            </div>*!/*/}
                                    {/*            /!*            <div className="button field_inline__button"*!/*/}
                                    {/*            /!*                 onClick={this.selectAppVersion.bind(this, app, version)}>Подробнее</div>*!/*/}
                                    {/*            /!*        </div>*!/*/}
                                    {/*            /!*    </div>*!/*/}
                                    {/*            /!*))}*!/*/}

                                    {/*            /!*<br/><br/><br/><br/>*!/*/}


                                    {/*            <tr className="tr__device">*/}
                                    {/*                <td className="td__device">{device.id}</td>*/}
                                    {/*                <td className="td__device">*/}
                                    {/*                    <button className="button device__link link_blue"*/}
                                    {/*                            type="submit"*/}
                                    {/*                            name="open_edit_device"*/}
                                    {/*                    onClick={this.selectDevice.bind(this, device)}>*/}
                                    {/*                        {device.uid}*/}
                                    {/*                    </button>*/}
                                    {/*                    /!*<button id="{$devices_array[$i]['uid']}"*!/*/}
                                    {/*                    /!*        className="button_icon icon-copy-48" type="submit"*!/*/}
                                    {/*                    /!*        name="copy_id"></button>*!/*/}
                                    {/*                </td>*/}
                                    {/*                <td className="td__device">{device.request_time ? (getDateMonthYear(device.request_time) + ' ' + getTimeHourMinSec(device.request_time)) : null}</td>*/}
                                    {/*                <td className="td__device">*/}
                                    {/*                    {device.apps_on_device ? device.apps_on_device.map(app => (*/}
                                    {/*                        app.app_name + ' ' + app.version_number + ' '*/}
                                    {/*                    )) : null}*/}
                                    {/*                </td>*/}
                                    {/*                <td className="td__device">{device.tasks ? device.tasks.length : null}</td>*/}
                                    {/*            </tr>*/}


                                    {/*        </React.Fragment>*/}
                                    {/*    // ) : null*/}
                                    {/*))}*/}
                                    {/*</tbody>*/}
                                    {/*</table>*/}

















                                    <ul className="Body__list">

                                                {devicesData.map((device, index) => (
                                                    <React.Fragment key={index}>
                                                        <li className={isUnroll(device.id) ? 'List__item active' : 'List__item'}
                                                            onClick={this.unrollArray.bind(this, device.id)}
                                                            style={{
                                                                padding: "12px 24px 7px 24px",
                                                                position: "relative"
                                                            }}>
                                                            {/*<div className="Table__td">{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>*/}
                                                            {/*<div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>*/}

                                                            {/*{trainingGroup.direction_preparation ? (*/}
                                                            {/*    <React.Fragment>*/}
                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*        <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.direction_preparation}</div>*/}
                                                            {/*    </React.Fragment>*/}
                                                            {/*) : null}*/}

                                                            {/*{trainingGroup.employee_fio ? (*/}
                                                            {/*    <React.Fragment>*/}
                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*        <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.employee_fio}</div>*/}
                                                            {/*    </React.Fragment>*/}
                                                            {/*    ) : null}*/}

                                                                <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>
                                                                    {device.id}
                                                                </div>
                                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                <div className="Table__td" style={{
                                                                    display: "inline-block", verticalAlign: "top", paddingTop: 6,
                                                                    fontWeight: 400,
                                                                    fontSize: 16
                                                                }}>
                                                                    {device.name ? (device.name !== '' ? device.name : uidTitle(device.uid)) : uidTitle(device.uid)}
                                                                    {/*{uidTitle(device.uid)}*/}

                                                                </div>


                                                            {device.info ? (device.info !== '' ? (
                                                                <React.Fragment>
                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                            <div className="Table__td" style={{
                                                                display: "inline-block", verticalAlign: "top", paddingTop: 6,
                                                                fontWeight: 400,
                                                                fontSize: 16
                                                            }}>
                                                                {device.info ? (device.info !== '' ? device.info : null) : null}
                                                            </div>
                                                                </React.Fragment>
                                                                )  : null) : null }


                                                            <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 7, marginLeft: 24, float: "right"}}>
                                                                {lastVisit(device.request_time)}
                                                            </div>
                                                            <div className="Table__td" style={{
                                                                display: "inline-block", verticalAlign: "top", paddingTop: 6, float: "right",
                                                                fontWeight: 400,
                                                                fontSize: 16
                                                            }}>
                                                                {lastVisitDataTime(device.request_time)}
                                                            </div>

                                                            {device.name ? (device.name !== '' ? (
                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: -10,
                                                                    right: 0,
                                                                    backgroundColor: "#000",
                                                                    color: "white",
                                                                    fontFamily: "Roboto",
                                                                    fontSize: 12,
                                                                    fontWeight: 400,
                                                                    display: "inline-block",
                                                                    padding: "4px 6px 3px",
                                                                    // marginTop: 6,
                                                                    borderRadius: 4,
                                                                    marginRight: 12
                                                                }}>
                                                                    {uidTitle(device.uid)}
                                                                </div>
                                                            ) : null) : null}

                                                            {device.phone ? (device.phone !== '' ? (
                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: -10,
                                                                    left: 0,
                                                                    backgroundColor: "#000",
                                                                    color: "white",
                                                                    fontFamily: "Roboto",
                                                                    fontSize: 12,
                                                                    fontWeight: 400,
                                                                    display: "inline-block",
                                                                    padding: "4px 6px 3px",
                                                                    // marginTop: 6,
                                                                    borderRadius: 4,
                                                                    marginLeft: 12
                                                                }}>
                                                                    {device.provider ? (device.provider !== '' ? device.provider + ':' : null) : null} {device.phone}
                                                                </div>
                                                            ) : null) : null}
                                                        </li>
                                                        {isUnroll(device.id) ? (
                                                            <li>
                                                                {device.apps_on_device ? (
                                                                    <table className="Body__table" style={{marginBottom: 0}}>
                                                                        <tbody>
                                                                        {device.apps_on_device.map((app, index) => (
                                                                            <tr className="Table__tr" key={index}
                                                                                style={{
                                                                                    cursor: "default"
                                                                                }}
                                                                                // onClick={
                                                                                // this.modalOpen.bind(this, 'modalStudent', modalData(student.id, student))
                                                                            // }
                                                                            >
                                                                                <td className="Table__td">
                                                                                    {/*{app.student_avatar ? (*/}
                                                                                    {/*    <div style={{*/}
                                                                                    {/*        position: "relative"*/}
                                                                                    {/*    }}>*/}
                                                                                    {/*        <div className="Student__photo"/>*/}
                                                                                    {/*        <img style={{*/}
                                                                                    {/*            position: "absolute",*/}
                                                                                    {/*            top: 2,*/}
                                                                                    {/*            left: 2,*/}
                                                                                    {/*            width: 32,*/}
                                                                                    {/*            height: 32,*/}
                                                                                    {/*            borderRadius: "50%",*/}
                                                                                    {/*            opacity: 1*/}
                                                                                    {/*        }}*/}
                                                                                    {/*             src={baseURL() + 'api/images/avatar/' + app.student_avatar}*/}
                                                                                    {/*             width="100"*/}
                                                                                    {/*             height="100"/>*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*) : (*/}
                                                                                    {/*    <div style={{*/}
                                                                                    {/*        position: "relative"*/}
                                                                                    {/*    }}>*/}
                                                                                    {/*        <div className="Student__photo"/>*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*)}*/}

                                                                                    {app.app_name} ({app.version_number})


                                                                                </td>
                                                                                {/*<td className="Table__td"/>*/}
                                                                                {/*<td className="Table__td"/>*/}
                                                                                {/*<td className="Table__td"/>*/}
                                                                                {/*<td className="Table__td"/>*/}
                                                                                <td className="Table__td"
                                                                                style={{
                                                                                    width: 42, paddingRight: 10
                                                                                }}>
                                                                                    <div className="Button__edit"
                                                                                         style={{
                                                                                             // verticalAlign:"top",
                                                                                             // margin: "24px 0 24px 24px",
                                                                                             margin: 0,
                                                                                             float: "right"
                                                                                         }}
                                                                                        // onClick={this.modalOpen.bind(this, 'modalTrainingGroupEdit', modalData(trainingGroup.id, trainingGroup))}
                                                                                         onClick={this.uninstallApp.bind(this, device, app)}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                                            <g id="Сгруппировать_2247" data-name="Сгруппировать 2247" transform="translate(-563 -746)">
                                                                                                <rect id="Прямоугольник_2757" data-name="Прямоугольник 2757" width="42" height="42" transform="translate(563 746)" fill="#f7f7f7" opacity="0"/>
                                                                                                <g id="Сгруппировать_1571" data-name="Сгруппировать 1571" transform="translate(-1248 342)">
                                                                                                    <rect id="Прямоугольник_1285" data-name="Прямоугольник 1285" width="24" height="24" rx="12" transform="translate(1820 413)" fill="#f44336"/>
                                                                                                    <rect id="Прямоугольник_1288" data-name="Прямоугольник 1288" width="10" height="4" rx="1" transform="translate(1827 423)" fill="#f7f7f7"/>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>




                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : null}


                                                                {device.tasks ? (
                                                                    <table className="Body__table" style={{marginTop: (device.tasks.length > 0 ? 24 : 0)}}>
                                                                        <tbody>
                                                                        {device.tasks.map((task, index) => (
                                                                            <tr className="Table__tr" key={index}
                                                                                style={{
                                                                                    cursor: "default",
                                                                                    backgroundColor: "#ededed"
                                                                                }}
                                                                                // onClick={
                                                                                // this.modalOpen.bind(this, 'modalStudent', modalData(student.id, student))
                                                                                // }
                                                                            >
                                                                                <td className="Table__td"
                                                                                style={{position: "relative"}}>

                                                                                    {task.app.app_name} ({task.app.version_number})


                                                                                    {task.assigned_task === 'setup' ? (
                                                                                        <div style={{
                                                                                            position: "absolute",
                                                                                            top: -14,
                                                                                            left: 14,
                                                                                            backgroundColor: "#0b7aff",
                                                                                            color: "white",
                                                                                            fontFamily: "Roboto",
                                                                                            fontSize: 14,
                                                                                            fontWeight: 400,
                                                                                            display: "inline-block",
                                                                                            padding: "4px 6px",
                                                                                            // marginTop: 6,
                                                                                            borderRadius: 4,
                                                                                            marginRight: 12
                                                                                        }}>
                                                                                            Загрузка
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {task.assigned_task === 'uninstall' ? (
                                                                                        <div style={{
                                                                                            position: "absolute",
                                                                                            top: -14,
                                                                                            left: 14,
                                                                                            backgroundColor: "#f44336",
                                                                                            color: "white",
                                                                                            fontFamily: "Roboto",
                                                                                            fontSize: 14,
                                                                                            fontWeight: 400,
                                                                                            display: "inline-block",
                                                                                            padding: "4px 6px",
                                                                                            // marginTop: 6,
                                                                                            borderRadius: 4,
                                                                                            marginRight: 12
                                                                                        }}>
                                                                                            Удаление
                                                                                        </div>
                                                                                    ) : null}
                                                                                </td>

                                                                                <td className="Table__td"
                                                                                    style={{
                                                                                        width: 42,
                                                                                        paddingRight: 10
                                                                                    }}>

                                                                                    <div className="Button__edit"
                                                                                         style={{
                                                                                             // verticalAlign:"top",
                                                                                             // margin: "24px 0 24px 24px",
                                                                                             margin: 0,
                                                                                             float: "right"
                                                                                         }}
                                                                                        // onClick={this.modalOpen.bind(this, 'modalTrainingGroupEdit', modalData(trainingGroup.id, trainingGroup))}
                                                                                         onClick={this.uninstallTask.bind(this, task)}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                                            <g id="Сгруппировать_2248" data-name="Сгруппировать 2248" transform="translate(-563 -746)">
                                                                                                <rect id="Прямоугольник_2757" data-name="Прямоугольник 2757" width="42" height="42" transform="translate(563 746)" fill="#f7f7f7" opacity="0"/>
                                                                                                <g id="Сгруппировать_2249" data-name="Сгруппировать 2249" transform="translate(511.668 -416.692) rotate(45)">
                                                                                                    <g id="Сгруппировать_2249-2" data-name="Сгруппировать 2249" transform="translate(310 56)">
                                                                                                        <rect id="Прямоугольник_1641" data-name="Прямоугольник 1641" width="5" height="12" rx="1" transform="translate(576 727)" fill="#2a3e4c"/>
                                                                                                        <rect id="Прямоугольник_1642" data-name="Прямоугольник 1642" width="12" height="5" rx="1" transform="translate(569 727)" fill="#2a3e4c"/>
                                                                                                    </g>
                                                                                                    <g id="Сгруппировать_2250" data-name="Сгруппировать 2250" transform="translate(1467 1515) rotate(180)">
                                                                                                        <rect id="Прямоугольник_1641-2" data-name="Прямоугольник 1641" width="5" height="12" rx="1" transform="translate(576 727)" fill="#2a3e4c"/>
                                                                                                        <rect id="Прямоугольник_1642-2" data-name="Прямоугольник 1642" width="12" height="5" rx="1" transform="translate(569 727)" fill="#2a3e4c"/>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>




                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : null}

                                                                {/*<div className="Button__add"*/}
                                                                {/*     // onClick={this.modalOpen.bind(this, 'modalStudentAdd', modalData(trainingGroup.id, null))}*/}
                                                                {/*>*/}
                                                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                {/*        <g id="Group_1662" data-name="Group 1662" transform="translate(-1826 -274)">*/}
                                                                {/*            <circle id="Ellipse_61" data-name="Ellipse 61" cx="21" cy="21" r="21" transform="translate(1826 274)" fill="#0061ff"/>*/}
                                                                {/*            <rect id="Rectangle_2052" data-name="Rectangle 2052" width="4" height="14" rx="1" transform="translate(1845 288)" fill="#fff"/>*/}
                                                                {/*            <rect id="Rectangle_2053" data-name="Rectangle 2053" width="4" height="14" rx="1" transform="translate(1854 293) rotate(90)" fill="#fff"/>*/}
                                                                {/*        </g>*/}
                                                                {/*    </svg>*/}

                                                                {/*    <div className="Add__text">Студент</div>*/}
                                                                {/*</div>*/}

                                                                <div
                                                                style={{
                                                                    padding: "0 10px",
                                                                    height: 42 + 24 + 16,
                                                                    width: "calc(100% - 20px)"
                                                                }}>

                                                                    {device.request_time ? (


                                                                    <div className="Button__edit"
                                                                         style={{
                                                                             verticalAlign:"top",
                                                                             float: "left"
                                                                         }}
                                                                        // onClick={this.modalOpen.bind(this, 'modalTrainingGroupEdit', modalData(trainingGroup.id, trainingGroup))}
                                                                         onClick={this.requestDownloadDevice.bind(this, device)}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                            <g id="Сгруппировать_2245" data-name="Сгруппировать 2245" transform="translate(-563 -746)">
                                                                                <rect id="Прямоугольник_2757" data-name="Прямоугольник 2757" width="42" height="42" transform="translate(563 746)" fill="#f7f7f7" opacity="0"/>
                                                                                <g id="Сгруппировать_2246" data-name="Сгруппировать 2246" transform="translate(446.297 -132.621) rotate(30)">
                                                                                    <rect id="Прямоугольник_1640" data-name="Прямоугольник 1640" width="5" height="16" rx="1" transform="translate(567 705)" fill="#2a3e4c"/>
                                                                                    <g id="Сгруппировать_2243" data-name="Сгруппировать 2243" transform="translate(-355.41 595.763) rotate(-45)">
                                                                                        <rect id="Прямоугольник_1641" data-name="Прямоугольник 1641" width="5" height="12" rx="1" transform="translate(576 727)" fill="#2a3e4c"/>
                                                                                        <rect id="Прямоугольник_1642" data-name="Прямоугольник 1642" width="12" height="5" rx="1" transform="translate(569 727)" fill="#2a3e4c"/>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>


                                                                    </div>



                                                                    ) : null}


                                                                    <div className="Button__edit"
                                                                         style={{
                                                                             verticalAlign:"top",
                                                                             float: "left"
                                                                         }}
                                                                        onClick={this.modalOpen.bind(this, 'modalDeviceNameEdit', modalData(device.id, device))}

                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                            <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">
                                                                                <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>
                                                                                <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">
                                                                                    <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>
                                                                                    <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                    </div>



                                                                    <div className="Button__edit" style={{
                                                                        display: "inline-block",
                                                                        height: 16,
                                                                        width: 16,
                                                                        // marginLeft: 16,
                                                                        cursor: "pointer",
                                                                        verticalAlign:"top",
                                                                        margin: "24px 24px 24px 0",
                                                                        float: "left"
                                                                    }}
                                                                         onClick={this.clipboard.bind(this, device.uid)}>
                                                                        <svg style={{
                                                                            verticalAlign:"top"
                                                                        }}
                                                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                            <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                                                <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                                                </g>
                                                                                <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>

                                                                    {device.request_time ? (

                                                                    <div className="Button__edit"
                                                                         style={{
                                                                             verticalAlign:"top",
                                                                             float: "left",
                                                                             // marginLeft: 24
                                                                         }}
                                                                        // onClick={this.modalOpen.bind(this, 'modalTrainingGroupEdit', modalData(trainingGroup.id, trainingGroup))}
                                                                         onClick={this.sleepDevice.bind(this)}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                            <g id="Сгруппировать_2257" data-name="Сгруппировать 2257" transform="translate(-1157 -703)">
                                                                                <g id="Сгруппировать_2255" data-name="Сгруппировать 2255" transform="translate(594 -43)">
                                                                                    <rect id="Прямоугольник_2757" data-name="Прямоугольник 2757" width="42" height="42" transform="translate(563 746)" fill="#f7f7f7" opacity="0.31"/>
                                                                                </g>
                                                                                <rect id="Прямоугольник_1641" data-name="Прямоугольник 1641" width="26" height="18" rx="3" transform="translate(1165 715)" fill="#2a3e4c" stroke="#2a3e4c" strokeWidth="3"/>
                                                                                <g id="Сгруппировать_2256" data-name="Сгруппировать 2256" transform="translate(976.61 -509.573) rotate(45)">
                                                                                    <rect id="Прямоугольник_1641-2" data-name="Прямоугольник 1641" width="7" height="3" rx="1.5" transform="translate(1014 722)" fill="#f7f7f7"/>
                                                                                    <rect id="Прямоугольник_1641-3" data-name="Прямоугольник 1641" width="3" height="7" rx="1.5" transform="translate(1016 720)" fill="#f7f7f7"/>
                                                                                </g>
                                                                            </g>
                                                                        </svg>

                                                                    </div>




                                                                    ) : null}




                                                                    <div className="Button__edit"
                                                                         style={{
                                                                             verticalAlign:"top",
                                                                             margin: "24px 0 24px 24px",
                                                                             float: "right"
                                                                         }}
                                                                        // onClick={this.modalOpen.bind(this, 'modalTrainingGroupEdit', modalData(trainingGroup.id, trainingGroup))}
                                                                         onClick={this.archiveDevice.bind(this, device)}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                            <g id="Сгруппировать_2242" data-name="Сгруппировать 2242" transform="translate(-563 -746)">
                                                                                <rect id="Прямоугольник_2757" data-name="Прямоугольник 2757" width="42" height="42" transform="translate(563 746)" fill="#f7f7f7" opacity="0"/>
                                                                                <g id="Сгруппировать_1571" data-name="Сгруппировать 1571" transform="translate(-1248 344)">
                                                                                    <rect id="Прямоугольник_1285" data-name="Прямоугольник 1285" width="24" height="6" rx="2" transform="translate(1820 413)" fill="#2a3e4c"/>
                                                                                    <path id="Прямоугольник_1286" data-name="Прямоугольник 1286" d="M1.571,0H20.429A1.564,1.564,0,0,1,22,1.556v9.333A3.127,3.127,0,0,1,18.857,14H3.143A3.127,3.127,0,0,1,0,10.889V1.556A1.564,1.564,0,0,1,1.571,0Z" transform="translate(1821 420)" fill="#aeb5ba"/>
                                                                                    <rect id="Прямоугольник_1288" data-name="Прямоугольник 1288" width="8" height="3" rx="1" transform="translate(1828 424)" fill="#f7f7f7"/>
                                                                                </g>
                                                                            </g>
                                                                        </svg>



                                                                    </div>



                                                                    <div className="Button__edit"
                                                                         onClick={this.printOpen.bind(this, device)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                            <g id="Сгруппировать_1679" data-name="Сгруппировать 1679" transform="translate(-1765 -274)">
                                                                                <circle id="Эллипс_62" data-name="Эллипс 62" cx="21" cy="21" r="21" transform="translate(1765 274)" fill="#2a3e4c" opacity="0"/>
                                                                                <path id="print-solid" d="M21,9V3.621a1.5,1.5,0,0,0-.439-1.061L18.439.439A1.5,1.5,0,0,0,17.378,0H4.5A1.5,1.5,0,0,0,3,1.5V9a3,3,0,0,0-3,3v5.25A.75.75,0,0,0,.75,18H3v4.5A1.5,1.5,0,0,0,4.5,24h15A1.5,1.5,0,0,0,21,22.5V18h2.25a.75.75,0,0,0,.75-.75V12A3,3,0,0,0,21,9ZM18,21H6V16.5H18Zm0-10.5H6V3h9V5.25a.75.75,0,0,0,.75.75H18Zm2.25,3.375a1.125,1.125,0,1,1,1.125-1.125A1.125,1.125,0,0,1,20.25,13.875Z" transform="translate(1774 283)" fill="#2a3e4c"/>
                                                                            </g>
                                                                        </svg>

                                                                    </div>



                                                                {/*<div className="Button__send"*/}
                                                                {/*     // onClick={this.alertOpen.bind(this, 'alertTrainingGroupSendSuggestions', alertData(trainingGroup.id, trainingGroup))}*/}
                                                                {/*>*/}
                                                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                {/*        <g id="Group_2236" data-name="Group 2236" transform="translate(-480 -760)">*/}
                                                                {/*            <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(480 760)" fill="#f7f7f7" opacity="0"/>*/}
                                                                {/*            <g id="Group_2234" data-name="Group 2234" transform="translate(-98 -4)">*/}
                                                                {/*                <path id="bell-solid" d="M10.5,24a3,3,0,0,0,3-3h-6A3,3,0,0,0,10.5,24Zm10.1-7.018C19.69,16.009,18,14.545,18,9.75a7.4,7.4,0,0,0-6-7.273V1.5a1.5,1.5,0,1,0-3,0v.977A7.4,7.4,0,0,0,3,9.75c0,4.8-1.695,6.259-2.6,7.232A1.465,1.465,0,0,0,0,18a1.5,1.5,0,0,0,1.5,1.5H19.495A1.5,1.5,0,0,0,21,18,1.464,1.464,0,0,0,20.6,16.982Z" transform="translate(600.408 771.358) rotate(30)" fill="#2a3e4c"/>*/}
                                                                {/*                <path id="at-solid" d="M14,8a6,6,0,1,0,3.276,11.027.581.581,0,0,0,.134-.856l-.246-.3a.579.579,0,0,0-.759-.124A4.452,4.452,0,1,1,14,9.548c2.423,0,4.452,1.394,4.452,3.871a1.889,1.889,0,0,1-1.407,2.025c-.42-.011-.409-.311-.326-.726l.567-2.93a.581.581,0,0,0-.57-.691H15.627a.327.327,0,0,0-.325.29v0a1.86,1.86,0,0,0-1.451-.527,3.451,3.451,0,0,0-3.335,3.664,2.273,2.273,0,0,0,2.323,2.561,2.448,2.448,0,0,0,1.814-.927c.23.825.983.825,1.711.825A3.319,3.319,0,0,0,20,13.419C20,10.121,17.339,8,14,8Zm-.525,7.365a.867.867,0,0,1-.873-.986,1.616,1.616,0,0,1,1.418-1.76c.539,0,.861.369.861.986a1.67,1.67,0,0,1-1.407,1.76Z" transform="translate(575 763)" fill="#2a3e4c"/>*/}
                                                                {/*            </g>*/}
                                                                {/*        </g>*/}
                                                                {/*    </svg>*/}

                                                                {/*</div>*/}

                                                                </div>
                                                            </li>
                                                        ) : null}
                                                    </React.Fragment>
                                                ))}

                                    </ul>



















                                </div>
                            </div>
                        </section>


                        {addDevices ? (
                            <div className="module__modal modal_add_device">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">
                                            <div className="modal__title">Новое устройсво</div>
                                            <div className="modal__form">
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button className="button field__button button_left"
                                                                type="submit"
                                                                name="add_device"
                                                                tabIndex="1"
                                                                onClick={this.addDevices.bind(this)}>
                                                            Добавить
                                                        </button>
                                                        <button
                                                            className="button field__button button_white_blue button__border_none link"
                                                            type="submit"
                                                            name="close_add_device"
                                                            tabIndex="2"
                                                            onClick={this.closeAddDevices.bind(this)}>
                                                            Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {newDevice ? (
                            <div className="module__modal modal_new_device">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">
                                            <div className="modal__title" style={{
                                                font: '400 16px Roboto'
                                            }}>
                                                {newDevice.device_uid}
                                            </div>
                                            <div className="modal__role">Новое устройство</div>
                                            <div className="modal__form">
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button className="button field__button button__gray button_left"
                                                                type="submit"
                                                                name="close_new_device"
                                                                onClick={this.closeNewDevice.bind(this)}>
                                                            Закрыть
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {/*{selectDevice ? (*/}
                        {/*    <div className="module__modal modal_edit_device">*/}
                        {/*        <div className="modal__overlay">*/}
                        {/*            <div className="modal__window">*/}
                        {/*                <div className="modal__content">*/}
                        {/*                    <div className="modal__title" style={{*/}
                        {/*                        font: '400 16px Roboto'*/}
                        {/*                    }}>{selectDevice.uid}</div>*/}
                        {/*                    <div className="modal__role">Устройство</div>*/}
                        {/*                    <input type="hidden" name="device_id" />*/}
                        {/*                        <div name="apps_on_device">*/}
                        {/*                            {selectDevice.apps_on_device ? (*/}
                        {/*                                <React.Fragment>*/}
                        {/*                                    <div className="form__title">Приложения</div>*/}
                        {/*                                    {selectDevice.apps_on_device.map((app, index) => (*/}


                        {/*                                    <div key={index} className="form__title text_black"*/}
                        {/*                                         style={{*/}
                        {/*                                             font: 'font: 400 16px Roboto',*/}
                        {/*                                             marginBottom: 24*/}
                        {/*                                         }}>*/}
                        {/*                                        {app.app_name}&nbsp;{app.version_number}&nbsp;&nbsp;*/}
                        {/*                                        <button className="button field__button button_red button_left button_small"*/}
                        {/*                                                type="submit"*/}
                        {/*                                        onClick={this.uninstallApp.bind(this, app)}>*/}
                        {/*                                            Удалить*/}
                        {/*                                        </button>*/}
                        {/*                                    </div>*/}
                        {/*                                ))}*/}
                        {/*                                </React.Fragment>*/}
                        {/*                            ) : null}*/}
                        {/*                        </div>*/}
                        {/*                        <div name="device_tasks">*/}
                        {/*                            {selectDevice.tasks ? (*/}
                        {/*                                <React.Fragment>*/}
                        {/*                                    <br/>*/}
                        {/*                                    <div className="form__title">Задачи</div>*/}
                        {/*                                    {selectDevice.tasks.map((task, index) => (*/}
                        {/*                                        <div key={index} className={task.assigned_task === 'uninstall' ? 'form__title text_red' : 'form__title text_blue'}*/}
                        {/*                                             style={{*/}
                        {/*                                                 font: 'font: 400 16px Roboto',*/}
                        {/*                                                 marginBottom: 24*/}
                        {/*                                             }}>*/}
                        {/*                                            {task.app.app_name}&nbsp;{task.app.version_number}&nbsp;&nbsp;*/}
                        {/*                                            <button className="button field__button button__gray button_left button_small"*/}
                        {/*                                                    type="submit"*/}
                        {/*                                                    onClick={this.uninstallTask.bind(this, task)}>*/}
                        {/*                                                Отмена*/}
                        {/*                                            </button>*/}
                        {/*                                        </div>*/}
                        {/*                                    ))}*/}
                        {/*                                </React.Fragment>*/}
                        {/*                            ) : null}*/}
                        {/*                        </div>*/}
                        {/*                        {selectDevice.tasks ? (*/}
                        {/*                            <button id="" className="button device__link link_blue button_left"*/}
                        {/*                                    type="submit" name="open_download_app_to_device"*/}
                        {/*                            onClick={this.requestDownloadDevice.bind(this, selectDevice)}>Загрузить*/}
                        {/*                                приложение?*/}
                        {/*                            </button>*/}
                        {/*                        ) : null}*/}
                        {/*                        <div className="modal__form">*/}
                        {/*                            <div className="form__field">*/}
                        {/*                                <button id=""*/}
                        {/*                                        className="button field__button button_red button_left"*/}
                        {/*                                        type="submit" name="put_in_archive_device"*/}
                        {/*                                        onClick={this.archiveDevice.bind(this, selectDevice)}>Удалить*/}
                        {/*                                    устройство*/}
                        {/*                                </button>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="form__footer">*/}
                        {/*                                <div className="form__field">*/}
                        {/*                                    <button*/}
                        {/*                                        className="button field__button button__gray button_left"*/}
                        {/*                                        type="submit" name="close_edit_device"*/}
                        {/*                                        tabIndex="1"*/}
                        {/*                                    onClick={this.closeDevice.bind(this)}>Закрыть*/}
                        {/*                                    </button>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*) : null}*/}

                        {downloadDevice ? (
                            <div className="module__modal modal_download_app_to_device">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">
                                            <div className="modal__title">Приложения</div>

                                            <div className="modal__form">
                                                <div name="apps_download">
                                                    {downloadDevice.map((app, index) => (
                                                        app.versions.length > 0 ? (
                                                        <React.Fragment key={index}>
                                                        <div className="form__title">{app.app_name}</div>
                                                            <div  className="form__field"
                                                                 style={{
                                                                     marginBottom: 12
                                                                 }}>
                                                        {app.versions.map((version, index) => (
                                                                <div key={index}>
                                                                    <button className="button device__link link_blue button_left"
                                                                            type="submit"
                                                                            name="save_download_app_to_device"
                                                                            style={{
                                                                                marginBottom: 24
                                                                            }}
                                                                    onClick={this.requestDownloadApp.bind(this, version)}>
                                                                        {version.version_name} {version.version_number} ({version.version_code})
                                                                    </button>
                                                                </div>
                                                            ))}
                                                            </div>
                                                        </React.Fragment>
                                                        ) : null
                                                    ))}
                                                </div>
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button
                                                            className="button field__button button__gray button_left"
                                                            type="submit" name="close_download_app_to_device"
                                                            onClick={this.closeDownloadDevice.bind(this)}>Закрыть
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {uninstallApp ? (
                            <div className="module__modal modal_uninstall_app_on_device">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">
                                            <div className="modal__title">{uninstallApp.app_name}&nbsp;{uninstallApp.version_number}</div>
                                            <div className="modal__role">Приложение</div>
                                            <div className="modal__form">
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button id=""
                                                                className="button field__button button_red button_left"
                                                                type="submit" name="save_uninstall_app_on_device"
                                                                onClick={this.requestUninstallApp.bind(this, uninstallApp)}>Удалить
                                                        </button>
                                                        <button
                                                            className="button field__button button_white_gray button__border_none link"
                                                            type="submit" name="close_uninstall_app_on_device"
                                                            onClick={this.closeUninstallApp.bind(this)}>Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {archiveDevice ? (
                            <div className="module__modal modal_put_in_archive_app">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content content__widthone">
                                            <div className="modal__title" style={{font: '400 16px Roboto'}}>{archiveDevice.uid}</div>
                                            <div className="modal__role">Устройство</div>
                                            <div className="modal__form">
                                                <div className="form__info">Внимание! <br />
                                                Прекращение работы учётной записи устройства, данные будут сохранены в архиве.
                                                </div>
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button id=""
                                                                className="button field__button button_red button_left"
                                                                type="submit" name="save_app_in_archive"
                                                                tabIndex="1"
                                                                onClick={this.requestArchiveDevice.bind(this, archiveDevice)}>Продолжить
                                                        </button>
                                                        <button
                                                            className="button field__button button_white_gray button__border_none link"
                                                            type="submit" name="close_put_in_archive_app" tabIndex="2"
                                                            autoFocus="true"
                                                            onClick={this.closeArchiveDevice.bind(this)}>Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    </React.Fragment>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        isRequest: state.api.isRequest,
        responseData: state.api.responseData,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
