import React from "react";
import '../Form.scss';
import {modalClose, modalOpen} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class ProjectPeriod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectDateStart: null,
            projectDateEnd: null,
            projectPeriodResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            projectDateStart: this.inputDateMonthYear(this.props.interimData.project_date_start),
            projectDateEnd: this.inputDateMonthYear(this.props.interimData.project_date_end)
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        if (this.state.projectDateStart && this.state.projectDateEnd) {

            if (dateRegular.test(this.state.projectDateStart) && dateRegular.test(this.state.projectDateEnd)) {

                const projectDateStart = new Date(this.state.projectDateStart).getTime() / 1000;
                const projectDateEnd = new Date(this.state.projectDateEnd).getTime() / 1000;

                if (projectDateStart && projectDateEnd) {

                    let formData = new FormData();

                    formData.append('project_date_start', String(projectDateStart));
                    formData.append('project_date_end', String(projectDateEnd));

                    this.props.apiRequest('/project/' + this.props.interimData.project_id + '/period', formData, () => {
                            this.props.modalClose();
                            this.props.notificationMessage(this.props.responseMessage, 'green');
                            this.props.pageUpdate();
                        }, () => {
                            this.setState({
                                projectPeriodResponseError: this.props.responseError
                            })
                        }
                    );
                }

                else {
                    this.setState({
                        projectPeriodResponseError: 'Пожалуйста, укажите корректно месяц и день даты начала и завершения проекта.'
                    });
                }
            }

            else {
                this.setState({
                    projectPeriodResponseError: 'Пожалуйста, заполните в правильном формате дату начала и завершения проекта (2019-12-31).'
                });
            }
        }

        else {
            this.setState({
                projectPeriodResponseError: 'Пожалуйста, укажите дату начала и завершения проекта.'
            });
        }
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    modalClose() {
        this.props.modalClose();
    }

    inputDateMonthYear(date) {

        const dateMonthYear = new Date(date * 1000);

        let dd = dateMonthYear.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = dateMonthYear.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        let yyyy = dateMonthYear.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;

        return yyyy + '-' + mm + '-' + dd;
    }

    render() {
        const { interimData, isRequest } = this.props;
        const { projectPeriodResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Срок проведения</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Дата начала проекта</div>
                            <input className="Box__input"
                                   name="projectDateStart"
                                   type="date"
                                   defaultValue={this.inputDateMonthYear(interimData.project_date_start)}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Дата завершения проекта</div>
                            <input className="Box__input"
                                   name="projectDateEnd"
                                   type="date"
                                   defaultValue={this.inputDateMonthYear(interimData.project_date_end)}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{projectPeriodResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPeriod);
