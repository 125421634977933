import React from "react";
import {connect} from "react-redux";
import {alertClose} from "../../../../../store/actions/alertAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import {modalClose} from "../../../../../store/actions/modalAction";
import Loading from "../../../../loading/Loading";

class AlertCompanyDelete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objectId: null,
            objectType: null,
            objectData: null,
            isHandleSubmit: false,
            alertResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            objectData: this.props.alertData.objectData,
            objectId: this.props.alertData.objectId,
            objectType: this.props.alertData.objectType
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isHandleSubmit: true
        });

        this.props.apiRequest('/company/' + this.state.objectId + '/delete', null, () => {
                this.setState({
                    isHandleSubmit: false
                });
                this.props.modalClose();
                this.props.pageUpdate();
                this.props.alertClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                })
            }
        );
    }

    alertClose() {
        this.props.alertClose();
    }

    render() {
        const { isHandleSubmit, alertResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Удаление предприятия</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__text">Вы уверены что хотите удалить предприятие и все прикрепленные к нему записи из системы ИСУП?</div>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{alertResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'} type="submit" disabled={isHandleSubmit ? 'disabled' : null}>{isHandleSubmit ? <Loading white={true} /> : 'Удалить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.alertClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alertData: state.alert.alertData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalClose: () => dispatch(modalClose()),
        alertClose: () => dispatch(alertClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertCompanyDelete);
