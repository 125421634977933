import {CABINET_EDIT, CABINET_OPEN} from "../actions/actionTypes";

const initialState = {
    thereLevelAccess: false,
    levelAccess: 0,
    profileEmail: null,
    profileFIO: null,
    isEdit: false
}

export default function cabinetReducer(state = initialState, action) {
    switch (action.type) {
        case CABINET_OPEN:
            return {
                ...state, thereLevelAccess: true, levelAccess: action.levelAccess, profileEmail: action.profileEmail ,profileFIO: action.profileFIO
            }
        case CABINET_EDIT:
            return {
                ...state, isEdit: action.isEdit
            }
        default:
            return state;
    }
}
