import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class ProjectCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectName: null,
            projectTarget: '',
            projectType: 0,
            projectTypeData: null,
            projectDateStart: null,
            projectDateEnd: null,
            projectCreateResponseError: null
        };
    }

    componentDidMount() {
        this.projectTypeData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        if (this.state.projectName && this.state.projectType > 0 && this.state.projectDateStart && this.state.projectDateEnd) {

            if (dateRegular.test(this.state.projectDateStart) && dateRegular.test(this.state.projectDateEnd)) {

                const projectDateStart = new Date(this.state.projectDateStart).getTime() / 1000;
                const projectDateEnd = new Date(this.state.projectDateEnd).getTime() / 1000;

                if (projectDateStart && projectDateEnd) {

                    let formData = new FormData();

                    formData.append('project_name', this.state.projectName);
                    formData.append('project_target', this.state.projectTarget);
                    formData.append('project_type_id', this.state.projectType);
                    formData.append('project_date_start', String(projectDateStart));
                    formData.append('project_date_end', String(projectDateEnd));

                    this.props.apiRequest('/company/' + this.props.interimData.company_id + '/project', formData, () => {
                            this.props.modalClose();
                            this.props.notificationMessage(this.props.responseMessage, 'green');
                            this.props.pageUpdate();
                        }, () => {
                            this.setState({
                                projectCreateResponseError: this.props.responseError
                            })
                        }
                    );
                }

                else {
                    this.setState({
                        projectCreateResponseError: 'Пожалуйста, укажите корректно месяц и день даты начала и завершения проекта.'
                    });
                }
            }

            else {
                this.setState({
                    projectCreateResponseError: 'Пожалуйста, заполните в правильном формате дату начала и завершения проекта (2019-12-31).'
                });
            }
        }

        else {
            this.setState({
                projectCreateResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    projectTypeData() {
        this.props.apiRequest('/dictionary/project_type', null, () => {
            this.setState({
                projectTypeData: this.props.responseData
            });
        }, null);
    }

    render() {
        const { isRequest } = this.props;
        const { projectName, projectTarget, projectType, projectTypeData, projectDateStart, projectDateEnd, projectCreateResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Добавить проект</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">Название проекта *</div>
                            <input className="Box__input"
                                   name="projectName"
                                   type="text"
                                   defaultValue={projectName}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)}
                                   autoFocus />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Цель проекта</div>
                            <textarea className="Box__input"
                                      rows={5}
                                      defaultValue={projectTarget}
                                      name="projectTarget"
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Направление проекта *</div>
                            <select className="Box__select" name="projectType" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={projectType}>
                                <option value='0'>Выберите направление проекта</option>
                                {!projectTypeData ? null : (
                                    (projectTypeData.map(option => (
                                        (option.project_type_id > 0 ? <option value={option.project_type_id} key={option.project_type_id}>{option.project_type_name}</option> : null)
                                    ))))}
                            </select>
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Дата старта проекта *</div>
                            <input className="Box__input"
                                   name="projectDateStart"
                                   type="date"
                                   defaultValue={projectDateStart}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Дата окончания проекта *</div>
                            <input className="Box__input"
                                   name="projectDateEnd"
                                   type="date"
                                   defaultValue={projectDateEnd}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{projectCreateResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreate);
