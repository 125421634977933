import {combineReducers} from "redux";
import authReducer from "./authReducer";
import apiReducer from "./apiReducer";
import cabinetReducer from "./cabinetReducer";
import pageReducer from "./pageReducer";
import formReducer from "./formReducer";
import notificationReducer from './notificationReducer';
import modalReducer from "./modalReducer";
import alertReducer from "./alertReducer";

export default combineReducers({
    auth: authReducer,
    api: apiReducer,
    cabinet: cabinetReducer,
    page: pageReducer,
    form: formReducer,
    notification: notificationReducer,
    modal: modalReducer,
    alert: alertReducer
});
