import React from "react";
import '../../Form.scss';
import {modalClose, modalOpen} from "../../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../../loading/Loading";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";

class CompanyProgramData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objectId: this.props.interimData.objectId,
            objectType: this.props.interimData.objectType,
            programsData: null,
            isProgramsData: false,
            programName: null,
            filesDataResponseError: null
        };
    }

    componentDidMount() {
        this.formProgramsData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.programName) {
                    let formData = new FormData();

                    formData.append('program_name', this.state.programName);

                    this.props.apiRequest('/company/' + this.state.objectId + '/program', formData, () => {
                            this.setState({
                                filesDataResponseError: null
                            });
                            this.formProgramsData();
                            this.props.pageUpdate();
                        }, () => {
                            this.setState({
                                filesDataResponseError: this.props.responseError
                            })
                        }
                    );

        }

        else {
            this.setState({
                filesDataResponseError: 'Пожалуйста, укажите название программы обучения.'
            });
        }
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    modalClose() {
        this.props.modalClose();
    }

    formProgramsData() {
        this.setState({
            isProgramsData: true
        });

        this.props.apiRequest('/company/' + this.state.objectId + '/programs', null, () => {
            this.setState({
                programsData: this.props.responseData,
                isProgramsData: false
            });
        }, null);
    }

    render() {
        const { isRequest } = this.props;
        const {
            // objectId,
            // objectType,
            programName, isProgramsData, programsData, filesDataResponseError } = this.state;

        let interimData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        return (
            <div className="Form Form_width-690">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Программы обучения</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">


                            <div className="Box__programs">
                                {isProgramsData ? (<Loading />) : (
                                    programsData ? (
                                        programsData.length > 0 ? (
                                            <table className="Programs__table">
                                                <thead>
                                                <tr className="Table__tr">
                                                    <th className="Table__th">
                                                        <div className="Th__title">Программа обучения</div>
                                                        {/*<div className="Th__count">Обучившихся</div>*/}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {programsData.map(program => (
                                                    <tr className="Table__tr" key={program.program_id}>
                                                        <td className="Table__td">
                                                            <div className="Link">
                                                                <div className="Program__name">{program.program_name}</div>
                                                                <div className="Program__count">&nbsp;</div>
                                                                {/*<div className="Program__count_edit" onClick={this.modalOpen.bind(this, 'formCompanyProgramCountEdit', interimData(objectId, program.program_id, program.program_name))}>Обучившиеся</div>*/}
                                                                <div className="Program__edit" onClick={this.modalOpen.bind(this, 'formCompanyProgramEdit', interimData(program.program_id, null, program.program_name))}>Редактировать</div>
                                                                <div className="Program__delete" onClick={this.modalOpen.bind(this, 'formCompanyProgramDelete', interimData(program.program_id, null, program.program_name))}>Удалить</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        ):(
                                            <div className='Box__text Box__text_open Box__text_disabled'>У предприятия пока нет программ обучения</div>
                                        )) : (
                                        <div className='Box__text Box__text_open Box__text_disabled'>У предприятия пока нет программ обучения</div>
                                    ))}
                            </div>

                        </div>

                        {/*<hr className="Content__hr" />*/}

                        <div className="Content__box">
                            <div className="Box__label">Новая программа обучения</div>
                            <input className="Box__input"
                                   name="programName"
                                   type="text"
                                   defaultValue={programName}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{filesDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProgramData);
