import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class UserCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userFio: null,
            userEmail: null,
            userRole: null,
            userPassword: null,
            userCreateResponseError: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.userFio && this.state.userEmail && this.state.userRole > 0 && this.state.userPassword) {
            // const emailRegular = /^[\w\d%$:.-]+@\w+\.\w{2,5}$/;
            // const emailRegularTest = emailRegular.test(this.state.userEmail);
            //
            // if (!emailRegularTest) {
            //     this.setState({userCreateResponseError: 'Некорректный адрес эл. почты.'});
            // }
            //
            // else {
            let formData = new FormData();
            formData.append('user_fio', this.state.userFio);
            formData.append('user_email', this.state.userEmail);
            formData.append('user_typ', this.state.userRole);
            formData.append('user_password', this.state.userPassword);

            this.props.apiRequest('/user', formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        userCreateResponseError: this.props.responseError
                    })
                }
            );
            // }
        }

        else {
            this.setState({
                userCreateResponseError: 'Пожалуйста, внимательно заполните все поля.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { isRequest, interimData } = this.props;
        const { userFio, userEmail, userRole, userPassword, userCreateResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Новый пользователь</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">ФИО</div>
                            <input className="Box__input"
                                   name="userFio"
                                   type="text"
                                   defaultValue={userFio}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)}
                                   autoFocus />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Адрес эл. почты</div>
                            <input className="Box__input"
                                   name="userEmail"
                                   type="email"
                                   defaultValue={userEmail}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Уровень доступа</div>
                            <select className="Box__select" name="userRole" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={userRole}>
                                <option value='0'>Выберите уровень доступа</option>
                                {interimData.map(option => (
                                    (option.r_child_id > 0 ? <option value={option.r_child_id} key={option.r_child_id}>{option.catalog_name_child}</option> : null)
                                ))}
                            </select>
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Пароль</div>
                            <input className="Box__input"
                                   name="userPassword"
                                   type="text"
                                   defaultValue={userPassword}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{userCreateResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
