import React from "react";
import {modalClose} from "../../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../../loading/Loading";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";

class ProjectObjectiveEnd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectProblemCreate: this.props.interimData.objective_end,
            projectProblemCreateResponseError: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let formData = new FormData();
        formData.append('objective_end', this.state.projectProblemCreate);

        // Какой айди брать objective_id или objective_name_id
        this.props.apiRequest('/objective/' + this.props.interimData.objective_id + '/end_edit', formData, () => {
                this.props.modalClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
            }, () => {
                this.setState({
                    projectProblemCreateResponseError: this.props.responseError
                })
            }
        );
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { isRequest } = this.props;
        const { projectProblemCreate, projectProblemCreateResponseError } = this.state;


        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Редактировать</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">Окончание цели</div>
                            <textarea className="Box__input"
                                      rows={5}
                                      name="projectProblemCreate"
                                      defaultValue={projectProblemCreate}
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)}
                                      autoFocus />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{projectProblemCreateResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectObjectiveEnd);
