import React from "react";
import {connect} from 'react-redux';
import {authLogin} from "../../store/actions/authAction";
import './Auth.scss';
import Loading from "../loading/Loading";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authEmail: '',
            authPassword: '',
            authError: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({authError: null});
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.authEmail || !this.state.authPassword) {
            this.setState({authError: 'Заполните адрес эл. почты и пароль'});
        }

        else {
            // const emailRegular = /^[\w\d%$:.-]+@\w+\.\w{2,5}$/;
            // const emailRegularTest = emailRegular.test(this.state.authEmail);
            //
            // if (!emailRegularTest) {
            //     this.setState({authError: 'Некорректный адрес эл. почты'});
            // }
            //
            // else {
                let formData = new FormData();
                formData.append('login', this.state.authEmail);
                formData.append('password', this.state.authPassword);

                this.props.authLogin(
                    formData,
                    () => {this.props.history.push('/cabinet/apps');},
                    () => {this.setState({authError: this.props.authError});}
                );
            // }
        }
    };

    render() {
        const { authRequest } = this.props;
        const { authError, authPassword } = this.state;

        return (
            <div className="Auth" style={({height: window.innerHeight + 'px'})}>
                <i/>
                <div className="Auth__card">
                    <div className="Auth__form">

                        <form onSubmit={this.handleSubmit.bind(this)}>

                            <div className="Form__header">
                                <div className="Header__logotype">
                                    <div className="Logotype_big"/>
                                </div>
                                <div className="Header__motto">Кабинет управления системой<br/>сбора и анализа данных</div>
                            </div>

                            <div className="Form__content">
                                <div className="Form__field">
                                    <div className="Field__label">Адрес эл. почты</div>
                                    <input className="Field__input"
                                           name="authEmail"
                                           type="email"
                                           autoComplete="off"
                                           placeholder="Укажите адрес эл. почты"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                </div>

                                <div className="Form__field">
                                    <div className="Field__label">Пароль</div>
                                    <input className={authPassword.length > 0 ? "Field__input Field__input_password" : "Field__input"}
                                           name="authPassword"
                                           type="password"
                                           autoComplete="off"
                                           placeholder="Введите пароль"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                    <div className="Field__error">
                                        {!authError ? null : <span className="Error__text">{authError}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="Form__footer">
                                <div className="Form__field">
                                    <button className={!authRequest ? 'Field__button' : 'Field__button Field__button_disabled'} type="submit" disabled={!authRequest ? null : 'disabled'}>Войти</button>
                                </div>
                            </div>

                        </form>

                    </div>

                    {/*<div className="Auth__card_shadow"/>*/}
                    <div className="Auth__loading">
                        {!authRequest ? null : <Loading/>}
                    </div>
                </div>
                <i/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authRequest: state.auth.authRequest,
        authError: state.auth.authError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authLogin: (formData, callbackSuccess, callbackError) => dispatch(authLogin(formData, callbackSuccess, callbackError))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
