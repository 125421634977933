import React from 'react';
import './Navigate.scss';
import {NavLink, Link} from 'react-router-dom';
import {connect} from 'react-redux';

class Navigate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserMenuOn: false,
            isDateSelect: false,
            isDateSelectStatistics: false,
            isDateSelectService: false
        };
    }

    handleClickUserMenu() {
        this.setState(state => ({
            isUserMenuOn: !state.isUserMenuOn
        }));
    }

    handleClickUserMenuFalse() {
        this.setState(state => ({
            isUserMenuOn: false
        }));
    }

    dateSelectTrue() {

        this.setState({
            isDateSelect: true
        });
    }

    dateSelectFalse() {

        this.setState({
            isDateSelect: false
        });
    }

    dateSelect() {

        if (this.state.isDateSelect) {
            this.setState({
                isDateSelect: false
            });
        }

        else {
            this.setState({
                isDateSelect: true
            });
        }
    }

    dateSelectStatisticsTrue() {

        this.setState({
            isDateSelectStatistics: true
        });
    }

    dateSelectStatisticsFalse() {

        this.setState({
            isDateSelectStatistics: false
        });
    }

    dateSelectStatistics() {

        if (this.state.isDateSelectStatistics) {
            this.setState({
                isDateSelectStatistics: false
            });
        }

        else {
            this.setState({
                isDateSelectStatistics: true
            });
        }
    }

    dateSelectServiceTrue() {

        this.setState({
            isDateSelectService: true
        });
    }

    dateSelectServiceFalse() {

        this.setState({
            isDateSelectService: false
        });
    }

    dateSelectService() {

        if (this.state.isDateSelectService) {
            this.setState({
                isDateSelectService: false
            });
        }

        else {
            this.setState({
                isDateSelectService: true
            });
        }
    }

    editorOpen() {
        this.props.editorOpen();
    }

    render() {

        const { levelAccess, profileFIO } = this.props;

        const { isDateSelect, isDateSelectStatistics, isDateSelectService } = this.state;

        let navLink;

        if (this.props.isAuthenticated) {

            let userNavigateModule = (levelAccess) => {
                try {

                    // let userType = JSON.parse(atob(token.split('.')[1])).data.user_type;

                    switch(levelAccess) {
                        case '0':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <div className="Navigate__link" onMouseEnter={this.dateSelectServiceTrue.bind(this)} onClick={this.dateSelectService.bind(this)}>Сервис</div>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    <NavLink to={"/cabinet/users"} className="Navigate__link">Пользователи</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '1':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <div className="Navigate__link" onMouseEnter={this.dateSelectServiceTrue.bind(this)} onClick={this.dateSelectService.bind(this)}>Сервис</div>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    <NavLink to={"/cabinet/users"} className="Navigate__link">Пользователи</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '2':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <div className="Navigate__link" onMouseEnter={this.dateSelectServiceTrue.bind(this)} onClick={this.dateSelectService.bind(this)}>Сервис</div>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    <NavLink to={"/cabinet/users"} className="Navigate__link">Пользователи</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '3':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '4':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '5':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '6':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left" onMouseEnter={this.dateSelectStatisticsTrue.bind(this)} onClick={this.dateSelectStatistics.bind(this)}>Статистика</NavLink>
                                    <NavLink to={"/cabinet/companies"} className="Navigate__link">Предприятия</NavLink>
                                    {/*<NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>*/}
                                </React.Fragment>
                            );
                        case '7':
                            return(
                                <React.Fragment>
                                    <NavLink to={"/cabinet/dashboard"} className="Navigate__link Navigate__link_left">Статистика</NavLink>
                                </React.Fragment>
                            );
                        default:
                            return null;
                    }
                }

                catch (error) {
                    console.log(error);
                    return null;
                }
            };

            const userMenu = (token) => {
                try {

                    let userFio = JSON.parse(atob(token.split('.')[1])).data.fio.split(' ');

                    if (userFio[1]) {
                        return (
                            <React.Fragment>
                                <div className="Navigate__link" onMouseEnter={this.dateSelectTrue.bind(this)} onClick={this.dateSelect.bind(this)}>{userFio[1]}&nbsp;{userFio[0]}</div>
                                {isDateSelect ? (
                                    <div className="Navigate__select" onMouseLeave={this.dateSelectFalse.bind(this)}>
                                        <div className="Navigate__user">
                                            {levelAccess < 7 ? (
                                                <React.Fragment>
                                                    <NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>
                                                    <hr className="Navigate__hr"/>
                                                </React.Fragment>
                                            ) : null}
                                            <NavLink to={"/cabinet/profile"} className="Navigate__link">Персональные данные</NavLink>
                                            <hr className="Navigate__hr"/>
                                            <NavLink to={"/cabinet/logout"} className="Navigate__link">Выйти</NavLink>
                                        </div>
                                    </div>) : null
                                }
                            </React.Fragment>
                        );
                    }

                    else {
                        return (
                            <React.Fragment>
                                {levelAccess < 7 ? (
                                    <NavLink to={"/cabinet/calendar"} className="Navigate__link">Календарь</NavLink>
                                ) : null}
                                <NavLink to={"/cabinet/profile"} className="Navigate__link">Персональные данные</NavLink>
                                <NavLink to={"/cabinet/logout"} className="Navigate__link">Выйти</NavLink>
                            </React.Fragment>
                        );
                    }
                }

                catch (error) {
                    return (<NavLink to={"/cabinet/logout"} className="Navigate__link">Выйти</NavLink>);
                }
            };

            navLink = (
                <React.Fragment>
                    {/*{userNavigateModule(localStorage.getItem('tokenSession'))}*/}
                    {userNavigateModule(levelAccess)}
                    {userMenu(localStorage.getItem('tokenSession'))}
                    {isDateSelectStatistics ? (
                        <div className="Navigate__select Navigate__select_statistics" onMouseLeave={this.dateSelectStatisticsFalse.bind(this)}>
                            <div className="Navigate__user">
                                <NavLink to={"/cabinet/dashboard/companies"} className="Navigate__link">Предприятия</NavLink>
                                <hr className="Navigate__hr"/>
                                <NavLink to={"/cabinet/dashboard/projects"} className="Navigate__link">Проекты</NavLink>
                                <hr className="Navigate__hr"/>
                                <NavLink to={"/cabinet/dashboard/samples"} className="Navigate__link">Потоки образцы</NavLink>
                                <hr className="Navigate__hr"/>
                                <NavLink to={"/cabinet/dashboard/productivity"} className="Navigate__link">Производительность</NavLink>
                            </div>
                        </div>) : null
                    }
                    {isDateSelectService ? (
                        <div className="Navigate__select Navigate__select_service" onMouseLeave={this.dateSelectServiceFalse.bind(this)}>
                            <div className="Navigate__user">
                                {/*<NavLink to={"/cabinet/documents"} className="Navigate__link">Документы</NavLink>*/}
                                {/*<hr className="Navigate__hr"/>*/}
                                <NavLink to={"/cabinet/mailing"} className="Navigate__link">Рассылка</NavLink>
                                <hr className="Navigate__hr"/>
                                <NavLink to={"/cabinet/history"} className="Navigate__link">История</NavLink>
                                <hr className="Navigate__hr"/>
                                <a className="Navigate__link" target='_blank' rel="noopener noreferrer" href={'http://ivboard.ru/public/svgedit/editor/svg-editor-es.html'}>Схема процесса</a>
                                {/*<NavLink to={"/cabinet/drawing"} className="Navigate__link" onClick={this.editorOpen.bind(this)}>Схема процесса</NavLink>*/}
                            </div>
                        </div>) : null
                    }
                </React.Fragment>
            );
        }

        else {
            navLink = (<NavLink to={"/login"} className="Navigate__link">Войти</NavLink>);
        }

        return (
            // <div className="Navigate">
            //     <div className="Navigate__bar">
            //         <div className="Navigate__logotype">
            //             <Link to={"/"}>
            //                 <div className="Logotype_small" />
            //             </Link>
            //         </div>
            //         <div className="Navigate__logotype_2">
            //             <a target='_blank' rel="noopener noreferrer" href={'https://xn--b1aedfedwqbdfbnzkf0oe.xn--p1ai/ru/'}>
            //                 <div className="Logotype_small_2" />
            //             </a>
            //         </div>
            //         <div className="Navigate__menu">
            //             {navLink}
            //         </div>
            //         {levelAccess < 3 ? (
            //             <div className="Navigate__search">
            //                 <NavLink to={"/cabinet/search"} className="Search"/>
            //             </div>
            //         ) : null}
            //     </div>
            // </div>

            <nav className="_after _before">
                <div className="baron baron__root baron__clipper _macosx">
                    <div className="baron__scroller">
                        <div className="container">

                            <ul>

                                <li className="link role"><a style={{cursor: 'default'}}>{profileFIO}</a><p>Администратор</p></li>

                                <li className="link">
                                    <NavLink to={"/cabinet/journal"}>Журнал</NavLink>
                                </li>
                                <li className="link">
                                    <NavLink to={"/cabinet/apps"}>Приложения</NavLink>
                                </li>
                                <li className="link">
                                    <NavLink to={"/cabinet/devices"}>Устройства</NavLink>
                                </li>
                                <li className="link exit">
                                    <NavLink to={"/cabinet/logout"}>Выйти</NavLink>
                                </li>

                            </ul>

                        </div>
                    </div>
                    <div className="baron__track">
                        <div className="baron__free">
                            <div className="baron__bar"></div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        levelAccess: state.cabinet.levelAccess,
        profileFIO: state.cabinet.profileFIO
    }
}

export default connect(mapStateToProps, null) (Navigate);
