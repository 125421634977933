import React from "react";
import {modalClose, modalOpen} from "../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../loading/Loading";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../store/actions/pageAction";

class ModalDeviceNameEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventName: null,
            eventInfo: null,
            eventTarget: null,
            eventCountUsers: null,
            eventDataResponseError: null,
            eventWorkers: '',
            eventWorker: null
        };
    }

    componentDidMount() {
        this.setState({
            eventName: (this.props.modalData.objectData.name ? this.props.modalData.objectData.name : ''),
            eventInfo: (this.props.modalData.objectData.info ? this.props.modalData.objectData.info : ''),
            eventProvider: (this.props.modalData.objectData.provider ? this.props.modalData.objectData.provider : ''),
            eventPhone: (this.props.modalData.objectData.phone ? this.props.modalData.objectData.phone : ''),
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        // if (this.state.eventName) {
            let formData = new FormData();

        formData.append('device_name', this.state.eventName);
        formData.append('device_info', this.state.eventInfo);
        formData.append('device_provider', this.state.eventProvider);
        formData.append('device_phone', this.state.eventPhone);
            // formData.append('events_info', this.state.eventInfo);
            // formData.append('events_target', this.state.eventTarget);
            // formData.append('events_count_user', this.state.eventCountUsers);
            // formData.append('events_workers', this.state.eventWorkers);

            this.props.apiRequest('api/cabinet/devices/edit/device/' + this.props.modalData.objectId, formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        eventDataResponseError: this.props.responseError
                    })
                }
            );
        // }

        // else {
        //     this.setState({
        //         eventDataResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
        //     });
        // }
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    modalClose() {
        this.props.modalClose();
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    eventAddWorker() {
        if (this.state.eventWorker && this.state.eventWorker !== '') {
            if (this.state.eventWorkers !== '') {
                this.setState({
                    eventWorkers: this.state.eventWorkers + ';' + this.state.eventWorker
                });
            }

            else {
                this.setState({
                    eventWorkers: this.state.eventWorker
                });
            }
        }

        this.setStateAndInput('eventWorker', null);
    }

    eventDelWorker(index) {
        let eventWorkersArray = this.state.eventWorkers.split(';');
        eventWorkersArray.splice(index, 1);
        this.setState({
            eventWorkers: eventWorkersArray.join(';')
        });
    }

    render() {
        const { modalData, isRequest } = this.props;
        const { eventDataResponseError, eventWorkers } = this.state;

        // let eventWorkersArray = [];
        //
        // if (eventWorkers) {
        //     eventWorkersArray = eventWorkers.split(';');
        // }

        let eventWorkersArray = eventWorkers.split(';');

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Редактировать</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">Название устройства</div>
                            <input className="Box__input"
                                   name="eventName"
                                   type="text"
                                   defaultValue={modalData.objectData.name}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Описание устройства</div>
                            <input className="Box__input"
                                   name="eventInfo"
                                   type="text"
                                   defaultValue={modalData.objectData.info}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Провайдер модема</div>
                            <input className="Box__input"
                                   name="eventProvider"
                                   type="text"
                                   defaultValue={modalData.objectData.provider}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Телефон модема</div>
                            <input className="Box__input"
                                   name="eventPhone"
                                   type="text"
                                   defaultValue={modalData.objectData.phone}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        {/*<hr className="Content__hr" />*/}

                        <div className="Content__error">
                            <span className="Error__text">{eventDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeviceNameEdit);
