import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class UserRole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: null,
            usersRight: null,
            userRoleResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            userRole: this.props.interimData.user_typ
        });
        this.usersRight();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let formData = new FormData();
        formData.append('user_type', this.state.userRole);

        this.props.apiRequest('/user/' + this.props.interimData.user_id + '/type', formData, () => {
                this.props.modalClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
            }, () => {
                this.setState({
                    userRoleResponseError: this.props.responseError
                })
            }
        );
    }

    modalClose() {
        this.props.modalClose();
    }

    usersRight() {
        this.props.apiRequest('/users/right', null, () => {
            this.setState({
                usersRight: this.props.responseData
            });
            console.log(this.props.responseData);
        }, null);
    }

    render() {
        const { interimData, isRequest } = this.props;
        const { usersRight, userRole, userRoleResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Уровень доступа</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Уровень доступа</div>
                            {!usersRight ? (
                                <select className="Box__select" name="userRole" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={interimData.user_typ}>
                                    <option value={interimData.user_typ}>{interimData.catalog_name}</option>
                                </select>) : (
                                <select className="Box__select" name="userRole" value={userRole} onChange={this.handleInputChange.bind(this)} defaultValue={userRole}>
                                    {usersRight.map(option => (
                                        (option.r_child_id > 0 ? <option value={option.r_child_id} key={option.r_child_id}>{option.catalog_name_child}</option> : null)
                                    ))}
                                </select>
                            )}
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{userRoleResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Изменить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);
