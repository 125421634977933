import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class EventCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventName: null,
            eventInfo: null,
            eventTarget: null,
            eventDateStart: null,
            eventDateEnd: null,
            eventCountUsers: null,
            eventCreateResponseError: null,
            eventWorkers: '',
            eventWorker: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        if (this.state.eventName && this.state.eventDateStart && this.state.eventDateEnd) {

            if (dateRegular.test(this.state.eventDateStart) && dateRegular.test(this.state.eventDateEnd)) {

                const eventDateStart = new Date(this.state.eventDateStart).getTime() / 1000;
                const eventDateEnd = new Date(this.state.eventDateEnd).getTime() / 1000;

                if (eventDateStart && eventDateEnd) {
                    let formData = new FormData();

                    formData.append('events_name', this.state.eventName);
                    // formData.append('events_info', this.state.eventInfo);
                    formData.append('events_target', this.state.eventTarget);
                    formData.append('events_date_start', String(eventDateStart));
                    formData.append('events_date_end', String(eventDateEnd));
                    // formData.append('events_count_user', this.state.eventCountUsers);
                    formData.append('events_workers', this.state.eventWorkers);

                    this.props.apiRequest('/project/' + this.props.interimData.project_id + '/event', formData, () => {
                            this.props.modalClose();
                            this.props.notificationMessage(this.props.responseMessage, 'green');
                            this.props.pageUpdate();
                        }, () => {
                            this.setState({
                                eventCreateResponseError: this.props.responseError
                            })
                        }
                    );
                }

                else {
                    this.setState({
                        eventCreateResponseError: 'Пожалуйста, кажите корректно месяц и день даты начала и завершения мероприятия.'
                    });
                }
            }

            else {
                this.setState({
                    eventCreateResponseError: 'Пожалуйста, заполните в правильном формате дату начала и завершения мероприятия (2019-12-31).'
                });
            }
        }

        else {
            this.setState({
                eventCreateResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
            });
        }
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    eventAddWorker() {
        if (this.state.eventWorker && this.state.eventWorker !== '') {
            if (this.state.eventWorkers !== '') {
                this.setState({
                    eventWorkers: this.state.eventWorkers + ';' + this.state.eventWorker
                });
            }

            else {
                this.setState({
                    eventWorkers: this.state.eventWorker
                });
            }
        }

        this.setStateAndInput('eventWorker', null);
    }

    eventDelWorker(index) {
        let eventWorkersArray = this.state.eventWorkers.split(';');
        eventWorkersArray.splice(index, 1);
        this.setState({
            eventWorkers: eventWorkersArray.join(';')
        });
    }

    modalClose() {
        this.props.modalClose();
    }

    render() {
        const { isRequest } = this.props;
        const { eventName, eventInfo, eventWorker, eventTarget, eventCountUsers, eventDateStart, eventDateEnd, eventCreateResponseError, eventWorkers } = this.state;

        let eventWorkersArray = eventWorkers.split(';');

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Добавить мероприятие</div>
                    </div>
                    <div className="Form__content">

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Название мероприятия *</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           name="eventName"*/}
                        {/*           type="text"*/}
                        {/*           defaultValue={eventName}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)}*/}
                        {/*           autoFocus />*/}
                        {/*</div>*/}

                        <div className="Content__box">
                            <div className="Box__label">Название мероприятия *</div>
                            <textarea className="Box__input"
                                      rows={4}
                                      name="eventName"
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)}>{eventName}</textarea>
                        </div>

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Описание мероприятия</div>*/}
                        {/*    <textarea className="Box__input"*/}
                        {/*              rows={5}*/}
                        {/*              name="eventInfo"*/}
                        {/*              value={this.state.value}*/}
                        {/*              onChange={this.handleInputChange.bind(this)}>{eventInfo}</textarea>*/}
                        {/*</div>*/}

                        <div className="Content__box">
                            <div className="Box__label">Цель мероприятия</div>
                            <textarea className="Box__input"
                                      rows={4}
                                      name="eventTarget"
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)}>{eventTarget}</textarea>
                        </div>

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Количество участников</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           name="eventCountUsers"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={eventCountUsers}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">ФИО участника мероприятия<div className="Box__label_link" onClick={this.eventAddWorker.bind(this)}>Добавить</div></div>
                            <input className="Box__input"
                                   name="eventWorker"
                                   id="eventWorker"
                                   type="text"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            {eventWorkers.length > 0 ? (
                                eventWorkersArray.map((worker, index) => (
                                    <div className="Box__candy" key={index}>
                                        {worker}
                                        <div className="Label__clear icon-clear"
                                             onClick={this.eventDelWorker.bind(this, index)}>+</div>
                                    </div>
                                ))
                            ) : null}
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Дата начала мероприятия *</div>
                            <input className="Box__input"
                                   name="eventDateStart"
                                   type="date"
                                   defaultValue={eventDateStart}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Дата завершения мероприятия *</div>
                            <input className="Box__input"
                                   name="eventDateEnd"
                                   type="date"
                                   defaultValue={eventDateEnd}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{eventCreateResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCreate);
