import React from "react";
import {connect} from "react-redux";
import {alertClose} from "../../../store/actions/alertAction";
import "./Alert.scss";
import "../modal/form/Form.scss";
import AlertCompanyDelete from "./form/company/alertCompanyDelete";
import AlertCompanySupportDelete from "./form/company/alertCompanySupportDelete";

class Alert extends React.Component {

    alertClose() {
        this.props.alertClose();
    }

    switchForm(alertForm) {
        switch(alertForm) {
            case 'alertCompanyDelete': return (<AlertCompanyDelete />);
            case 'alertCompanySupportDelete': return (<AlertCompanySupportDelete />);

            default: return (<div className="Form Form_null" />);
        }
    }

    render() {
        const { isAlert, alertForm } = this.props;

        let switchForm = this.switchForm(alertForm);

        if (!isAlert) { return null; }

        else {
            return (
                <div className="Alert">
                    <i/>
                    <div className="Alert__dialog">
                        <div className="Alert__content">
                            <div className="Alert__body">
                                {switchForm}
                            </div>
                            <div className="Alert__close">
                                <div className="Button__close icon-close" onClick={this.alertClose.bind(this, false, null)} />
                            </div>
                        </div>
                    </div>
                    <i/>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isAlert: state.alert.isAlert,
        alertForm: state.alert.alertForm,
        alertData: state.alert.alertData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        alertClose: () => dispatch(alertClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
