import React from "react";
import {modalClose, modalOpen} from "../../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../../loading/Loading";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";

class CompanyProgramCountEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            programName: this.props.interimData.filesData,
            objectId: this.props.interimData.objectId,
            programId: this.props.interimData.objectType,
            countDate: this.inputDateMonthYear(new Date().getTime() / 1000),
            countNumber: null,
            programsData: null,
            eventDateStart: null,
            isProgramsData: false,
            filesDataResponseError: null
        };
    }

    componentDidMount() {
        this.formProgramsData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        // const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        // if (this.state.eventDateStart) {
        //
        //     if (dateRegular.test(this.state.eventDateStart)) {
        //
        //         const eventDateStart = new Date(this.state.eventDateStart).getTime() / 1000;
        //
        //         if (eventDateStart) {



        const eventDateStart = new Date(this.state.countDate).getTime() / 1000;


        if (this.state.countNumber) {
            let formData = new FormData();


            formData.append('program_date', String(eventDateStart));
            formData.append('program_user', this.state.countNumber);
            formData.append('program_id', this.state.programId);

            this.props.apiRequest('/program/' + this.state.objectId + '/user_program', formData, () => {
                    this.setState({
                        filesDataResponseError: null
                    });
                    this.formProgramsData();
                        this.props.pageUpdate();
                    }, () => {
                    this.setState({
                        filesDataResponseError: this.props.responseError
                    })
                }
            );

        }

        else {
            this.setState({
                filesDataResponseError: 'Пожалуйста, укажите количество обучившихся.'
            });
        }




        //
        //         }
        //
        //         else {
        //             this.setState({
        //                 eventPeriodResponseError: 'Пожалуйста, кажите корректно месяц и день даты начала и завершения мероприятия.'
        //             });
        //         }
        //     }
        //
        //     else {
        //         this.setState({
        //             eventPeriodResponseError: 'Пожалуйста, заполните в правильном формате дату начала и завершения мероприятия (2019-12-31).'
        //         });
        //     }
        // }
        //
        // else {
        //     this.setState({
        //         eventPeriodResponseError: 'Пожалуйста, укажите дату начала и завершения мероприятия.'
        //     });
        // }




    }

    modalClose() {
        this.props.modalClose();
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    formProgramsData() {
        this.setState({
            isProgramsData: true
        });

        this.props.apiRequest('/program/' + this.state.objectId + '/user_programs', null, () => {
            this.setState({
                programsData: this.props.responseData,
                isProgramsData: false
            });
        }, null);
    }

    inputDateMonthYear(date) {

        const dateMonthYear = new Date(date * 1000);

        let dd = dateMonthYear.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = dateMonthYear.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        let yyyy = dateMonthYear.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;

        return yyyy + '-' + mm + '-' + dd;
    }

    render() {
        const { isRequest } = this.props;
        const { filesDataResponseError, programName, countDate, isProgramsData, programsData, countNumber } = this.state;

        let interimData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        let dateMonthYear = (date) => {

            const dateMonthYear = new Date(date * 1000);

            let dd = dateMonthYear.getDate();
            if (dd < 10) dd = '0' + dd;

            let mm = dateMonthYear.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;

            let yyyy = dateMonthYear.getFullYear();
            if (yyyy < 10) yyyy = '0' + yyyy;

            return dd + '.' + mm + '.' + yyyy;
        }

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Программа обучения</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__text">{programName}</div>
                        </div>




                        <div className="Content__box">


                            <div className="Box__counts">
                                {isProgramsData ? (<Loading />) : (
                                    programsData ? (
                                        programsData.length > 0 ? (
                                            <table className="Counts__table">
                                                <thead>
                                                <tr className="Table__tr">
                                                    <th className="Table__th">
                                                        <div className="Th__title">Дата обучения</div>
                                                        <div className="Th__count">Обучившихся</div>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {programsData.map(program => (
                                                    <tr className="Table__tr" key={program.program_user_id}>
                                                        <td className="Table__td">
                                                            <div className="Link">
                                                                <div className="Program__name">{dateMonthYear(program.program_user_date)}</div>
                                                                <div className="Program__count">{program.program_user_count}</div>
                                                                <div className="Program__delete" onClick={this.modalOpen.bind(this, 'formCompanyProgramCountDelete', interimData(program.program_user_id, null, null))}>Удалить</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        ):(
                                            <div className='Box__text Box__text_disabled'>Пока нет записи об обучившихся</div>
                                        )) : (
                                        <div className='Box__text Box__text_disabled'>Пока нет записи об обучившихся</div>
                                    ))}
                            </div>

                        </div>







                        <hr className="Content__hr" />






                        <div className="Content__box">
                            <div className="Box__label">Количество обучившихся</div>
                            <input className="Box__input"
                                   name="countNumber"
                                   type="number"
                                   defaultValue={countNumber}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Дата обучения</div>
                            <input className="Box__input"
                                   name="countDate"
                                   type="date"
                                   defaultValue={countDate}
                                   // defaultValue={this.inputDateMonthYear(new Date().getTime() / 1000)}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{filesDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProgramCountEdit);
