import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";
import {determineLevelAccess} from "../store/actions/cabinetAction";
import "./Page.scss";
import "./module.css";
import "./modal.css";
import Logout from "./auth/Logout";
import Navigate from "./cabinet/navigate/Navigate";
// import Profile from "./cabinet/profile/Profile";
import Apps from "./cabinet/apps/Apps";
import Devices from "./cabinet/devices/Devices";
import Notification from "./notification/Notification";
import Modal from "./cabinet/modal/Modal";
import Alert from "./cabinet/alert/Alert";
import Journal from "./cabinet/journal/Journal";

class Cabinet extends React.Component {

    componentDidMount() {
        this.props.determineLevelAccess();
    }

    render() {
        const { thereLevelAccess, levelAccess } = this.props;

        if (!thereLevelAccess) {
            return null;
        }

        else {

            if (levelAccess < 1) {
                return (
                    <div className="Cabinet">
                        <Navigate/>
                        <div className="Page">
                            <Switch>
                                <Route exact path="/cabinet/logout" component={Logout}/>
                                {/*<Route exact path="/cabinet/profile" component={Profile}/>*/}
                                <Route exact path="/cabinet/apps" component={Apps}/>
                                <Route exact path="/cabinet/devices" component={Devices}/>
                                <Route exact path="/cabinet/journal" component={Journal}/>

                                <Route path="/">
                                    <Redirect push to="/cabinet/apps"/>
                                </Route>
                            </Switch>
                        </div>
                        <Notification/>
                        <Modal/>
                        <Alert/>
                    </div>
                );
            }

            else return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        thereLevelAccess: state.cabinet.thereLevelAccess,
        levelAccess: state.cabinet.levelAccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        determineLevelAccess: () => dispatch(determineLevelAccess())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cabinet);
