import React from "react";
import {connect} from "react-redux";
// import {modalOpen} from "../../../store/actions/modalAction";
import {apiRequest} from "../../../store/actions/apiAction";
import {pageLoad, pageMount, pageUpdate} from "../../../store/actions/pageAction";
import {notificationMessage} from "../../../store/actions/notificationAction";
import "./Apps.scss";
import Loading from "../../loading/Loading";
import getDateMonthYear from "../../../library/getDateMonthYear";
import fileSizeView from "../../../library/fileSizeView";

class Apps extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            appsData: null,
            selectApp: null,
            selectVersion: null,
            addAppVersion: null,
            editApp: null,
            editVersion: null,
            appName: null
        };

        this.appInput = React.createRef();
    }

    componentDidMount() {
        this.props.notificationMessage('Кабинет авторизирован', 'black');
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
    }

    componentWillUnmount() {
        // console.log('Apps Unmounted');
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.appsData) {
                    // console.log(3 + ' responseData');
                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        appsData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdate: this.props.isUpdate
                });
                this.pageUpdate(this.state.isLoad);
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/apps', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    // modalOpen(modalForm, interimData) {
    //     this.props.modalOpen(modalForm, interimData);
    // }

    // electronicNotification() {
    //     if (this.state.electronicNotification) {
    //         this.setState({
    //             electronicNotification: false
    //         });
    //         this.props.apiRequest('/profile/mail_notcheck', null, () => {
    //             this.props.notificationMessage('Системное оповещение отключено!', 'red');
    //         }, () => {
    //             this.setState({
    //                 electronicNotification: true
    //             });
    //         });
    //     }
    //
    //     else {
    //         this.setState({
    //             electronicNotification: true
    //         });
    //         this.props.apiRequest('/profile/mail_check', null, () => {
    //             this.props.notificationMessage('Системное оповещение включено', 'green');
    //         }, () => {
    //             this.setState({
    //                 electronicNotification: false
    //             });
    //         });
    //     }
    // }

        speckText() {

        // // Создаем распознаватель
        // // var recognizer = new webkitSpeechRecognition();
        // var recognizer = new SpeechRecognition();
        //
        // // Ставим опцию, чтобы распознавание началось ещё до того, как пользователь закончит говорить
        //         recognizer.interimResults = true;
        //
        // // Какой язык будем распознавать?
        //         recognizer.lang = 'ru-Ru';
        //
        // // Используем колбек для обработки результатов
        //         recognizer.onresult = function (event) {
        //             var result = event.results[event.resultIndex];
        //             if (result.isFinal) {
        //                 alert('Вы сказали: ' + result[0].transcript);
        //             } else {
        //                 console.log('Промежуточный результат: ', result[0].transcript);
        //             }
        //         };
        //
        // // Начинаем слушать микрофон и распознавать голос
        //         recognizer.start();

        // speechSynthesis.speak(
        //     new SpeechSynthesisUtterance('Сап, Двач. Я ламповый V8 JavaScript Engine')
        // );

        // let voices = speechSynthesis.getVoices();
        // let utterance = new SpeechSynthesisUtterance('Кожанные Россияне');
        // utterance.voice = voices[1];
        // speechSynthesis.speak(utterance);
    }

    handleAppChange() {
        let app = this.appInput.current.files.item(0);
        this.uploadApp(app);
    }

    uploadApp(app) {
        let formData = new FormData();

        formData.append('app_file', app);

        this.props.apiRequest('api/cabinet/apps/upload', formData, () => {
                this.setState({
                    addAppVersion: this.props.responseData
                });
                this.props.pageUpdate();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    selectAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            selectApp: app,
            selectVersion: version
        });
    }

    closeAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            selectApp: null,
            selectVersion: null,
            addAppVersion: null
        });
    }

    editAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            editApp: app,
            editVersion: version,
            appName: app.app_name
        });
    }

    closeEditAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            editApp: null,
            editVersion: null,
            appName: null
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    archiveAppVersion(app, version) {
        document.body.classList.add('overflow_hidden');
        this.setState({
            archiveApp: app,
            archiveVersion: version
        }, () => {
            this.closeEditAppVersion();
        });
    }

    closeArchiveAppVersion() {
        document.body.classList.remove('overflow_hidden');
        this.setState({
            archiveApp: null,
            archiveVersion: null
        });
    }

    requestEditApp(app, name) {
        let formData = new FormData();

        formData.append('app_id', app.id);
        formData.append('app_name', name);

        this.props.apiRequest('api/cabinet/apps/edit/app', formData, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeEditAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    requestArchiveApp(app) {

        this.props.apiRequest('api/cabinet/apps/archive/app/' + app.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeArchiveAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    requestArchiveVersion(version) {

        this.props.apiRequest('api/cabinet/apps/archive/version/' + version.id, null, () => {
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.closeArchiveAppVersion();
            }, () => {
                this.props.notificationMessage(this.props.responseError, 'red');
            }
        );
    }

    render() {
        const { isRequest } = this.props;
        const { isMount, isOpen, appsData, selectApp, selectVersion, addAppVersion, editApp, editVersion, appName, archiveApp, archiveVersion } = this.state;

        if (isMount) {

            if (!isOpen || !appsData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <React.Fragment>
                        <section>
                            <div className="container">

                                <div className="module__wrapper">
                                    <div className="module__header">
                                        <div className="header__title">Приложения</div>
                                        <label>
                                            <div className='button header__button button_blue'>
                                                Добавить приложение
                                            </div>
                                            <div style={{display: 'inline-block', float: 'right', padding: '17px 24px'}}>
                                                {isRequest ? <Loading /> : null}
                                            </div>
                                            <input type="file"
                                                   accept=".apk"
                                                   ref={this.appInput}
                                                   onChange={this.handleAppChange.bind(this)}
                                                   hidden />
                                        </label>
                                    </div>

                                    {appsData.map((app, index) => (
                                        app.versions.length > 0 ? (
                                            <React.Fragment key={index}>
                                                <div className="module__field_header">
                                                    <div className="field_header__title">{app.app_name}</div>
                                                    <a className="header__link">{app.app_signature}</a>
                                                    <button id="id"
                                                            className="button field_header__button button_gray"
                                                            type="submit" name="open_edit_app"
                                                            onClick={this.editAppVersion.bind(this, app, null)}>Редактировать</button>
                                                </div>

                                                {app.versions.map((version, index) => (
                                                    <div className="module__field_inline" key={index}>
                                                        <div className="field_inline__box">
                                                            <div className="field_inline__label">Версия</div>
                                                            <div className="field_inline__text">
                                                                {version.version_name + ' ' + version.version_number + ' (' + version.version_code + ')'}
                                                            </div>
                                                        </div>
                                                        <div className="field_inline__hr">
                                                            <div className="hr__line" />
                                                        </div>
                                                        <div className="field_inline__box">
                                                            <div className="field_inline__label">Обновление</div>
                                                            <div
                                                                className={Number(version.access_update) > 0 ? "field_inline__text text_green" : "field_inline__text text_red"}>
                                                                {Number(version.access_update) > 0 ? "Доступно" : "Не доступно"}
                                                            </div>
                                                        </div>

                                                        {Number(version.access_update) > 0 ? (
                                                            <div className="field_inline__box">
                                                                <div className="field_inline__label">От</div>
                                                                <div className="field_inline__text">
                                                                    {getDateMonthYear(version.release_date)}
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        <div className="field_inline__box_right">
                                                            <div className="field_inline__hr">
                                                                <div className="hr__line" />
                                                            </div>
                                                            <div className="button field_inline__button"
                                                                 onClick={this.selectAppVersion.bind(this, app, version)}>Подробнее</div>
                                                        </div>
                                                    </div>
                                                ))}

                                                <br/><br/><br/><br/>
                                            </React.Fragment>
                                        ) : null
                                    ))}
                                </div>
                            </div>
                        </section>

                        {selectApp && selectVersion ? (
                            <div className="module__modal modal_open_version">
                                <div className="modal__overlay">
                                    <div className="modal__page" onMouseLeave={this.closeAppVersion.bind(this)}>
                                        <div className="modal__content">
                                            <section>
                                                <div className="container">
                                                    <div className="content">

                                                        <div className="modal__title title_firm">
                                                            {selectVersion.version_name + ' ' + selectVersion.version_number}
                                                        </div>
                                                        <div className="modal__role">
                                                            {selectApp.app_signature ? selectApp.app_signature : 'Версия приложения'}
                                                        </div>
                                                        <div className="information__title">Данные версии</div>
                                                        <div className="information__content">
                                                            <div className="information__label">Код версии</div>
                                                            <div className="information__text">{selectVersion.version_code}</div>
                                                        </div>
                                                        <div className="page__hr"/>
                                                        <div className="information__content">
                                                            <div className="information__label">Рекомендуемая версия SDK</div>
                                                            <div className="information__text">{selectVersion.required_version_sdk}</div>
                                                        </div>
                                                        <div className="page__hr"/>
                                                        <div className="information__content">
                                                            <div className="information__label">Минимальная версия SDK</div>
                                                            <div className="information__text">{selectVersion.min_version_sdk}</div>
                                                        </div>
                                                        <div className="page__hr"/>
                                                        <div className="information__content">
                                                            <div className="information__label">Размер приложения</div>
                                                            <div className="information__text">{fileSizeView(selectVersion.file_size)}</div>
                                                        </div>
                                                        <div className="page__hr"/>
                                                        <div className="information__content">
                                                            <div className="information__label">Хэш сумма в MD5</div>
                                                            <div className="information__text">{selectVersion.file_hash_sum}</div>
                                                        </div>

                                                        <br/><br/><br/>
                                                        <div className="information__content">
                                                            <div className="information__button">
                                                                <button id="" className="button button_gray"
                                                                        type="submit"
                                                                        name="open_edit_version"
                                                                        onClick={this.editAppVersion.bind(this, selectApp, selectVersion)}>Редактировать</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {addAppVersion ? (
                            <div className="module__modal modal_add_app modal_add_version">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">
                                            <div className="modal__title">{addAppVersion.add === 'app' ? 'Новое приложение' : 'Новая версия'}</div>
                                            <div className="modal__form">

                                                <div className="information__content">
                                                    <div className="information__label">Название</div>
                                                    <div className="information__text">{addAppVersion.app_name}</div>
                                                </div>
                                                <div className="information__content">
                                                    <div className="information__label">Подпись</div>
                                                    <div className="information__text">{addAppVersion.app_signature}</div>
                                                </div>
                                                <div className="information__content">
                                                    <div className="information__label">Номер версии</div>
                                                    <div className="information__text">{addAppVersion.version_number}</div>
                                                </div>
                                                <div className="information__content">
                                                    <div className="information__label">Код версии</div>
                                                    <div className="information__text">{addAppVersion.version_code}</div>
                                                </div>
                                                <br/>

                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button className="button field__button button__gray button_left"
                                                                type="submit" name="close_add_app" tabIndex="1"
                                                                autoFocus="true" onClick={this.closeAppVersion.bind(this)}>
                                                            Закрыть
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {editApp && !editVersion ? (
                            <div className="module__modal modal_edit_app">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">
                                            <div className="modal__title">{editApp.app_name}</div>
                                            <div className="modal__role">{editApp.app_signature ? editApp.app_signature : 'Приложение'}</div>
                                            <div className="modal__form">
                                                <div className="form__title">Редактировать данные</div>
                                                <div className="form__field">
                                                    <div className="field__title">Название</div>
                                                    <div>

                                                        <input className="input field__input"
                                                               name="appName"
                                                               type="text"
                                                               defaultValue={editApp.app_name}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}
                                                               autoFocus />
                                                    </div>
                                                    <div className="field__error">
                                                        <span className="error__text"></span>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="form__field">
                                                    <button id="" className="button field__button button_red button_left"
                                                            type="submit" name="put_in_archive_app"
                                                            onClick={this.archiveAppVersion.bind(this, editApp, null)}>Удалить приложение</button>
                                                </div>
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button id="" className="button field__button button_left"
                                                                type="submit" name="apply_edit_app" tabIndex="3"
                                                                onClick={this.requestEditApp.bind(this, editApp, appName)}>Сохранить</button>
                                                        <button
                                                            className="button field__button button_white_blue button__border_none link"
                                                            type="submit" name="close_edit_app" tabIndex="5"
                                                            onClick={this.closeEditAppVersion.bind(this)}>Отмена</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {editApp && editVersion ? (
                            <div className="module__modal modal_edit_version">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content">

                                            <div className="modal__title">{editVersion.version_name + ' ' + editVersion.version_number}</div>
                                            <div className="modal__role">{editApp.app_signature ? editApp.app_signature : 'Версия приложения'}</div>
                                            <div className="modal__form">
                                                {/*<div className="form__title">Доступ к обновлению*/}
                                                {/*    <div style={{font: '400 10px Roboto', opacity: 0.47}}>- Временно не*/}
                                                {/*        доступно на уровне API *</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="form__field">*/}
                                                {/*    <label className="switch"*/}
                                                {/*           name="version_access_update">*/}
                                                {/*        <span className="switch__lever"></span>*/}
                                                {/*        <span className="switch__index"></span>*/}
                                                {/*    </label>*/}
                                                {/*</div>*/}
                                                {/*<div className="form__field">*/}
                                                {/*    <div className="field__title">Дата автоматического обновления</div>*/}
                                                {/*    <div>*/}
                                                {/*        <input className="input field__input _name" type="text"*/}
                                                {/*               placeholder="00.00.0000" name="version_release_date"*/}
                                                {/*               tabIndex="1" />*/}
                                                {/*    </div>*/}
                                                {/*    <div className="field__error">*/}
                                                {/*        <span className="error__text"></span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<br/>*/}
                                                {/*<div className="form__field">*/}
                                                {/*    <button id="" className="button field__button button_red button_left"*/}
                                                {/*            type="submit" name="put_in_archive_version"*/}
                                                {/*            onClick={this.archiveAppVersion.bind(this, editApp, editVersion)}>Удалить версию</button>*/}
                                                {/*</div>*/}
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        {/*<button id="" className="button field__button button_left"*/}
                                                        {/*        type="submit" name="apply_edit_version"*/}
                                                        {/*        tabIndex="2"*/}
                                                        {/*        onClick={this.closeEditAppVersion.bind(this)}>Сохранить</button>*/}
                                                        <button id="" className="button field__button button_red button_left"
                                                                type="submit" name="put_in_archive_version"
                                                                onClick={this.archiveAppVersion.bind(this, editApp, editVersion)}>Удалить версию</button>
                                                        <button
                                                            className="button field__button button_white_gray button__border_none link"
                                                            type="submit" name="close_edit_version" tabIndex="3"
                                                            onClick={this.closeEditAppVersion.bind(this)}>Отмена</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}


                        {archiveApp && !archiveVersion ? (
                            <div className="module__modal modal_put_in_archive_app">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content content__widthone">
                                            <div className="modal__title">{archiveApp.app_name}</div>
                                            <div className="modal__role">{archiveApp.app_signature ? archiveApp.app_signature : 'Приложение'}</div>
                                            <div className="modal__form">
                                                <div className="form__info">Внимание! <br/>Прекращение действий обновления
                                                    приложения и его версий, данные будут сохранены в архиве.
                                                </div>
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button id=""
                                                                className="button field__button button_red button_left"
                                                                type="submit" name="save_app_in_archive"
                                                                tabIndex="1"
                                                                onClick={this.requestArchiveApp.bind(this, archiveApp)}>Продолжить
                                                        </button>
                                                        <button
                                                            className="button field__button button_white_gray button__border_none link"
                                                            type="submit" name="close_put_in_archive_app" tabIndex="2"
                                                            autoFocus="true"
                                                            onClick={this.closeArchiveAppVersion.bind(this)}>Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {archiveApp && archiveVersion ? (
                            <div className="module__modal modal_put_in_archive_version">
                                <div className="modal__overlay">
                                    <div className="modal__window">
                                        <div className="modal__content content__widthone">
                                            <div className="modal__title">{archiveVersion.version_name + ' ' + archiveVersion.version_number}</div>
                                            <div className="modal__role">{archiveApp.app_signature ? archiveApp.app_signature : 'Версия приложения'}</div>
                                            <div className="modal__form">
                                                <div className="form__info">Внимание! <br/>Прекращение действий обновления
                                                    версии приложения, данные будут сохранены в архиве.
                                                </div>
                                                <div className="form__footer">
                                                    <div className="form__field">
                                                        <button id=""
                                                                className="button field__button button_red button_left"
                                                                type="submit" name="save_version_in_archive"
                                                                tabIndex="1"
                                                                onClick={this.requestArchiveVersion.bind(this, archiveVersion)}>Продолжить
                                                        </button>
                                                        <button
                                                            className="button field__button button_white_gray button__border_none link"
                                                            type="submit" name="close_put_in_archive_version" tabIndex="2"
                                                            autoFocus="true"
                                                            onClick={this.closeArchiveAppVersion.bind(this)}>Отмена
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    </React.Fragment>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        isRequest: state.api.isRequest,
        responseData: state.api.responseData,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        // modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Apps);
