import React from "react";
import '../Form.scss';
import {modalClose, modalOpen} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class FilesEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objectId: null,
            objectType: null,
            filesData: null,
            isFilesData: false,
            fileUpload: null,
            filesDataResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            filesData: this.props.interimData.objectData,
            objectId: this.props.interimData.objectId,
            objectType: this.props.interimData.objectType
        });
    }

    handleInputChange(event) {
        // const target = event.target;
        // const value = target.value;
        // const name = target.name;
        //
        // this.setState({
        //     [name]: value
        // });

        // console.log(event.target.files[0]);

        this.setState({
            fileUpload: event.target.files[0]
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.fileUpload) {

            if (this.state.fileUpload.type === 'application/pdf' // PDF
                || this.state.fileUpload.type === 'image/png' // PNG
                || this.state.fileUpload.type === 'image/jpeg' // JPG
                || this.state.fileUpload.type === 'application/vnd.ms-excel' // XLS
                || this.state.fileUpload.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // XLSX
                || this.state.fileUpload.type === 'application/vnd.openxmlformats-officedocument.spre' // XLSX
                || this.state.fileUpload.type === 'application/vnd.ms-excel.sheet.macroEnabled.12' // XLSM
                || this.state.fileUpload.type === 'application/vnd.openxmlformats-officedocument.pres' // PPTX
                || this.state.fileUpload.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' // PPTX
                || this.state.fileUpload.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // DOCX
                || this.state.fileUpload.type === 'application/vnd.openxmlformats-officedocument.word' // DOCX
                || this.state.fileUpload.type === 'application/msword' // DOC
            ) {

                if (Number(this.state.fileUpload.size) < 268435456) {
                    let formData = new FormData();

                    formData.append('file_name', this.state.fileUpload);

                    this.props.apiRequest('/' + this.state.objectType + '/' + this.state.objectId + '/file_upload', formData, () => {
                            this.setState({
                                filesDataResponseError: null
                            });
                            this.formfilesData();
                            this.props.pageUpdate();
                        }, () => {
                            this.setState({
                                filesDataResponseError: this.props.responseError
                            })
                        }
                    );
                }

                else {
                    this.setState({
                        filesDataResponseError: 'Размер файла не должен превышать ' + this.fileSize(268435456) + '.'
                    })
                }
            }

            else {
                this.setState({
                    filesDataResponseError: 'Возможна загрузка документов формата PDF, PNG, JPG(JPEG), DOC(DOCX), XLS(XLSX,XLSM) и PPT(PPTX).'
                })
            }
        }

        else {
            this.setState({
                filesDataResponseError: 'Пожалуйста, выберите документ для загрузки.'
            });
        }
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    modalClose() {
        this.props.modalClose();
    }

    formfilesData() {
        this.setState({
            isFilesData: true
        });

        this.props.apiRequest('/' + this.state.objectType + '/' + this.state.objectId + '/files', null, () => {
            this.setState({
                filesData: this.props.responseData,
                isFilesData: false
            });
        }, null);
    }

    fileSize(length) {
        let i = 0, type = ['б','Кб','Мб','Гб','Тб','Пб'];
        while((length / 1000 | 0) && i < type.length - 1) {
            length /= 1024;
            i++;
        }
        return length.toFixed(2) + ' ' + type[i];
    }

    render() {
        const { isRequest } = this.props;
        const { objectId, objectType, isFilesData, filesData, filesDataResponseError } = this.state;

        let interimData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        let fileType = (type) => {

            if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type === 'application/vnd.openxmlformats-officedocument.word' || type === 'application/msword') {
                return (<div className="File__format File__format_doc">DOC</div>);
            }

            else if (type === 'application/vnd.ms-excel' || type === 'application/vnd.ms-excel.sheet.macroEnabled.12' || type === 'application/vnd.openxmlformats-officedocument.spre' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                return (<div className="File__format File__format_xls">XLS</div>);
            }

            else if (type === 'application/vnd.openxmlformats-officedocument.pres' || type==='application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                return (<div className="File__format File__format_ppt">PPT</div>);
            }

            else if (type === 'application/pdf') {
                return (<div className="File__format File__format_pdf">PDF</div>);
            }

            else if (type === 'image/png') {
                return (<div className="File__format File__format_png">PNG</div>);
            }

            else if (type === 'image/jpeg') {
                return (<div className="File__format File__format_jpg">JPG</div>);
            }

            else {
                return (<div className="File__format"/>);
            }
        }

        let fileSize = (length) => {
            let i = 0, type = ['б','Кб','Мб','Гб','Тб','Пб'];
            while((length / 1000 | 0) && i < type.length - 1) {
                length /= 1024;
                i++;
            }
            return length.toFixed(2) + ' ' + type[i];
        }

        return (
            <div className="Form Form_width-796">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Документы</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">


                            <div className="Box__files">
                                {isFilesData ? (<Loading />) : (
                                    filesData ? (
                                        filesData.length > 0 ? (
                                            <React.Fragment>
                                                <div className="Box__label">Загруженные документы</div>
                                                <table className="Files__table">
                                                    <tbody>
                                                    {filesData.map(file => (
                                                        (file.file_id ? (
                                                        <tr className="Table__tr" key={file.file_id}>
                                                            <td className="Table__td">
                                                                <div className="Link">
                                                                    {fileType(file.file_type)}
                                                                    <div className="File__name">{file.file_name}</div>
                                                                    <div className="File__size">{fileSize(file.file_size)}</div>
                                                                    <div className="File__delete" onClick={this.modalOpen.bind(this, 'formFilesDelete', interimData(objectId, objectType, file.file_id))}>Удалить&nbsp;файл</div>
                                                                </div>
                                                            </td>
                                                        </tr>) : null)
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </React.Fragment>):(
                                            <div className='Box__text'>Пока нет загруженных документов</div>
                                        )) : (
                                        <div className='Box__text'>Пока нет загруженных документов</div>
                                    ))}
                            </div>

                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Выбрать документ</div>
                            <input className="Box__file"
                                   name="fileUpload"
                                   type="file"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{filesDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Загрузить документ'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesEdit);
