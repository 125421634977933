import React from "react";
import './Loading.css';

class Loading extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            white: this.props.white
        };
    }

    render() {
        return (<div className={this.state.white ? 'Loader Loader_white' : 'Loader'}/>);
    }
}

export default Loading;
