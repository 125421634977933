import React from "react";
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class EventMemberAlter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectMember: 0,
            projectMemberData: null,
            projectMemberAddResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            projectMember: this.props.interimData.user_id
        });
        this.projectMemberData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.projectMember > 0) {

            let formData = new FormData();
            formData.append('user_id', this.state.projectMember);

            this.props.apiRequest('/event/' + this.props.interimData.events_id + '/user_create', formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        projectMemberAddResponseError: this.props.responseError
                    })
                }
            );
        }

        else {
            this.setState({
                projectMemberAddResponseError: 'Пожалуйста, выберите ответственное лицо за проект.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    projectMemberData() {
        this.props.apiRequest('/event/' + this.props.interimData.events_id + '/user_select', null, () => {
            this.setState({
                projectMemberData: this.props.responseData
            });
        }, null);
    }

    render() {
        const { isRequest, interimData } = this.props;
        const { projectMemberData, projectMember, projectMemberAddResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Поменять ответсвенного</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Участники от предприятия</div>

                            {!projectMemberData ? (
                                <select className="Box__select" name="projectMember" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={interimData.user_id}>
                                    <option value={interimData.user_id}>{interimData.user_fio}</option>
                                </select>) : (
                                <select className="Box__select" name="projectMember" value={projectMember} onChange={this.handleInputChange.bind(this)} defaultValue={projectMember}>
                                    <option value="0">Выберите участника от проекта</option>
                                    <option  className="option__hr" disabled />
                                    {!projectMemberData ? null : (
                                        projectMemberData.map((option, index) => (
                                            <React.Fragment key={index}>
                                                <option value={option.user_id} key={option.user_id}>{option.user_fio}</option>
                                                <option className="option__info" disabled>{option.user_eml}</option>
                                            </React.Fragment>
                                        )))}
                                </select>
                            )}

                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{projectMemberAddResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Выбрать'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventMemberAlter);
