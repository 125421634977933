import React from "react";
import '../Form.scss';
import {modalClose, modalOpen} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class ProjectData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectName: null,
            projectTarget: '',
            projectType: 0,
            projectTypeData: null,
            projectDataResponseError: null
        };
    }

    componentDidMount() {
        this.projectTypeData();
        this.setState({
            projectName: this.props.interimData.project_name,
            projectTarget: this.props.interimData.project_target,
            projectType: this.props.interimData.project_type_id
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        console.log(this.state.projectType);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.projectName && this.state.projectType > 0) {

            let formData = new FormData();

            formData.append('project_name', this.state.projectName);
            formData.append('project_target', this.state.projectTarget);
            formData.append('project_type_id', this.state.projectType);

            this.props.apiRequest('/project/' + this.props.interimData.project_id + '/edit', formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        projectDataResponseError: this.props.responseError
                    })
                }
            );
        }

        else {
            this.setState({
                projectDataResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
            });
        }
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    modalClose() {
        this.props.modalClose();
    }

    projectTypeData() {
        this.props.apiRequest('/dictionary/project_type', null, () => {
            this.setState({
                projectTypeData: this.props.responseData
            });
        }, null);
    }

    render() {
        const { interimData, isRequest } = this.props;
        const { projectType, projectTypeData, projectDataResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Проект</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__label">Название проекта *</div>
                            <input className="Box__input"
                                   name="projectName"
                                   type="text"
                                   defaultValue={interimData.project_name}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Цель проекта</div>
                            <textarea className="Box__input"
                                      rows={5}
                                      name="projectTarget"
                                      defaultValue={interimData.project_target}
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Направление проекта *</div>
                            {!projectTypeData ? (
                                <select className="Box__select" name="projectType" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={interimData.project_type_id}>
                                    <option value={interimData.project_type_id}>{interimData.project_type_name}</option>
                                </select>) : (
                                <select className="Box__select" name="projectType" value={projectType} onChange={this.handleInputChange.bind(this)} defaultValue={projectType}>
                                    {projectTypeData.map(option => (
                                        (option.project_type_id > 0 ? <option value={option.project_type_id} key={option.project_type_id}>{option.project_type_name}</option> : null)
                                    ))}
                                </select>
                            )}
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{projectDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                    <div className="Form__link">
                        <div className="Link__delete" onClick={this.modalOpen.bind(this, 'formProjectDelete', interimData)}>Удалить проект</div>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectData);
