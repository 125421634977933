import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class CompanyCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            companyType: 0,
            companyInn: null,
            companyIndust: 0,
            companyName: null,
            companyOgrn: null,
            companyAddress: null,
            companyPhone: null,
            companySchedule: null,
            companyWebsite: null,
            companyEmail: null,
            isSearch: false,
            companySearchData: null,
            companySearchError: null,
            companyIndustryData: null,
            companyTypeData: null,
            companyCreateResponseError: null,
            companyLogo: null,
            companyProductPhoto: null,
            companyDistrict: null,
            companyCoordinates: null,
            contactUserId: null,
        };
    }

    componentDidMount() {
        this.companyTypeData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.companyInn && this.state.companyName && this.state.companyType > 0 && this.state.companyIndust > 0) {
            const companyInnNumb = this.state.companyInn;

            if (companyInnNumb.length > 9) {
                let formData = new FormData();

                formData.append('comp_type', this.state.companyType);
                formData.append('comp_inn', this.state.companyInn);
                formData.append('comp_indust', this.state.companyIndust);
                formData.append('comp_name', this.state.companyName);
                formData.append('comp_ogrn', this.state.companyOgrn);
                formData.append('comp_address', this.state.companyAddress);
                formData.append('comp_phone', this.state.companyPhone);
                formData.append('comp_sched', this.state.companySchedule);
                formData.append('comp_web', this.state.companyWebsite);
                formData.append('comp_mail', this.state.companyEmail);

                formData.append('company_logo', this.state.companyLogo);
                formData.append('company_product_photo', this.state.companyProductPhoto);
                formData.append('company_district', this.state.companyDistrict);
                formData.append('company_coordinates', this.state.companyCoordinates);
                formData.append('contact_user_id', this.state.contactUserId);

                this.props.apiRequest('/company', formData, () => {
                        this.props.modalClose();
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                    }, () => {
                        this.setState({
                            companyCreateResponseError: this.props.responseError
                        })
                    }
                );
            }

            else {
                this.setState({
                    companyCreateResponseError: 'Поле ИНН предприятия, должно состоять минимум из 10 цифр.'
                });
            }
        }

        else {
            this.setState({
                companyCreateResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    companySearch() {
        let formData = new FormData();

        this.setState({
            isSearch: true
        });

        console.log(this.state.companyInn);
        formData.append('company_inn', this.state.companyInn);

        this.props.apiRequest('/companies/search', formData, () => {
            console.log(0);
            if (!!this.props.responseData[0].company_inn) {
                console.log(1);
                this.setState({
                    isSearch: false,
                    companySearchData: this.props.responseData[0],
                    companySearchError: null
                });

                if (this.props.responseData[0].company_inn !== this.state.companyInn) {
                    this.setState({
                        companySearchError: 'Не удалось найти по данному ИНН, предприятие.'
                    });
                }

            } else {
                console.log(2);
                this.setState({
                    isSearch: false,
                    companySearchData: this.props.responseData,
                    companySearchError: null
                });

                if (this.props.responseData.company_inn !== this.state.companyInn) {
                    this.setState({
                        companySearchError: 'Не удалось найти по данному ИНН, предприятие.'
                    });
                }
            }

            console.log(this.props.responseData);
            }, () => {
                console.log(3);
                this.setState({
                    isSearch: false,
                    companySearchData: null,
                    companySearchError: this.props.responseError
                });
        });
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    companySearchData(companySearchData) {

        this.setStateAndInput('companyType', Number(companySearchData.company_type_id));
        this.setStateAndInput('companyInn', companySearchData.company_inn);
        this.setStateAndInput('companyIndust', Number(companySearchData.company_industry_id));
        this.setStateAndInput('companyName', companySearchData.company_name);
        this.setStateAndInput('companyOgrn', companySearchData.company_ogrn);
        this.setStateAndInput('companyAddress', companySearchData.company_address);
        this.setStateAndInput('companyPhone', companySearchData.company_phone);
        this.setStateAndInput('companySchedule', companySearchData.company_schedule);
        this.setStateAndInput('companyWebsite', companySearchData.company_website);
        this.setStateAndInput('companyEmail', companySearchData.company_mail);

        this.setState({
            companyLogo: companySearchData.company_logo,
            companyProductPhoto: companySearchData.company_product_photo,
            companyDistrict: companySearchData.company_district,
            companyCoordinates: companySearchData.company_coordinates,
            contactUserId: companySearchData.contact_user_id,
            companySearchError: null
        });
    }

    companyIndustryData() {
        this.props.apiRequest('/dictionary/company_industry', null, () => {
            this.setState({
                companyIndustryData: this.props.responseData
            });
        }, null);
    }

    companyTypeData() {
        this.props.apiRequest('/dictionary/company_type', null, () => {
            this.setState({
                companyTypeData: this.props.responseData
            });
            this.companyIndustryData();
        }, null);
    }

    render() {
        const { isRequest } = this.props;
        const { companyType,
                companyInn,
                companyIndust,
                companyName,
                companyOgrn,
                companyAddress,
                companyPhone,
                companySchedule,
                companyWebsite,
                companyEmail,
                isSearch,
                companySearchData,
                companyTypeData,
                companyIndustryData,
                companySearchError,
                companyCreateResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Добавить предприятие</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Название предприятия *</div>
                            <input className="Box__input"
                                   id="companyName"
                                   name="companyName"
                                   type="text"
                                   defaultValue={companyName}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">ИНН предприятия * <div className="Box__label_link" onClick={this.companySearch.bind(this)}>Искать по ИНН</div></div>
                            <input className="Box__input"
                                   id="companyInn"
                                   name="companyInn"
                                   type="number"
                                   defaultValue={companyInn}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)}
                                   autoFocus />
                        </div>

                        {!isSearch ? (!companySearchData ? (
                            <div className="Content__error">
                                <span className="Error__text">{companySearchError}</span>
                            </div>) : (companySearchError ? (
                                <div className="Content__error">
                                    <span className="Error__text">{companySearchError}</span>
                                </div>) : (
                                <div className="Content__box">
                                    <div className="Box__label">Применить результат поиска:</div>
                                    <div className="Box__link" onClick={this.companySearchData.bind(this, companySearchData)}>{companySearchData.company_name}</div>
                                </div>)
                        )) : <Loading />}

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Тип предприятия *</div>
                                <select className="Box__select" id="companyType" name="companyType" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={companyType}>
                                    <option value='0'>Выберите тип придприятия</option>
                                    {!companyTypeData ? null : (
                                        (companyTypeData.map(option => (
                                            (option.company_type_id > 0 ? <option value={option.company_type_id} key={option.company_type_id}>{option.company_type_name}</option> : null)
                                    ))))}
                                </select>
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Отрасль *</div>
                                <select className="Box__select" id="companyIndust" name="companyIndust" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={companyIndust}>
                                    <option value='0'>Выберите отрасль предприятия</option>
                                    {!companyIndustryData ? null : (
                                        (companyIndustryData.map(option => (
                                            (option.company_industry_id > 0 ? <option value={option.company_industry_id} key={option.company_industry_id}>{option.company_industry_name}</option> : null)
                                    ))))}
                                </select>
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">ОГРН предприятия</div>
                            <input className="Box__input"
                                   id="companyOgrn"
                                   name="companyOgrn"
                                   type="number"
                                   defaultValue={companyOgrn}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Юридический адрес</div>
                            <input className="Box__input"
                                   id="companyAddress"
                                   name="companyAddress"
                                   type="text"
                                   defaultValue={companyAddress}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Время работы</div>
                            <input className="Box__input"
                                   id="companySchedule"
                                   name="companySchedule"
                                   type="text"
                                   defaultValue={companySchedule}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Телефон</div>
                            <input className="Box__input"
                                   id="companyPhone"
                                   name="companyPhone"
                                   type="tel"
                                   defaultValue={companyPhone}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Официальный сайт</div>
                            <input className="Box__input"
                                   id="companyWebsite"
                                   name="companyWebsite"
                                   type="text"
                                   defaultValue={companyWebsite}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Электронная почта</div>
                            <input className="Box__input"
                                   id="companyEmail"
                                   name="companyEmail"
                                   type="email"
                                   defaultValue={companyEmail}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{companyCreateResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);
