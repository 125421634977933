import React from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {autoLogin} from "./store/actions/authAction";
import './App.scss';
import Login from "./components/auth/Login";
import Cabinet from "./components/Cabinet";

class App extends React.Component {

    componentDidMount() {
        this.props.autoLogin();
    }

    render() {
        const {isAutoLogin, isAuthenticated} = this.props;

        if (!isAutoLogin) {
            return null;
        }

        else {
            if (isAuthenticated) {
                return (
                    <div className="App">
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/cabinet/dashboard"/>
                            </Route>
                            <Route path="/" component={Cabinet}/>
                        </Switch>
                    </div>
                );
            }

            else {
                return (
                    <div className="App">
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/login"/>
                            </Route>
                            <Route exact path="/login" component={Login}/>
                            <Route path="/">
                                <Redirect to="/login"/>
                            </Route>
                        </Switch>
                    </div>
                );
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        isAutoLogin: state.auth.isAutoLogin,
        isAuthenticated: !!state.auth.token
    }
}

function mapDispatchToProps(dispatch) {
    return {
        autoLogin: () => dispatch(autoLogin())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (App);
