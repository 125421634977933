export const AUTH_REQUEST = 'auth_request';
export const AUTH_ERROR = 'auth_error';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_LOGOUT = 'auth_logout';

export const CABINET_OPEN = 'cabinet_open';
export const CABINET_EDIT = 'cabinet_edit';

export const MODAL_OPEN = 'modal_open';
export const MODAL_CLOSE = 'modal_close';

export const ALERT_OPEN = 'alert_open';
export const ALERT_CLOSE = 'alert_close';

export const NOTIFICATION_MESSAGE = 'notification_message';
export const NOTIFICATION_CLEAR = 'notification_clear';

export const API_REQUEST = 'api_request';
export const API_RESPONSE = 'api_response';
export const API_RESPONSE_ERROR = 'api_response_error';
export const API_REQUEST_ERROR = 'api_request_error';

export const PAGE_LOAD = 'page_load';
export const PAGE_UPDATE = 'page_update';

export const FORM_LOAD = 'form_load';
export const FORM_UPDATE = 'form_update';
