import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../store/actions/modalAction";
import "./Modal.scss";
import "./form/Form.scss";
import ProfileData from "./form/profile/profileData";
import ProfilePassword from "./form/profile/profilePassword";
import UserCreate from "./form/user/userCreate";
import UserPassword from "./form/user/userPassword";
import UserEmail from "./form/user/userEmail";
import UserData from "./form/user/userData";
import UserRole from "./form/user/userRole";
import UserDelete from "./form/user/userDelete";
import UserRestore from "./form/user/userRestore";
import CompanyCreate from "./form/company/companyCreate";
import CompanyUserContact from "./form/company/companyUserContact";
import ProjectCreate from "./form/project/projectCreate";
import ProjectData from "./form/project/projectData";
import EventCreate from "./form/event/eventCreate";
import EventData from "./form/event/eventData";
import EventDelete from "./form/event/eventDelete";
import EventPeriod from "./form/event/eventPeriod";
import EventClose from "./form/event/eventClose";
import EventOpen from "./form/event/eventOpen";
import ProjectPeriod from "./form/project/projectPeriod";
import ProjectClose from "./form/project/projectClose";
import ProjectOpen from "./form/project/projectOpen";
import ProjectStandard from "./form/project/projectStandard";
import ProjectAntistandard from "./form/project/projectAntistandard";
import ProjectMemberDel from "./form/project/projectMemberDel";
import ProjectAdminDel from "./form/project/projectAdminDel";
import ProjectAdminAdd from "./form/project/projectAdminAdd";
import ProjectMemberAdd from "./form/project/projectMemberAdd";
import CompanyProgramDate from "./form/company/companyProgramDate";
import CompanyData from "./form/company/companyData";
import ProjectProblemCreate from "./form/project/problem/projectProblemCreate";
import ProjectProblemData from "./form/project/problem/projectProblemData";
import ProjectProblemDelete from "./form/project/problem/projectProblemDelete";
import ProjectMemberAlter from "./form/project/projectMemberAlter";
import ProjectAdminAlter from "./form/project/projectAdminAlter";
import FilesEdit from "./form/files/filesEdit";
import FilesDelete from "./form/files/filesDelete";
import PhotosEdit from "./form/photos/photosEdit";
import PhotosDelete from "./form/photos/photosDelete";
import CompanyProgramData from "./form/company/program/companyProgramData";
import CompanyProgramDelete from "./form/company/program/companyProgramDelete";
import CompanyProgramEdit from "./form/company/program/companyProgramEdit";
import CompanyProgramCountEdit from "./form/company/program/companyProgramCountEdit";
import CompanyProgramCountDelete from "./form/company/program/companyProgramCountDelete";
import CompanyIndicatorEdit from "./form/company/indicator/companyIndicatorEdit";
import FormCompanySupportsEdit from "./form/company/formCompanySupportsEdit";
import ProjectProblemUnresolved from "./form/project/problem/projectProblemUnresolved";
import ProjectProblemDecided from "./form/project/problem/projectProblemDecided";
import ProjectTeamAdd from "./form/project/team/projectTeamAdd";
import ProjectTeamDel from "./form/project/team/projectTeamDel";
import EventMemberDel from "./form/event/eventMemberDel";
import EventMemberAlter from "./form/event/eventMemberAlter";
import EventMemberAdd from "./form/event/eventMemberAdd";
import ProjectObjectiveEnd from "./form/project/objective/projectObjectiveEnd";
import ProjectObjectiveBegin from "./form/project/objective/projectObjectiveBegin";
import ProjectObjectiveName from "./form/project/objective/projectObjectiveName";
import ProjectObjectiveDel from "./form/project/objective/projectObjectiveDel";
import ProjectObjectiveAdd from "./form/project/objective/projectObjectiveAdd";
import DashboardAverageEdit from "./form/dashboard/dashboardAverageEdit";
import ProjectDelete from "./form/project/projectDelete";
import ProjectTeamNotSystemDel from "./form/project/team/projectTeamNotSystemDel";
import EventTeamNotSystemDel from "./form/event/eventTeamNoSystemDel";
import EventTeamDel from "./form/event/eventTeamDel";
import EventTeamAdd from "./form/event/eventTeamAdd";
import FormMailingCreate from "./form/mailing/formMailingCreate";
import FormMailingEdit from "./form/mailing/formMailingEdit";
import FormMailingSend from "./form/mailing/formMailingSend";
import ModalDeviceNameEdit from "../devices/modal/modalDeviceNameEdit";

class Modal extends React.Component {

    modalClose() {
        this.props.modalClose();
    }

    switchForm(modalForm) {
        switch(modalForm) {
            case 'formProfileData': return (<ProfileData />);
            case 'formProfilePassword': return (<ProfilePassword />);
            case 'formUserCreate': return (<UserCreate />);
            case 'formUserData': return (<UserData />);
            case 'formUserEmail': return (<UserEmail />);
            case 'formUserRole': return (<UserRole />);
            case 'formUserPassword': return (<UserPassword />);
            case 'formUserDelete': return (<UserDelete />);
            case 'formUserRestore': return (<UserRestore />);
            case 'formCompanyCreate': return (<CompanyCreate />);
            case 'formCompanyData': return (<CompanyData />);
            case 'formCompanyProgramDate': return (<CompanyProgramDate />);
            case 'formCompanyUserContact': return (<CompanyUserContact />);
            case 'formProjectCreate': return (<ProjectCreate />);
            case 'formProjectData': return (<ProjectData />);
            case 'formEventCreate': return (<EventCreate />);
            case 'formEventData': return (<EventData />);
            case 'formEventPeriod': return (<EventPeriod />);
            case 'formEventDelete': return (<EventDelete />);
            case 'formEventClose': return (<EventClose />);
            case 'formEventOpen': return (<EventOpen />);
            case 'formProjectPeriod': return (<ProjectPeriod />);
            case 'formProjectClose': return (<ProjectClose />);
            case 'formProjectOpen': return (<ProjectOpen />);
            case 'formProjectStandard': return (<ProjectStandard />);
            case 'formProjectAntistandard': return (<ProjectAntistandard />);
            case 'formProjectAdminAdd': return (<ProjectAdminAdd />);
            case 'formProjectAdminAlter': return (<ProjectAdminAlter />);
            case 'formProjectAdminDel': return (<ProjectAdminDel />);
            case 'formProjectMemberAdd': return (<ProjectMemberAdd />);
            case 'formProjectMemberAlter': return (<ProjectMemberAlter />);
            case 'formProjectMemberDel': return (<ProjectMemberDel />);
            case 'formProjectProblemCreate': return (<ProjectProblemCreate />);
            case 'formProjectProblemData': return (<ProjectProblemData />);
            case 'formProjectProblemDelete': return (<ProjectProblemDelete />);
            case 'formProjectProblemUnresolved': return (<ProjectProblemUnresolved />);
            case 'formProjectProblemDecided': return (<ProjectProblemDecided />);
            case 'formFilesEdit': return (<FilesEdit />);
            case 'formFilesDelete': return (<FilesDelete />);
            case 'formPhotosEdit': return (<PhotosEdit />);
            case 'formPhotosDelete': return (<PhotosDelete />);
            case 'formCompanyProgramData': return (<CompanyProgramData />);
            case 'formCompanyProgramDelete': return (<CompanyProgramDelete />);
            case 'formCompanyProgramEdit': return (<CompanyProgramEdit />);
            case 'formCompanyProgramCountEdit': return (<CompanyProgramCountEdit />);
            case 'formCompanyProgramCountDelete': return (<CompanyProgramCountDelete />);
            case 'formCompanyIndicatorEdit': return (<CompanyIndicatorEdit />);
            case 'formCompanySupportEdit': return (<FormCompanySupportsEdit />);
            case 'formProjectTeamAdd': return (<ProjectTeamAdd />);
            case 'formProjectTeamDel': return (<ProjectTeamDel />);
            case 'formEventMemberDel': return (<EventMemberDel />);
            case 'formEventMemberAlter': return (<EventMemberAlter />);
            case 'formEventMemberAdd': return (<EventMemberAdd />);
            case 'formProjectObjectiveEnd': return (<ProjectObjectiveEnd />);
            case 'formProjectObjectiveBegin': return (<ProjectObjectiveBegin />);
            case 'formProjectObjectiveName': return (<ProjectObjectiveName />);
            case 'formProjectObjectiveAdd': return (<ProjectObjectiveAdd />);
            case 'formProjectObjectiveDel': return (<ProjectObjectiveDel />);
            case 'formDashboardAverageEdit': return (<DashboardAverageEdit />);
            case 'formProjectDelete': return (<ProjectDelete />);
            case 'formProjectTeamNotSystemDel': return (<ProjectTeamNotSystemDel />);
            case 'formEventTeamAdd': return (<EventTeamAdd />);
            case 'formEventTeamDel': return (<EventTeamDel />);
            case 'formEventTeamNotSystemDel': return (<EventTeamNotSystemDel />);
            case 'formMailingCreate': return (<FormMailingCreate />);
            case 'formMailingEdit': return (<FormMailingEdit />);
            case 'formMailingSend': return (<FormMailingSend />);


            case 'modalDeviceNameEdit': return (<ModalDeviceNameEdit/>);
            default: return (<div className="Form Form_null" />);
        }
    }

    render() {
        const { isModal, modalForm } = this.props;

        let switchForm = this.switchForm(modalForm);

        if (!isModal) { return null; }

        else {
            return (
                <div className="Modal">
                    <i/>
                    <div className="Modal__dialog">
                        <div className="Modal__content">
                            <div className="Modal__body">
                                {switchForm}
                            </div>
                            <div className="Modal__close">
                                <div className="Button__close icon-close" onClick={this.modalClose.bind(this, false, null)} />
                            </div>
                        </div>
                    </div>
                    <i/>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isModal: state.modal.isModal,
        modalForm: state.modal.modalForm,
        modalData: state.modal.modalData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
