import React from 'react';
import {connect} from 'react-redux';
import {authLogout} from "../../store/actions/authAction";

class Logout extends React.Component {

    componentDidMount() {
        this.props.authLogout(() => {
            // console.log('Auth logout.');
            // this.props.history.push('/home');
            this.props.history.push('/login');
        });
    }

    render() {
        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authLogout: (callback) => dispatch(authLogout(callback))
    }
}

export default connect(null, mapDispatchToProps)(Logout);
