import {PAGE_LOAD, PAGE_UPDATE} from "./actionTypes";
import {modalClose} from "./modalAction";
import {alertClose} from "./alertAction";

export function pageLoad(isLoad) {

    isLoad = !isLoad;

    return {
        type: PAGE_LOAD,
        isLoad
    }
}

export function pageUpdate() {

    return {
        type: PAGE_UPDATE
    }
}

export function pageMount() {
    return dispatch => {
        dispatch(modalClose());
        dispatch(alertClose());
    }
}
