import React from "react";
import {modalClose, modalOpen} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class DashboardAverageEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // objectId: this.props.interimData.company_id,


            indicatorYear: new Date().getFullYear(),
            oldYear: new Date().getFullYear(),
            indicatorQuarter: 1,
            oldQuarter: 1,

            indicatorYearArray: [...Array.from(Array(new Date().getFullYear() - 1969).keys(),x => new Date().getFullYear() - x)],




            indicatorProceeds: 0,








            indicatorsData: null,
            isIndicatorsData: false,







            companySupportEditResponseError: null
        };
    }

    componentDidMount() {
        this.formIndicatorsData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            // console.log(this.state.indicatorYear + ' ' + this.state.oldYear);
            // console.log(this.state.indicatorQuarter + ' ' + this.state.oldQuarter);

            if (this.state.indicatorQuarter !== this.state.oldQuarter) {
                this.formQuarterData();
                this.setState({
                    oldQuarter: this.state.indicatorQuarter
                });
            }

            if (this.state.indicatorYear !== this.state.oldYear) {
                this.formIndicatorsData();
                this.setState({
                    oldYear: this.state.indicatorYear
                });
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();


        let formData = new FormData();

        formData.append('quarter', this.state.indicatorQuarter);
        formData.append('year', this.state.indicatorYear);

        formData.append('quant', this.state.indicatorProceeds);

        this.props.apiRequest('/statistics/indicator_create', formData, () => {
                this.props.modalClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
            }, () => {
                this.setState({
                    companySupportEditResponseError: this.props.responseError
                })
            }
        );
    }

    modalClose() {
        this.props.modalClose();
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    formIndicatorsData() {

        let formData = new FormData();

        formData.append('year', this.state.indicatorYear);

        this.setState({
            isIndicatorsData: true
        });

        this.props.apiRequest('/statistics/indicator_year', formData, () => {
            this.setState({
                indicatorsData: this.props.responseData,
                isIndicatorsData: false
            }, () => {
                this.formQuarterData();
            });
            // console.log(this.state.indicatorsData);
        }, null);
    }

    formQuarterData() {
        let indicatorsData = this.state.indicatorsData;

        let indicatorProceeds = 0;
        // let indicatorExpenses = 0;
        // let indicatorSalary = 0;
        // let indicatorInsurancePremiums = 0;
        // let indicatorsTax = 0;
        // let indicatorsLaborCosts = 0;
        // let indicatorsProceeds2 = 0;
        // let indicatorsNumber = 0;

        if (indicatorsData.length > 0) {

            for (let i = 0; i < indicatorsData.length; i++) {

                if (Number(this.state.indicatorQuarter) === Number(indicatorsData[i].dashboard_indicators_quarter)) {
                    indicatorProceeds = Number(indicatorsData[i].dashboard_indicators_quant);
                    // indicatorExpenses = Number(indicatorsData[i].indicators_direct_expenses);
                    // indicatorSalary = Number(indicatorsData[i].indicators_salary);
                    // indicatorInsurancePremiums = Number(indicatorsData[i].indicators_insurance_premiums);
                    // indicatorsTax = Number(indicatorsData[i].indicators_tax);
                    // indicatorsLaborCosts = Number(indicatorsData[i].indicators_labor_costs);
                    // indicatorsProceeds2 = Number(indicatorsData[i].indicators_proceeds2);
                    // indicatorsNumber = Number(indicatorsData[i].indicators_number);

                    this.setState({
                        indicatorProceeds: indicatorProceeds,
                        // indicatorExpenses: indicatorExpenses,
                        // indicatorSalary: indicatorSalary,
                        // indicatorInsurancePremiums: indicatorInsurancePremiums,
                        // indicatorsTax: indicatorsTax,
                        // indicatorsLaborCosts: indicatorsLaborCosts,
                        // indicatorsProceeds2: indicatorsProceeds2,
                        // indicatorsNumber: indicatorsNumber,
                    });

                    this.setStateAndInput('indicatorProceeds', indicatorProceeds);
                    // this.setStateAndInput('indicatorExpenses', indicatorExpenses);
                    // this.setStateAndInput('indicatorSalary', indicatorSalary);
                    // this.setStateAndInput('indicatorInsurancePremiums', indicatorInsurancePremiums);
                    // this.setStateAndInput('indicatorsTax', indicatorsTax);
                    // this.setStateAndInput('indicatorsLaborCosts', indicatorsLaborCosts);
                    // this.setStateAndInput('indicatorsProceeds2', indicatorsProceeds2);
                    // this.setStateAndInput('indicatorsNumber', indicatorsNumber);
                }
            }
        }

    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    inputDateMonthYear(date) {

        const dateMonthYear = new Date(date * 1000);

        let dd = dateMonthYear.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = dateMonthYear.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        let yyyy = dateMonthYear.getFullYear();
        if (yyyy < 10) yyyy = '0' + yyyy;

        return yyyy + '-' + mm + '-' + dd;
    }

    render() {
        const { isRequest } = this.props;
        const { companySupportEditResponseError,

            // countDate, isSupportsData, SupportsData, countNumber, supportType,

            indicatorYear,
            indicatorQuarter,
            indicatorYearArray,


            indicatorProceeds,
            // indicatorExpenses,
            // indicatorSalary,
            // indicatorInsurancePremiums,
            // indicatorsTax,
            // indicatorsLaborCosts,
            // indicatorsProceeds2,
            // indicatorsNumber,



        } = this.state;



        // console.log(indicatorYear); console.log(indicatorYearArray);





        // let interimData = (objectId, objectType, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectType: objectType,
        //         objectData: objectData
        //     };
        // }
        //
        // let dateMonthYear = (date) => {
        //
        //     const dateMonthYear = new Date(date * 1000);
        //
        //     let dd = dateMonthYear.getDate();
        //     if (dd < 10) dd = '0' + dd;
        //
        //     let mm = dateMonthYear.getMonth() + 1;
        //     if (mm < 10) mm = '0' + mm;
        //
        //     let yyyy = dateMonthYear.getFullYear();
        //     if (yyyy < 10) yyyy = '0' + yyyy;
        //
        //     return dd + '.' + mm + '.' + yyyy;
        // }

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Средние показатели</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Показатели в год</div>
                            <select className="Box__select" name="indicatorYear" value={indicatorYear} onChange={this.handleInputChange.bind(this)}>
                                {indicatorYearArray.map(year => (<option value={year} key={year}>{year} год</option>))}
                            </select>
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Показатели в квартал</div>
                            <select className="Box__select" name="indicatorQuarter" value={indicatorQuarter} onChange={this.handleInputChange.bind(this)}>
                                <option value={1}>1 квартал</option>
                                <option value={2}>2 квартал</option>
                                <option value={3}>3 квартал</option>
                                <option value={4}>4 квартал</option>
                            </select>
                        </div>




                        {/*<div className="Content__box">*/}


                        {/*<div className="Box__counts">*/}
                        {/*    {isSupportsData ? (<Loading />) : (*/}
                        {/*        SupportsData ? (*/}
                        {/*            SupportsData.length > 0 ? (*/}
                        {/*                <table className="Counts__table">*/}
                        {/*                    <thead>*/}
                        {/*                    <tr className="Table__tr">*/}
                        {/*                        <th className="Table__th">*/}
                        {/*                            <div className="Th__title">Дата поддержки</div>*/}
                        {/*                            <div className="Th__count">Сумма</div>*/}
                        {/*                        </th>*/}
                        {/*                    </tr>*/}
                        {/*                    </thead>*/}
                        {/*                    <tbody>*/}
                        {/*                    {SupportsData.map(support => (*/}
                        {/*                        (Number(support.support_name_id) === Number(supportType) ? (*/}
                        {/*                            <tr className="Table__tr" key={support.support_id}>*/}
                        {/*                                <td className="Table__td">*/}
                        {/*                                    <div className="Link">*/}
                        {/*                                        <div className="Program__name">{dateMonthYear(support.support_date)}</div>*/}
                        {/*                                        <div className="Program__count">{Number(support.support_quant)}</div>*/}
                        {/*                                        <div className="Program__delete" onClick={this.modalOpen.bind(this, 'formCompanySupportDelete', interimData(this.state.objectId, null, support.support_id))}>Удалить</div>*/}
                        {/*                                    </div>*/}
                        {/*                                </td>*/}
                        {/*                            </tr>*/}
                        {/*                        ) : null)*/}
                        {/*                    ))}*/}
                        {/*                    </tbody>*/}
                        {/*                </table>*/}
                        {/*            ):(*/}
                        {/*                <div className='Box__text Box__text_disabled'>Пока нет записей о поддержке</div>*/}
                        {/*            )) : (*/}
                        {/*            <div className='Box__text Box__text_disabled'>Пока нет записей о поддержке</div>*/}
                        {/*        ))}*/}
                        {/*</div>*/}

                        {/*</div>*/}


                        <hr className="Content__hr" />



























                        <div className="Content__box">
                            <div className="Box__label">Средний показатель (млн. рублей)</div>
                            <input className="Box__input"
                                   id="indicatorProceeds"
                                   name="indicatorProceeds"
                                   type="number"
                                   step={0.1}
                                   defaultValue={indicatorProceeds}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Прямые расходы (млн. рублей)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorExpenses"*/}
                        {/*           name="indicatorExpenses"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorExpenses}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Сумма расходов на оплату труда работников (млн. рублей)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorSalary"*/}
                        {/*           name="indicatorSalary"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorSalary}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Сумма страховых взносов (млн. рублей)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorInsurancePremiums"*/}
                        {/*           name="indicatorInsurancePremiums"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorInsurancePremiums}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Сумма налогов, включаемых в себестоимость (млн. рублей)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorsTax"*/}
                        {/*           name="indicatorsTax"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorsTax}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Затраты труда (чел.)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorsLaborCosts"*/}
                        {/*           name="indicatorsLaborCosts"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorsLaborCosts}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Выручка (млн. рублей)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorsProceeds2"*/}
                        {/*           name="indicatorsProceeds2"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorsProceeds2}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Численность сотрудников (чел.)</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="indicatorsNumber"*/}
                        {/*           name="indicatorsNumber"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={indicatorsNumber}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}






















































                        <div className="Content__error">
                            <span className="Error__text">{companySupportEditResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAverageEdit);
