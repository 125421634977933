import React from "react";
import '../Form.scss';
import {modalClose, modalOpen} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class EventData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventName: null,
            eventInfo: null,
            eventTarget: null,
            eventCountUsers: null,
            eventDataResponseError: null,
            eventWorkers: '',
            eventWorker: null
        };
    }

    componentDidMount() {
        this.setState({
            eventName: this.props.interimData.events_name,
            eventInfo: this.props.interimData.events_info,
            eventTarget: this.props.interimData.events_target,
            eventCountUsers: this.props.interimData.events_count_user,
            eventWorkers: (this.props.interimData.events_workers ? this.props.interimData.events_workers : '')
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.eventName) {
            let formData = new FormData();

            formData.append('events_name', this.state.eventName);
            // formData.append('events_info', this.state.eventInfo);
            formData.append('events_target', this.state.eventTarget);
            // formData.append('events_count_user', this.state.eventCountUsers);
            formData.append('events_workers', this.state.eventWorkers);

            this.props.apiRequest('/event/' + this.props.interimData.events_id + '/edit', formData, () => {
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        eventDataResponseError: this.props.responseError
                    })
                }
            );
        }

        else {
            this.setState({
                eventDataResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
            });
        }
    }

    modalOpen(modalForm, interimData) {
        this.modalClose();
        this.props.modalOpen(modalForm, interimData);
    }

    modalClose() {
        this.props.modalClose();
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    eventAddWorker() {
        if (this.state.eventWorker && this.state.eventWorker !== '') {
            if (this.state.eventWorkers !== '') {
                this.setState({
                    eventWorkers: this.state.eventWorkers + ';' + this.state.eventWorker
                });
            }

            else {
                this.setState({
                    eventWorkers: this.state.eventWorker
                });
            }
        }

        this.setStateAndInput('eventWorker', null);
    }

    eventDelWorker(index) {
        let eventWorkersArray = this.state.eventWorkers.split(';');
        eventWorkersArray.splice(index, 1);
        this.setState({
            eventWorkers: eventWorkersArray.join(';')
        });
    }

    render() {
        const { interimData, isRequest } = this.props;
        const { eventDataResponseError, eventWorkers } = this.state;

        // let eventWorkersArray = [];
        //
        // if (eventWorkers) {
        //     eventWorkersArray = eventWorkers.split(';');
        // }

        let eventWorkersArray = eventWorkers.split(';');

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Мероприятие</div>
                    </div>
                    <div className="Form__content">
                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Название мероприятия *</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           name="eventName"*/}
                        {/*           type="text"*/}
                        {/*           defaultValue={interimData.events_name}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        <div className="Content__box">
                            <div className="Box__label">Название мероприятия *</div>
                            <textarea className="Box__input"
                                      rows={4}
                                      name="eventName"
                                      defaultValue={interimData.events_name}
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)} />
                        </div>

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Описание мероприятия</div>*/}
                        {/*    <textarea className="Box__input"*/}
                        {/*              rows={5}*/}
                        {/*              name="eventInfo"*/}
                        {/*              defaultValue={interimData.events_info}*/}
                        {/*              value={this.state.value}*/}
                        {/*              onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        <div className="Content__box">
                            <div className="Box__label">Цель мероприятия</div>
                            <textarea className="Box__input"
                                      rows={4}
                                      name="eventTarget"
                                      defaultValue={interimData.events_target}
                                      value={this.state.value}
                                      onChange={this.handleInputChange.bind(this)} />
                        </div>

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Количество участников</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           name="eventCountUsers"*/}
                        {/*           type="number"*/}
                        {/*           defaultValue={interimData.events_count_user}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">ФИО участника мероприятия<div className="Box__label_link" onClick={this.eventAddWorker.bind(this)}>Добавить</div></div>
                            <input className="Box__input"
                                   name="eventWorker"
                                   id="eventWorker"
                                   type="text"
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            {eventWorkers.length > 0 ? (
                                eventWorkersArray.map((worker, index) => (
                                    <div className="Box__candy" key={index}>
                                        {worker}
                                        <div className="Label__clear icon-clear"
                                             onClick={this.eventDelWorker.bind(this, index)}>+</div>
                                    </div>
                                ))
                            ) : null}
                        </div>

                        {/*<hr className="Content__hr" />*/}

                        <div className="Content__error">
                            <span className="Error__text">{eventDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                    <div className="Form__link">
                        <div className="Link__delete" onClick={this.modalOpen.bind(this, 'formEventDelete', interimData)}>Удалить мероприятие</div>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        modalOpen: (modalForm, interimData) => dispatch(modalOpen(modalForm, interimData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventData);
