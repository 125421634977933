import React from "react";
import {modalClose} from "../../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../../loading/Loading";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";

class ProjectTeamAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectTeamUserId: 0,
            projectTeamRightId: 0,
            projectTeamRightOldId: 0,
            projectTeamRight: [],
            projectTeamUsers: [],

            projectTeamAddResponseError: null,

            isManualEntry: false,

            userContactFio: null,
            userContactEmail: null,
            userContactPhone: null,
            positionAtPlaceOfWork: null
        };
    }

    componentDidMount() {
        this.projectTeamRight();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // console.log('1!' + this.state.projectTeamRightId);

        this.setState({
            [name]: value
        }, () => {
            // console.log(this.state.indicatorYear + ' ' + this.state.oldYear);
            // console.log(this.state.indicatorQuarter + ' ' + this.state.oldQuarter);
            // console.log('2!' + this.state.projectTeamRightId);
            if (this.state.projectTeamRightId !== this.state.projectTeamRightOldId) {
                this.projectTeam();
                this.setState({
                    projectTeamRightOldId: this.state.projectTeamRightId,
                });
            }

        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.isManualEntry) {
            if (this.state.userContactFio && this.state.positionAtPlaceOfWork) {

                let formData = new FormData();
                formData.append('fio', this.state.userContactFio);
                formData.append('dolj', this.state.positionAtPlaceOfWork);
                formData.append('tel', this.state.userContactPhone);
                formData.append('email', this.state.userContactEmail);

                this.props.apiRequest('/project/' + this.props.interimData.project_id + '/user_create_not_system', formData, () => {
                        this.props.modalClose();
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                    }, () => {
                        this.setState({
                            projectTeamAddResponseError: this.props.responseError
                        })
                    }
                );
            }

            else {
                this.setState({
                    projectTeamAddResponseError: 'Пожалуйста, заполните все обязательные поля.'
                });
            }
        }

        else {

            if (this.state.projectTeamUserId > 0) {

                let formData = new FormData();
                formData.append('user_id', this.state.projectTeamUserId);

                this.props.apiRequest('/project/' + this.props.interimData.project_id + '/user_create', formData, () => {
                        this.props.modalClose();
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                    }, () => {
                        this.setState({
                            projectTeamAddResponseError: this.props.responseError
                        })
                    }
                );
            }

            else {
                this.setState({
                    projectTeamAddResponseError: 'Пожалуйста, выберите ответсвенного за проект.'
                });
            }
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    projectTeamRight() {
        this.props.apiRequest('/users/right', null, () => {
            this.setState({
                projectTeamRight: this.props.responseData
            });
        }, null);
    }

    projectTeam() {
        // console.log('3!' + this.state.projectTeamRightId);
        this.props.apiRequest('/users/' + this.state.projectTeamRightId, null, () => {
            this.setState({
                projectTeamUsers: this.props.responseData,
                projectTeamUserId: 0
            });
        }, null);
    }

    tabOnManualEntry() {
        this.setState({
            isManualEntry: true,
        });
    }

    tabOffManualEntry() {
        this.setState({
            isManualEntry: false,
        });
    }

    render() {
        const { isRequest } = this.props;
        const { projectTeamUserId,
                projectTeamRightId,
                projectTeamRight,
                projectTeamUsers,
                projectTeamAddResponseError,
                isManualEntry } = this.state;


        // console.log(projectTeamUserId);
        // console.log(projectTeamRightId);
        // console.log(projectTeamRight);
        // console.log(projectTeamUsers);
        // console.log(projectTeamAddResponseError);



        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Добавить ответственного</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Navigate__wrapper ">
                                <div className={!isManualEntry ? 'Navigate__link active' : 'Navigate__link'} onClick={this.tabOffManualEntry.bind(this)}>Зарегистрированные</div>
                                <div className={isManualEntry ? 'Navigate__link active' : 'Navigate__link'} onClick={this.tabOnManualEntry.bind(this)}>Ручной ввод данных</div>
                            </div>
                        </div>
                        {!isManualEntry ? (
                            <React.Fragment>
                                <div className="Content__box">
                                    <div className="Box__label">Категория ответсвенного</div>
                                    <select className="Box__select" name="projectTeamRightId" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={projectTeamRightId}>
                                        <option value="0">Выберите категорию</option>
                                        <option  className="option__hr" disabled />

                                        {!projectTeamRight ? null : (
                                            projectTeamRight.map((ptr, index) => (
                                                <React.Fragment key={index}>
                                                    {/*<option disabled>{ptr.key}</option>*/}
                                                    {/*{ptr.value.map(option => (*/}
                                                    {Number(ptr.r_child_id) > 0 ? (<option value={ptr.r_child_id}>{ptr.catalog_name_child}</option>) : null}
                                                    {/*))}*/}
                                                </React.Fragment>
                                            )))}
                                    </select>
                                </div>

                                <div className="Content__box">
                                    <div className="Box__label">Ответственное лицо</div>
                                    <select className="Box__select" name="projectTeamUserId" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={projectTeamUserId}>
                                        <option value="0">Выберите ответственного</option>
                                        <option  className="option__hr" disabled />

                                        {!projectTeamUsers ? null : (
                                            projectTeamUsers.map((ptu, index) => (
                                                <React.Fragment key={index}>
                                                    {/*<option disabled>{ptu.key}</option>*/}
                                                    {ptu.value.map((user, index) => (
                                                        <React.Fragment key={index}>
                                                            <option value={user.user_id}>{user.user_fio}</option>
                                                            <option className="option__info" disabled>{user.user_eml}</option>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            )))}
                                    </select>
                                </div>
                            </React.Fragment>
                            ) : (
                            <React.Fragment>
                                <div className="Content__box">
                                    <div className="Box__label">ФИО *</div>
                                    <input className="Box__input"
                                           name="userContactFio"
                                           type="text"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                </div>

                                <div className="Content__box">
                                    <div className="Box__label">Должность *</div>
                                    <input className="Box__input"
                                           name="positionAtPlaceOfWork"
                                           type="text"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                </div>

                                <div className="Content__box">
                                    <div className="Box__label">Контактный телефон</div>
                                    <input className="Box__input"
                                           name="userContactPhone"
                                           type="tel"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                </div>

                                <div className="Content__box">
                                    <div className="Box__label">Электронная почта</div>
                                    <input className="Box__input"
                                           name="userContactEmail"
                                           type="email"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                </div>
                            </React.Fragment>
                        )}

                        <div className="Content__error">
                            <span className="Error__text">{projectTeamAddResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Добавить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTeamAdd);
