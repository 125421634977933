import {CABINET_EDIT, CABINET_OPEN} from "./actionTypes";

export function determineLevelAccess() {

    // let levelAccess = JSON.parse(atob(localStorage.getItem('tokenSession').split('.')[1])).data.type;
    let profileEmail = JSON.parse(atob(localStorage.getItem('tokenSession').split('.')[1])).data.email;
    let profileFIO = JSON.parse(atob(localStorage.getItem('tokenSession').split('.')[1])).data.fio;

    return {
        type: CABINET_OPEN,
        // levelAccess: levelAccess
        levelAccess: 0,
        profileEmail: profileEmail,
        profileFIO: profileFIO
    }
}

export function cabinetEditStart() {

    return {
        type: CABINET_EDIT,
        isEdit: true
    }
}

export function cabinetEditEnd() {

    return {
        type: CABINET_EDIT,
        isEdit: false
    }
}
