import {PAGE_LOAD, PAGE_UPDATE} from "../actions/actionTypes";

const initialState = {
    isLoad: false,
    isUpdate: false
}

export default function pageReducer(state = initialState, action) {
    switch (action.type) {
        case PAGE_LOAD:
            return {
                ...state, isLoad: action.isLoad, isUpdate: false
            }
        case PAGE_UPDATE:
            return {
                ...state, isUpdate: true
            }
        default:
            return state;
    }
}
