import React from "react";
import '../Form.scss';
import {modalClose} from "../../../../../store/actions/modalAction";
import {connect} from "react-redux";
import Loading from "../../../../loading/Loading";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import {alertOpen} from "../../../../../store/actions/alertAction";

class CompanyData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            companyType: null,
            companyInn: null,
            companyIndust: null,
            companyName: null,
            companyOgrn: null,
            companyAddress: null,
            companyPhone: null,
            companySchedule: null,
            companyWebsite: null,
            companyEmail: null,
            companyIndustryData: null,
            companyTypeData: null,
            companyDataResponseError: null
        };
    }

    componentDidMount() {
        this.setState({
            companyType: this.props.interimData.company_type_id,
            companyInn: this.props.interimData.company_inn,
            companyIndust: this.props.interimData.company_industry_id,
            companyName: this.props.interimData.company_name,
            companyOgrn: this.props.interimData.company_ogrn,
            companyAddress: this.props.interimData.company_address,
            companyPhone: this.props.interimData.company_phone,
            companySchedule: this.props.interimData.company_schedule,
            companyWebsite: this.props.interimData.company_website,
            companyEmail: this.props.interimData.company_mail,
        });
        this.companyTypeData();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.companyInn && this.state.companyName && this.state.companyType > 0 && this.state.companyIndust > 0) {
            let companyInnNumb = this.state.companyInn;

            if (companyInnNumb.length > 9) {

                let formData = new FormData();

                formData.append('comp_type', this.state.companyType);
                formData.append('comp_inn', this.state.companyInn);
                formData.append('comp_indust', this.state.companyIndust);
                formData.append('comp_name', this.state.companyName);
                formData.append('comp_ogrn', this.state.companyOgrn);
                formData.append('comp_address', this.state.companyAddress);
                formData.append('comp_phone', this.state.companyPhone);
                formData.append('comp_sched', this.state.companySchedule);
                formData.append('comp_web', this.state.companyWebsite);
                formData.append('comp_mail', this.state.companyEmail);

                this.props.apiRequest('/company/' + this.props.interimData.company_id + '/edit', formData, () => {
                        this.props.modalClose();
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                    }, () => {
                        this.setState({
                            companyDataResponseError: this.props.responseError
                        })
                    }
                );
            }

            else {
                this.setState({
                    companyDataResponseError: 'Поле ИНН предприятия, должно состоять минимум из 10 цифр.'
                });
            }
        }

        else {
            this.setState({
                companyDataResponseError: 'Пожалуйста, внимательно заполните все обязательные поля, отмеченные (*).'
            });
        }
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    modalClose() {
        this.props.modalClose();
    }

    companyIndustryData() {
        this.props.apiRequest('/dictionary/company_industry', null, () => {
            this.setState({
                companyIndustryData: this.props.responseData
            });
        }, null);
    }

    companyTypeData() {
        this.props.apiRequest('/dictionary/company_type', null, () => {
            this.setState({
                companyTypeData: this.props.responseData
            });
            this.companyIndustryData();
        }, null);
    }

    render() {
        const { interimData, isRequest, levelAccess } = this.props;
        const { companyIndustryData, companyTypeData, companyType, companyIndust, companyDataResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Предприятие</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__box">
                            <div className="Box__label">Название предприятия *</div>
                            <input className="Box__input"
                                   name="companyName"
                                   type="text"
                                   defaultValue={interimData.company_name}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">ИНН предприятия *</div>
                            <input className="Box__input"
                                   name="companyInn"
                                   type="number"
                                   defaultValue={interimData.company_inn}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">Тип предприятия *</div>
                            {!companyTypeData ? (
                                <select className="Box__select" name="companyType" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={interimData.company_type_id}>
                                    <option value={interimData.company_type_id}>{interimData.company_type_name}</option>
                                </select>) : (
                                <select className="Box__select" name="companyType" value={companyType} onChange={this.handleInputChange.bind(this)} defaultValue={companyType}>
                                    {companyTypeData.map(option => (
                                        (option.company_type_id > 0 ? <option value={option.company_type_id} key={option.company_type_id}>{option.company_type_name}</option> : null)
                                    ))}
                                </select>
                            )}
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Отрасль *</div>
                            {!companyIndustryData ? (
                                <select className="Box__select" name="companyIndust" value={this.state.value} onChange={this.handleInputChange.bind(this)} defaultValue={interimData.company_industry_id}>
                                    <option value={interimData.company_industry_id}>{interimData.company_industry_name}</option>
                                </select>) : (
                                <select className="Box__select" name="companyIndust" value={companyIndust} onChange={this.handleInputChange.bind(this)} defaultValue={companyIndust}>
                                    {companyIndustryData.map(option => (
                                        (option.company_industry_id > 0 ? <option value={option.company_industry_id} key={option.company_industry_id}>{option.company_industry_name}</option> : null)
                                    ))}
                                </select>
                            )}
                        </div>

                        <hr className="Content__hr" />

                        <div className="Content__box">
                            <div className="Box__label">ОГРН предприятия</div>
                            <input className="Box__input"
                                   name="companyOgrn"
                                   type="number"
                                   defaultValue={interimData.company_ogrn}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Юридический адрес</div>
                            <input className="Box__input"
                                   name="companyAddress"
                                   type="text"
                                   defaultValue={interimData.company_address}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Время работы</div>
                            <input className="Box__input"
                                   name="companySchedule"
                                   type="text"
                                   defaultValue={interimData.company_schedule}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Телефон</div>
                            <input className="Box__input"
                                   name="companyPhone"
                                   type="tel"
                                   defaultValue={interimData.company_phone}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Официальный сайт</div>
                            <input className="Box__input"
                                   name="companyWebsite"
                                   type="text"
                                   defaultValue={interimData.company_website}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__box">
                            <div className="Box__label">Электронная почта</div>
                            <input className="Box__input"
                                   name="companyEmail"
                                   type="email"
                                   defaultValue={interimData.company_mail}
                                   value={this.state.value}
                                   onChange={this.handleInputChange.bind(this)} />
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{companyDataResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isRequest ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'} type="submit" disabled={isRequest ? 'disabled' : null}>{isRequest ? <Loading white={true} /> : 'Сохранить'}</button>
                        <button className="Form__button Form__button_cancel" type="submit" onClick={this.modalClose.bind(this)}>Отмена</button>
                    </div>
                    {(levelAccess < 3) ? (
                        <div className="Form__link">
                            <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertCompanyDelete', interimData)}>Удалить предприятие</div>
                        </div>
                    ) : null}
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError,
        levelAccess: state.cabinet.levelAccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose()),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyData);
